import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "../store/actions/index";

import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import VerifyForgetOtp from './VerifyForgetOtp';
// import "../src/style/index.css";
// import "../App.css"
// import "../../src/style/index.css"

import Header from "./Header";
import Footer from "./Footer";

class ResetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            formErrpassword: '',
            showPassword:false,
            email:localStorage.getItem('resetPasswordEmail')
        }
    }
    handleSubmit = async () => {
        await this.setState({
            formErrpassword: (this.state.password == '') ? "Please provide this field" : '',
        })
        if (!(this.state.password === '')) {
            await this.props.resetPassword({
                email: this.state.email,
                password:this.state.password
            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { enqueueSnackbar, error, success, signup_success, signup_error } = this.props;
        success && enqueueSnackbar(success, { variant: "success" });
        error && enqueueSnackbar(error, { variant: "error" });
        signup_success && enqueueSnackbar(signup_success, { variant: "success" });
        signup_error && enqueueSnackbar(signup_error, { variant: "error" });
    }
    onChangeAddName = async (key, value) => {
        var self = this;
        await self.setState({
            [key]: value,
            ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
        })
    }

    showPassword =()=>{
       if(this.state.showPassword){
        this.setState({showPassword:false})
       }else{
        this.setState({showPassword:true})
       }
    }

    render() {

        return (
            <>
                <Header />
                <div className="modalcont">
                    <div className="modalinner my-5 py-4">
                        <h2>Reset Your Password</h2>
                        <p>Please Enter Your New Password</p>
                        <div className="loginForm signup" style={{height:"250px"}}>
                            <div class="input-group mb-3 mt-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text h-100" id="basic-addon1">
                                        <LockIcon />
                                    </span>
                                </div>
                                <input
                                    type={this.state.showPassword?'text':"password"}
                                    class="form-control form-control-lg"
                                    placeholder="Enter Password"
                                    id='password'
                                    value={this.state.password}
                                    onChange={(e) => this.onChangeAddName('password', e.target.value)}
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                />
                                <div class="input-group-prepend">
                                    <span class="input-group-text h-100" id="basic-addon1" onClick={()=>{this.showPassword()}}>
                                       {this.state.showPassword?<VisibilityOff />:<VisibilityIcon />} 
                                        
                                    </span>
                                </div>
                            </div>
                            <span style={{ color: 'red' }}>{this.state.formErrpassword}</span>
                            <div className="d-grid mt-4 justify-content">
                                <button
                                    className="btn btn-primary btn-lg"
                                    type="button"
                                    onClick={(e) => this.handleSubmit()}
                                >
                                    Reset Password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>

        )
    }
}
const mapPropsToState = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        signup_error: state.auth.signup_error,
        signup_success: state.auth.signup_success,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetPassword: (data) => dispatch(actions.resetPassword(data))
    };
};

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(ResetPassword));
