import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// import Login from "../Login";
// import Signup from "../Signup";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import 'react-slideshow-image/dist/styles.css';
import 'react-awesome-slider/dist/styles.css';
import EditCard from './CardDetail/EditCard'
import PayAndSaveCard from './CardDetail/PayAndSaveCard'
import * as actions from '../store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../App.css'
// import { getCardDetails } from "../store/actions/cardDetails";
class SubscriptionPlanDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            monthlyBottonColor: "#2dce89",
            yearlyBottonColor: "#0a68ae",
            oneTimeBottonColor: "#0a68ae",
            playing: true
        }
    }

    async componentWillMount() {
        this.props.getCardDetails()
        await this.props.getSubscriptionDetails(this.props.match.params.Id)
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }

    componentDidUpdate(prevProps, prevState) {
        const { enqueueSnackbar, error, success } = this.props;
        success && enqueueSnackbar(success, { variant: "success" });
        error && enqueueSnackbar(error, { variant: "error" });
    }

    makePayment(planId, customerId) {
        let data = {
            planId: planId,
            customerId: customerId,
            freeTrial:false
        }
        this.props.buyAdvertiseSubscriptionPlan(data)
    }
    render() {
        // const userToken = localStorage.getItem('token')
        let {is_monthlyfreetrial_advertise_subscription} = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): false;
        const cardDetails = this.props.CardDetails;
        var cardBrand = cardDetails.brand ? cardDetails.brand.toLowerCase() : '';
        return (
            <>
                <Header />
                <section className="mt-5">
                    <div className="container-fluid subscription-plan">
                        <div className='header-body'>
                            <h6 className='h2 text-center mb-0'>Subscription Plan Details</h6>
                        </div>
                        <div className='card mx-auto mt-4 mb-5' style={{ maxWidth: "600px" }}>
                            <div className='card-body py-5'>
                                <p>
                                    <strong>Plan Description: </strong>{this.props.subscriptionPlanDetails ? this.props.subscriptionPlanDetails.advertisement_type : ''}
                                </p>
                                <p><strong>Plan Type: </strong>{this.props.subscriptionPlanDetails.subscription_type ? (this.props.subscriptionPlanDetails.subscription_type.charAt(0).toUpperCase() + this.props.subscriptionPlanDetails.subscription_type.slice(1)) +' '+((is_monthlyfreetrial_advertise_subscription === false && this.props.subscriptionPlanDetails.subscription_type === 'monthly')?'Free trail':'') : ''}</p>
                                <p><strong>Plan Days: </strong>{(is_monthlyfreetrial_advertise_subscription === false && this.props.subscriptionPlanDetails.subscription_type === 'monthly')? '14':this.props.subscriptionPlanDetails.plan_day}</p>
                                <p><strong>Total Amount: </strong>$ {(is_monthlyfreetrial_advertise_subscription === false && this.props.subscriptionPlanDetails.subscription_type === 'monthly')? '0.99':this.props.subscriptionPlanDetails.price}</p>
                                {this.props.subscriptionPlanDetails.planDetail ?
                                    <div>
                                        <strong>Plan Details: </strong>:
                                        <div dangerouslySetInnerHTML={{
                                            __html:
                                                (this.props.subscriptionPlanDetails.planDetail !== null) ? this.props.subscriptionPlanDetails.planDetail : "waiting"
                                        }}></div> </div> : ''
                                }

                                {cardDetails.brand ?
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div class="form-check d-flex align-items-center">
                                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value={cardDetails.customerId} checked />
                                                <label class="form-check-label" for="exampleRadios1" style={{ minWidth: '300px', marginLeft: '20px' }}>
                                                    <div className={`credit-card ${cardBrand}`} >
                                                        <div class="credit-card-last4">
                                                            {cardDetails.last4}
                                                        </div>
                                                        <div class="credit-card-expiry">
                                                            {cardDetails.exp_month < 10 ? "0" + cardDetails.exp_month : cardDetails.exp_month}/{`${cardDetails.exp_year}`.slice(-2)}
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        {/* <input type='radio' /> */}

                                        <div className="col-md-4">
                                            <a
                                                className="nav-link"
                                                // href="#"
                                                data-toggle="modal"
                                                data-target="#exampleModalforCardEdit"
                                            >
                                                <button className='btn btn-danger'
                                                >
                                                    Edit Card
                                                </button>
                                            </a>
                                        </div>

                                    </div> : ''

                                }
                                {cardDetails.customerId ?
                                    <button className='btn btn-success btn-lg w-100 mt-5' onClick={() => this.makePayment(this.props.subscriptionPlanDetails._id, cardDetails.customerId)}>Buy Now</button>
                                    :
                                    <a className="nav-link" data-toggle="modal" data-target="#exampleModalforCardPay" >
                                        <button className='btn btn-success btn-lg w-100 mt-5' onClick={() => this.makePayment()}>Buy Now</button>
                                    </a>
                                }
                                <ToastContainer />
                            </div>
                        </div>
                    </div>
                    {/*model for edit card button */}
                    <div
                        className="modal fade"
                        id="exampleModalforCardEdit"
                        tabIndex="-1"
                        aria-labelledby="exampleModalforStar"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content rounded p-0">
                                <div className="modal-header">
                                    <div className="h5">Update Details</div>
                                </div>
                                <div className="modal-body">
                                    <EditCard />
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/*model for pay and card button */}
                    <div
                        className="modal fade"
                        id="exampleModalforCardPay"
                        tabIndex="-1"
                        aria-labelledby="exampleModalforStar"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content rounded p-0">
                                <div className="modal-header">
                                    <div className="h5">Pay Your Amount</div>
                                </div>
                                <div className="modal-body">
                                    <PayAndSaveCard planId={this.props.subscriptionPlanDetails._id} freeTrial={(is_monthlyfreetrial_advertise_subscription == false && this.props.subscriptionPlanDetails.subscription_type == 'monthly')?true:false} />
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

const mapPropsToState = (state) => {
    return {
        loading: state.advertiseSubscription.loading,
        error: state.advertiseSubscription.error,
        success: state.advertiseSubscription.success,
        subscriptionPlanDetails: state.advertiseSubscription.subscriptionPlanDetails,
        CardDetails: state.getCardDetails.cardDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCardDetails: () => dispatch(actions.getCardDetails()),
        getSubscriptionDetails: (data) => dispatch(actions.getSubscriptionDetails(data)),
        buyAdvertiseSubscriptionPlan: (data) => dispatch(actions.buyAdvertiseSubscriptionPlan(data))
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(SubscriptionPlanDetails))
