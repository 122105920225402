import axios from "../../axios-config";
import * as actionTypes from "./actionTypes";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, userInfo) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userInfo: userInfo,
    error: "Login Successfully"
  };
};
export const signupSuccess = (message) => {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    signup_success: message
  };
};
export const signupFail = (message) => {
  return {
    type: actionTypes.SIGNUP_FAIL,
    signup_error: message
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};
export const logoutAction = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};
export const showAlertNotification = (type, message) => {
  return {
    type: actionTypes.SHOW_ALERT_NOTIFICATION,
    alertType: type,
    alertMessage: message
  };
};
export const auth = (data) => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      email: data.email,
      password: data.password,

    };

    axios
      .post(`/webUser/login`, authData)
      .then(response => {
        if (response.status === 200) {
          let apiResponse = response.data;
          if (apiResponse.status === 0) {
            dispatch(authFail(apiResponse.message));
          } else {
            // console.log(response.data._id);
            dispatch(
              authSuccess(response.headers.authorization, response.data.data)
            );
            localStorage.setItem("token",response.data.data.token);
            localStorage.setItem("userId", response.data.data._id);
            localStorage.setItem("userInfo", JSON.stringify(response.data.data));
            window.location = '/'
          }
        }
      })
      .catch(err => {
        console.log(err);
        //dispatch(authFail(err.response.data.error))
      });
  };
};

export const register = (data) => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      mobile_no: data.mobile_no,
      country_code: data.country_code,
      business_owner_name: data.business_owner_name,
      name: data.name,
      email: data.email,
      password: data.password,

    };
    axios
      .post(`/webCustomer/business-register`, authData)
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            localStorage.setItem("registerUserEmail", authData.email);
            dispatch(signupSuccess(response.data.message))
            const load = () => { window.location.replace('/verify-user') }
            setTimeout(load, 3000);
            // alert(response.data.message);
          } else if (response.data.status === 1) {
            localStorage.setItem("registerUserEmail", authData.email);
            dispatch(signupFail(response.data.message))
            alert(response.data.message)
            const load = () => { window.location.reload(true) }
            setTimeout(load, 3000);
          }
          dispatch(signupFail(response.data.message))
        } else {
          dispatch(signupFail(response.message))
          // toast.error('Runtime error')
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(signupFail(err))
      });
  };
};
export const verifyOtp = (data) => {
  return dispatch => {
    axios
      .post("/webCustomer/verify-business-user", data, {
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(signupSuccess(response.data.message))
            dispatch(
              authSuccess(response.headers.authorization, response.data.data)
            );
            localStorage.setItem("token",response.data.data.token);
            localStorage.setItem("userId", response.data.data._id);
            localStorage.setItem("userInfo", JSON.stringify(response.data.data));
            const load = () => { window.location.replace('/') }
            setTimeout(load, 2000);
          } else if (response.data.status === 0) {
            dispatch(signupFail(response.data.message))
          }
        }
      })
      .catch(err => {
        dispatch(signupFail(err.message))
        console.log(err)
      });
  };
}


export const resendOtp = (data) => {
  return dispatch => {
    axios
      .post("/webCustomer/resend-otp", data, {
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(signupSuccess(response.data.message))
            // alert(response.data.message)
            // window.location.replace('/')

          } else if (response.data.status === 0) {
            dispatch(signupFail(response.data.message))
            // alert(response.data.message)
            // window.location.reload(true);
          }
        }
      })
      .catch(err => {
        dispatch(signupFail(err.message))
        console.log(err)
      });
  };
}

export const forgotPassword = (data) => {
  return dispatch => {
    axios
      .post("/webCustomer/forgot-password", data, {
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(signupSuccess(response.data.message))
            localStorage.setItem("resetPasswordEmail", data.email);
            window.location.reload(true)
          } else if (response.data.status === 0) {
            dispatch(signupFail(response.data.message))
          }
        }
      })
      .catch(err => {
        dispatch(signupFail(err.message))
        console.log(err)
      });
  };
}

export const verifyPasswordOtp = (data) => {
  return dispatch => {
    axios
      .post("/webCustomer/verify-business-user", data, {
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(signupSuccess("Otp verify,Please reset your password"))
            const load = () => { window.location.replace('/reset-password') }
            setTimeout(load, 1000);
          } else if (response.data.status === 0) {
            dispatch(signupFail(response.data.message))
          }
        }
      })
      .catch(err => {
        dispatch(signupFail(err.message))
        console.log(err)
      });
  };
}

export const resetPassword = (data) => {
  return dispatch => {
    axios
      .post("/webCustomer/reset-password", data, {
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(signupSuccess(response.data.message))
            localStorage.removeItem("resetPasswordEmail");
            const load = () => { window.location.replace('/') }
            setTimeout(load, 1000);
          } else if (response.data.status === 0) {
            dispatch(signupFail(response.data.message))
          }
        }
      })
      .catch(err => {
        dispatch(signupFail(err.message))
        console.log(err)
      });
  };
}
export const logout = () => {
  return dispatch => {
    let authCode = "Bearer " + localStorage.getItem("token");

    // if (response.status === 200) {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("userId");
    dispatch(logoutAction());
    // }

  };
};

export const sendUsRequirementEmail = (data,history)=>{
  return dispatch => {
    axios
      .post("/webCustomer/send-servicehubquery-mail", data, {
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(signupSuccess(response.data.message))
            const load = () => { history.push({ 
              pathname: '/thank-you',
              state: 'send-us'
             })}
            setTimeout(load, 2000);
          } else {
            dispatch(signupFail(response.data.message))
          }
        } else {
          dispatch(signupFail(response.data.message))
        }
      })
      .catch(err => {
        dispatch(signupFail(err.message))
      });
  };
}