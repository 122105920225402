import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  cardDetails:{}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CARD_FAIL:
      return {
        ...state,
        loading:null,
        error:action.message,
        success:null
      };
    case actionTypes.GET_CARD_DETAILS:
      return {
        ...state,
        loading:null,
        cardDetails:action.cardDetails,
        error:null,
        success:action.message
      };
      case actionTypes.UPDATE_CARD_DETAILS:
      return {
        ...state,
        loading:null,
        cardDetails:action.cardDetails,
        error:null,
        success:action.message
      };
    default:
      return state;
  }
};

export default reducer;
