import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startBlog = () => {
  return {
    type: actionTypes.START_BLOG
  };
};

export const blogFail = message => {
  return {
    type: actionTypes.GET_BLOG_FAIL,
    message
  };
};

export const getBlogSuccess = (data,message) => {
  return {
    type: actionTypes.GET_BLOG_SUCCESS,
    blogList: data,
    message
  };
};


export const getBlogList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .get("webCustomer/get-blog-list", {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogSuccess(response.data.data))
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};



export const getBlogDetailSuccess = (data,message) => {
  return {
    type: actionTypes.GET_BLOG_DETAILS_SUCCESS,
    blogDetails: data,
    message
  };
};


export const getBlogDetails = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("webCustomer/get-blog-details",data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogDetailSuccess(response.data.data))
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};


// Blog listing advertisement page
export const getBlogBannerPictureListSuccess = (pictureList,message = '') => {
  return {
    type: actionTypes.GET_BLOGLIST_BANNER_PICTURE_LIST,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getBlogBannerPictureList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-blog-price-advertisement-picture", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogBannerPictureListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};


export const getBlogSquarePictureListSuccess = (pictureList,message = '') => {
  return {
    type: actionTypes.GET_BLOGLIST_SQUARE_PICTURE_LIST,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getBlogSquarePictureList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-blog-price-advertisement-picture", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogSquarePictureListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};

export const getBlogBannerVideoListSuccess = (pictureList,message = '') => {
  return {
    type: actionTypes.GET_BLOGLIST_BANNER_VIDEO_LIST,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getBlogBannerVideoList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-blog-price-advertisement-video", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogBannerVideoListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};


export const getBlogSquareVideoListSuccess = (pictureList,message = '') => {
  return {
    type: actionTypes.GET_BLOGLIST_SQUARE_VIDEO_LIST,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getBlogSquareVideoList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-blog-price-advertisement-video", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogSquareVideoListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};

//blog details advertisement

export const getBlogDetailsBannerVideoListSuccess = (listing,message = '') => {
  return {
    type: actionTypes.GET_BLOGDETAILS_BANNER_VIDEO_LIST,
    listing: listing,
    message: message ? message : ''
  };
};

export const getBlogDetailsBannerVideoList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-blog-details-advertisement-video", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogDetailsBannerVideoListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};


export const getBlogDetailsBannerPictureListSuccess = (pictureList,message = '') => {
  return {
    type: actionTypes.GET_BLOGDETAILS_BANNER_PICTURE_LIST,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getBlogDetailsBannerPictureList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-blog-details-advertisement-picture", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogDetailsBannerPictureListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};

export const getBlogDetailsSquareVideoListSuccess = (listing,message = '') => {
  return {
    type: actionTypes.GET_BLOGDETAILS_SQUARE_VIDEO_LIST,
    listing: listing,
    message: message ? message : ''
  };
};

export const getBlogDetailsSquareVideoList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-blog-details-advertisement-video", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogDetailsSquareVideoListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};

export const getBlogDetailsSquarePictureListSuccess = (listing,message = '') => {
  return {
    type: actionTypes.GET_BLOGDETAILS_SQUARE_PICTURE_LIST,
    listing: listing,
    message: message ? message : ''
  };
};

export const getBlogDetailsSquarePictureList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-blog-details-advertisement-picture", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogDetailsSquarePictureListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};


export const getPricingBannerVideoListSuccess = (listing,message = '') => {
  return {
    type: actionTypes.GET_PRICINGLIST_BANNER_VIDEO_LIST,
    listing: listing,
    message: message ? message : ''
  };
};

export const getPricingBannerVideoList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-pricing-advertisement-video", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getPricingBannerVideoListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};


export const getPricingBannerPictureListSuccess = (pictureList,message = '') => {
  return {
    type: actionTypes.GET_PRICINGLIST_BANNER_PICTURE_LIST,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getPricingBannerPictureList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-pricing-advertisement-picture", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getPricingBannerPictureListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(blogFail( response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};


export const getPricingSquareVideoListSuccess = (listing,message = '') => {
  return {
    type: actionTypes.GET_PRICINGLIST_SQUARE_VIDEO_LIST,
    listing: listing,
    message: message ? message : ''
  };
};

export const getPricingSquareVideoList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-pricing-advertisement-video", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getPricingSquareVideoListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};


export const getPricingSquarePictureListSuccess = (pictureList,message = '') => {
  return {
    type: actionTypes.GET_PRICINGLIST_SQUARE_PICTURE_LIST,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getPricingSquarePictureList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-pricing-advertisement-picture", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getPricingSquarePictureListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};


