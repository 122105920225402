import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
// import Login from "../Login";
// import Signup from "../Signup";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import 'react-awesome-slider/dist/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import * as actions from '../../store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import ReactPlayer from 'react-player'
import { MDBDataTable } from "mdbreact";
import { Box } from '@material-ui/core';
import Linkify from 'react-linkify';

class BlogDetails extends React.Component {
    constructor(props) {
        super(props)

    }

    componentWillMount = () =>{
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }
    componentDidMount = async () => {
        let data = {
            blogId: this.props.match.params.Id
        }
        await this.props.getBlogDetails(data)
        await this.props.getBlogDetailsBannerVideoList({ size_type: "banner" })
        await this.props.getBlogDetailsBannerPictureList({ size_type: "banner" })
        await this.props.getBlogDetailsSquareVideoList({ size_type: "square" })
        await this.props.getBlogDetailsSquarePictureList({ size_type: "square" })
    }



    render() {
        let { blogDetails } = this.props
        return (
            <>
                <Header />
                <div className="mb-5">
                    <div className='blog-details-banner py-4 py-md-5'>
                        <div className='container my-lg-5'>
                            <div className='row align-items-center'>
                                <div className='col-md-4'>
                                    <div className="blog-label mt-0">Blog Post</div>
                                    <Box className="blog-title my-2 my-md-3" sx={{ fontSize: { xs: '20px', md: '30px', lg: '40px' } }}>{blogDetails.blog_title}</Box>
                                    <div className="blog-date">{blogDetails.createdAt ? new Date(blogDetails.createdAt).toDateString() : ''}</div>
                                </div>
                                <div className='col-md-8 mt-3 mt-md-0'>
                                    {blogDetails.blog_video && blogDetails.blog_picture ?
                                        <div className='row'>

                                            <div className='col-md-6 col-sm-12 p-0'>
                                                {blogDetails.blog_video ?
                                                    <div className="img-wrapper m-0">
                                                        <a className="player-wrapper" target="_blank" href={blogDetails.blog_video_website_url ? 'https://' + blogDetails.blog_video_website_url : '#'} onClick={(event) => !blogDetails.blog_video_website_url ? event.preventDefault() : true}>
                                                            <ReactPlayer className='react-player' style={{ backgroundColor: 'black' }} width='100%' url={blogDetails.blog_video} playing={true} loop={true} volume={0.5} muted={true} controls={true} ></ReactPlayer>
                                                            {/* <iframe height="350" width='100%' src={blogDetails.blog_video} title="YouTube video player" ></iframe> */}
                                                        </a>
                                                    </div> : ''
                                                }
                                            </div>

                                            <div className='col-md-6 col-sm-12 p-0'>
                                                {blogDetails.blog_picture ?
                                                    <div className="img-wrapper m-0">
                                                        <a target="_blank" href={blogDetails.blog_picture_website_url ? 'https://' + blogDetails.blog_picture_website_url : '#'} onClick={(event) => !blogDetails.blog_picture_website_url ? event.preventDefault() : true}>
                                                            <img className="img-fluid" src={blogDetails.blog_picture} alt="Blog Banner" />
                                                        </a>
                                                    </div> : ''
                                                }
                                            </div>
                                        </div> :
                                        <div className="img-wrapper only-one m-0">
                                            {
                                                blogDetails.blog_video ?
                                                    // <iframe height="350" width='100%' src={blogDetails.blog_video} title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; picture-in-picture" allowfullscreen></iframe>
                                                    <a className="player-wrapper vv" target="_blank" href={blogDetails.blog_video_website_url ? 'https://' + blogDetails.blog_video_website_url : '#'} onClick={(event) => !blogDetails.blog_video_website_url ? event.preventDefault() : true}>
                                                        <ReactPlayer className='react-player' width='100%' url={blogDetails.blog_video} playing={true} volume={0.5} loop={true} muted={true} controls={true} ></ReactPlayer>
                                                    </a>
                                                    :
                                                    <div>
                                                        {
                                                            blogDetails.blog_picture ?
                                                                <a target="_blank" href={blogDetails.blog_picture_website_url ? 'https://' + blogDetails.blog_picture_website_url : '#'} onClick={(event) => !blogDetails.blog_picture_website_url ? event.preventDefault() : true}>
                                                                    <img className="img-fluid" src={blogDetails.blog_picture} alt="Blog Banner" />
                                                                </a>
                                                                : ''
                                                        }
                                                    </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <Box className='blog-details-content mt-5 mx-auto' sx={{ fontSize: { md: '18px' } }}>
                            <div className='blog-description'>
                                {blogDetails.blog_description}
                            </div>
                            {/*blogdetails banner advertisement video  */}
                            {
                                this.props.blogDetailsBannerVideoList.length > 0 ?
                                    <div className="video-wrapper row g-md-0 mt-3">
                                        <div className="col-md-6">
                                            <div className="slide-container">
                                                <Slide arrows={false} duration={10000} >
                                                    {this.props.blogDetailsBannerVideoList.map((slideImage, index) => (
                                                        <div className="each-slide" key={index}>
                                                            <a target="_blank" href={'https://' + slideImage.website}>
                                                                <div >
                                                                    <ReactPlayer url={slideImage.video_url} playing={true} volume={0.5} muted={true} controls={true}
                                                                        loop={true} width={'100%'} height={'250px'} ></ReactPlayer>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    ))}
                                                </Slide>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="slide-container">
                                                <Slide arrows={false} duration={10000} >
                                                    {this.props.blogDetailsBannerVideoList.map((slideImage, index) => (
                                                        <div className="each-slide" key={index}>
                                                            <a target="_blank" href={'https://' + slideImage.website}>
                                                                <div>
                                                                    <ReactPlayer url={slideImage.video_url} playing={true} volume={0.5} muted={true} controls={true}
                                                                        loop={true} width={'100%'} height={'250px'}></ReactPlayer>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    ))}
                                                </Slide>
                                            </div>
                                        </div>
                                    </div> :
                                    ''
                            }
                            {/* blog details advertisement banner picturelist */}
                            {this.props.blogDetailsBannerPictureList.length > 0 ?
                                <div className="video-wrapper row g-md-0">
                                    <div className="col-md-6">
                                        <div className="slide-container">
                                            <Slide arrows={false} duration={3000}>
                                                {this.props.blogDetailsBannerPictureList.map((slideImage, index) => (
                                                    <div className="each-slide" key={index}>
                                                        <a target="_blank" href={'https://' + slideImage.website} >
                                                            <div>
                                                                <img src={slideImage.picture_url} style={{ height: '40vh', width: '100%' }} />
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </Slide>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="slide-container">
                                            <Slide arrows={false} duration={3000}>
                                                {this.props.blogDetailsBannerPictureList.map((slideImage, index) => (
                                                    <div className="each-slide" key={index}>
                                                        <a target="_blank" href={'https://' + slideImage.website} >
                                                            <div>
                                                                <img src={slideImage.picture_url} style={{ height: '40vh', width: '100%' }} />
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </Slide>
                                        </div>
                                    </div>
                                </div> :
                                ''
                            }
                            {/* Blog story */}
                            {
                                blogDetails.blog_story ?
                                    blogDetails.blog_story.map((item) => (
                                        <div key={item._id}>
                                            <Box className='blog-heading mt-4 mt-md-5' sx={{ fontSize: '26px' }}>{item.story_title}</Box>

                                            {
                                                item.story_picture ?
                                                    <div className='img-wrapper text-center mt-4 mt-md-5'>
                                                        <a target="_blank" href={item.story_picture_website_url ? 'https://' + item.story_picture_website_url : '#'} onClick={(event) => !item.story_picture_website_url ? event.preventDefault() : true}>
                                                            <img className="img-fluid" src={item.story_picture} alt="Blog Image" />
                                                        </a>
                                                    </div>
                                                    : ''
                                            }

                                            <div className='blog-description mt-4'>

                                                <div dangerouslySetInnerHTML={{
                                                    __html:
                                                        (item.story_content !== null) ? item.story_content : "waiting"
                                                }}></div>

                                            </div>

                                            {item.story_video ?
                                                <div className='video-wrapper text-center mt-4 mt-md-5'>
                                                    <a target="_blank" href={item.story_video_website_url ? 'https://' + item.story_video_website_url : '#'} onClick={(event) => !item.story_video_website_url ? event.preventDefault() : true}>
                                                        <ReactPlayer className='react-player' width="560" height="315" url={item.story_video} playing={true} muted={true} volume={0.5} loop={true} controls={true} ></ReactPlayer>
                                                    </a>
                                                </div> : ''
                                            }
                                        </div>
                                    ))
                                    : ''
                            }



                        </Box>
                        {/* Square video list */}
                        {
                            this.props.blogDetailsSquareVideoList.length > 0 ?
                                <div className="row g-0 mt-5">
                                    {this.props.blogDetailsSquareVideoList.map((item) => (
                                        <div className="col-md-4" key={item._id}>
                                            <div>
                                                <a target="_blank" href={'https://' + item.website}>
                                                    <ReactPlayer url={item.video_url} playing={true} volume={0.5} muted={true} controls={true}
                                                        loop={true} width={'100%'} height={'350%'}></ReactPlayer>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                : ''
                        }

                        {/* square picture list */}
                        {
                            this.props.blogDetailsSquarePictureList.length > 0 ?
                                <div className="row g-0 mt-1 mb-2">
                                    {this.props.blogDetailsSquarePictureList.map((item) => (
                                        <div className="col-md-4" key={item._id}>
                                            <div>
                                                <a target="_blank" href={'https://' + item.website}>
                                                    <div>
                                                        <img src={item.picture_url} style={{ height: '30vh', width: '100%' }} />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                : ''
                        }
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

const mapPropsToState = (state) => {
    return {
        success: state.blog.success,
        error: state.blog.error,
        loading: state.blog.loading,
        blogDetails: state.blog.blogDetails,
        blogDetailsBannerVideoList: state.blog.blogDetailsBannerVideoList,
        blogDetailsBannerPictureList: state.blog.blogDetailsBannerPictureList,
        blogDetailsSquareVideoList: state.blog.blogDetailsSquareVideoList,
        blogDetailsSquarePictureList: state.blog.blogDetailsSquarePictureList,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBlogDetails: (data) => dispatch(actions.getBlogDetails(data)),
        getBlogDetailsBannerVideoList: (data) => dispatch(actions.getBlogDetailsBannerVideoList(data)),
        getBlogDetailsBannerPictureList: (data) => dispatch(actions.getBlogDetailsBannerPictureList(data)),
        getBlogDetailsSquareVideoList: (data) => dispatch(actions.getBlogDetailsSquareVideoList(data)),
        getBlogDetailsSquarePictureList: (data) => dispatch(actions.getBlogDetailsSquarePictureList(data)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(BlogDetails))
