import React from 'react'
import Footer from '../Footer';
import Header from '../Header';

const Thankyou = (props) => {
    console.log(props.location.state, 'what is here')

    return (
        <>
            <Header />
            <div className='thankyou-page py-4 py-xl-5' style={{ minHeight: "50vh" }}>
                <div className='container'>
                    <div className='thankyou-wrapper p-5 border border-3 border-secondary rounded text-center mx-auto' style={{ maxWidth: '700px' }}>
                        <div className="icon-wrapper">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-green-600 w-28 h-28" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1" style={{ width: "100px" }}>
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <h1 className="text-primary">Thank You !</h1>
                        {props.location.state === 'send-us' ?
                            <p>Thanks for submitting your response. Admin will contact you soon.</p>
                            :
                            <p>Thanks for purchasing. Check your email to get all purchasing details.</p>
                        }

                        <a href='/' className="btn btn-primary text-nowrap">
                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                            </svg> Home
                        </a>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Thankyou;