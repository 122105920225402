import React, { useEffect } from 'react'
import Header from "../Header";
import Footer from "../Footer";
import ServicesBanner from "../../assets/images/new/mobileApp/mobileApp.jpg";
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import * as actions from '../../store/actions/index'
import { Link, Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import LandingBanner from "../../assets/images/new/landing-banner.png";

// Services Images
import ServicesIcon1 from "../../assets/images/new/mobileApp/app-development.png";
import ServicesIcon2 from "../../assets/images/new/mobileApp/coding.png";
import ServicesIcon3 from "../../assets/images/new/mobileApp/device.png";
import ServicesIcon4 from "../../assets/images/new/mobileApp/technical-support.png";
import ServicesIcon5 from "../../assets/images/new/mobileApp/cloud-service.png";
import ServicesIcon6 from "../../assets/images/new/mobileApp/payment-gateway.png";
import ServicesIcon7 from "../../assets/images/new/mobileApp/close.png";
import ServicesIcon8 from "../../assets/images/new/mobileApp/analysis.png";
import ServicesIcon9 from "../../assets/images/new/mobileApp/reminder.png";

//industry icon

import IndustryIcon1 from "../../assets/images/new/mobileApp/shooping.png";
import IndustryIcon2 from "../../assets/images/new/mobileApp/conflict.png";
import IndustryIcon3 from "../../assets/images/new/mobileApp/content.png";
import IndustryIcon4 from "../../assets/images/new/mobileApp/university.png";
import IndustryIcon5 from "../../assets/images/new/mobileApp/red-carpet.png";
import IndustryIcon6 from "../../assets/images/new/mobileApp/religion.png";
import IndustryIcon7 from "../../assets/images/new/mobileApp/dumbbell.png";
import IndustryIcon8 from "../../assets/images/new/mobileApp/workflow.png";
import IndustryIcon9 from "../../assets/images/new/mobileApp/vote.png";

// Our Packages Slick Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Download App Image
import AppImg from "../../assets/images/new/app-banner.jpg";
import { ServiceSection } from '../Component/ServiceSection';

const Data = [
    {
        icon: ServicesIcon1,
        title: 'App Development',
    },
    {
        icon: ServicesIcon2,
        title: 'App Design',
    },
    {
        icon: ServicesIcon3,
        title: 'App Strategy',
    },
    {
        icon: ServicesIcon4,
        title: 'Support and Maintenance',
    },
    {
        icon: ServicesIcon5,
        title: 'Cloud application development',
    },
    {
        icon: ServicesIcon6,
        title: 'Payment Gateway Integration',
    },
    {
        icon: ServicesIcon7,
        title: 'Enterprise Mobility',
    },
    {
        icon: ServicesIcon8,
        title: 'Business analysis',
    },
    {
        icon: ServicesIcon9,
        title: 'Web Development',
    },
]

const ChooseData = [
    {
        id: '01',
        title: 'User Centric Design',
        description: 'Our experts designers make sure the end user experience (UX) is easy and enjoyable, using colors, font and visuals that guide users intuitively throughout your app.'
    },
    {
        id: '02',
        title: 'Technology Expertise',
        description: 'With our deep knowledge of programming languages from swift and objective c to Java and HTML 5, our development team can ensure a quick and successful launch for your mobile app.'
    },
    {
        id: '03',
        title: 'Maintenance and Support',
        description: 'We are available 24/7 to assist you with any app related questions or requests so you can feel safe knowing your app is always in good hands'
    },
    {
        id: '04',
        title: 'Unique products and solutions',
        description: 'We strive to share our acquisitions and show the high quality of our products.'
    },
    {
        id: '05',
        title: 'Superior Scalability',
        description: 'Whether you need an app that built for speed or reliability our cutting edge technology allows for superior scalability for both Apple and android devices.'
    },
    {
        id: '06',
        title: 'Quality Testing',
        description: 'We have a team of experts app testers who will work with you every step of the way to ensure your app meets all quality assurance standards.'
    }
]

const IndustryData = [
    {
        icon: IndustryIcon1,
        title: 'Ecommerce App',
        description: 'Unlock the power of online sales and join the ecommerce revolution with a custom Shopify website',
    },
    {
        icon: IndustryIcon2,
        title: 'Employee Communication',
        description: 'Keep your team connected with a simple and secure employee communication app.',
    },
    {
        icon: IndustryIcon3,
        title: 'Content/Podcasts/Media',
        description: 'Get creative with Content App. Easily access an array of media content and use it to make your project truly stand out.',
    },
    {
        icon: IndustryIcon4,
        title: 'School and University',
        description: 'Make the transition to university easier with the school university app. Get all the information you need in one place.',

    },
    {
        icon: IndustryIcon5,
        title: 'Mobile app for events',
        description: 'Get organized with events—the best app for creating, planning, and sharing events with your friends.',

    },
    {
        icon: IndustryIcon6,
        title: 'Religious Organisation',
        description: 'Discover a new way to deepen your faith and strengthen your connection with like-minded believers with the Religious Organisation app',

    },
    {
        icon: IndustryIcon7,
        title: 'Fitness and wellness app',
        description: 'Get fit and reach your wellness goals with the fitness app that puts you in control.',

    },
    {
        icon: IndustryIcon8,
        title: 'Workflow app builder',
        description: 'Improve your productivity and manage tasks like a pro with workflow app. Get your work done in record time and take your career to the next level.',

    },
    {
        icon: IndustryIcon9,
        title: 'Voting app',
        description: 'Make your voice heard with nation voting app - cast your ballot and help shape your country’s future.'
    }
]

const MobileAppPage = (props) => {
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
        props.advertiseSubscriptionList({ subscription_type: "quote" })
    }, [])

    const OnSubscriptionPlan = (planDetails) => {
        if (planDetails.subscriptionType == 'quote') {
            props.history.push({
                pathname: '/support',
            })
        } else {
            const userToken = localStorage.getItem('token')
            if (!userToken) {
                toast.warning("Please login!")
            } else {
                props.history.push({
                    pathname: '/subscription-plan-details/' + `${planDetails.planId}`,
                    // planDetails: planDetails // your data array of objects
                })
            }
        }
    }
    let details = props.subscriptionPlanlist ? props.subscriptionPlanlist : []
    var isPresent;
    var filteredPlanDetails = [];
    var matchWords = ["Mobile App's"];
    for (var index = 0; index < details.length; index++) {
        isPresent = true;
        for (var outer = 0; outer < matchWords.length; outer++) {
            if (details[index].advertisement_type.indexOf(matchWords[outer]) === -1) {
                isPresent = false;
                break;
            }
        }
        if (isPresent) {
            filteredPlanDetails.push(details[index]);
        }
    }
    return (
        
        <>
            <Header />
            <div className='services-page'>
                {/* Top Banner Section */}
                <div className='banner-section' style={{ backgroundImage: `url(${ServicesBanner})` }}>
                    <div className='container'>
                        <div className='banner-info-cover'>
                            <div className='banner_subtitle'>Mobile App Design</div>
                            <div className='banner_title mb-3 mb-md-4'>& Development </div>
                            <div className='banner_description mb-4 mb-lg-5'>Take your business to the next level with an optimized Mobile App.
                                Make your App stand out from the rest with App’s cutting edge design & development services.</div>
                            <div className='btn-wrapper d-flex flex-column flex-md-row flex-wrap gap-3'>
                                <Link to={{ pathname: "/send-us-requirement" }} className='btn btn-custom btn-custom-outline'>Send Us Your Requirement</Link>
                                <Link to={{ pathname: "/register" }} className='btn btn-custom btn-custom-filled position-relative'>Register now</Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile App’s Services Section */}
                <div className='ecom-services services-section section-padding'>
                    <div className='container'>
                        <div className="text-center mx-auto pb-3" style={{ maxWidth: '670px' }}>
                            <div className="section-heading mb-2">Mobile App’s Services</div>
                            <div className="section-subheading">Take your mobile app to the next level with our service-launch faster, keep customers engaged and grow your business.</div>
                        </div>
                        <div className='row'>
                            {Data.map((service, index) => (
                                <div className='col-lg-4 col-sm-6 mt-4' key={index}>
                                    <div className='services-wrapper text-center h-100'>
                                        <div className='icon-wrapper mb-3'>
                                            <img className='img-fluid img-white' src={service.icon} alt="Icon" />
                                        </div>
                                        <div className='service-title text-white'>{service.title}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* Industry Section */}
                <div className='services-section section-padding' style={{ backgroundImage: `url(${LandingBanner})` }}>
                    <div className='container'>
                        <div className="text-center mx-auto pb-1" style={{ maxWidth: '750px' }}>
                            <div className="section-heading mb-3">Mobile App Industry Solutions</div>
                            <div className="section-subheading text-white">Take your business to the next level with a mobile app powered by industry solutions </div>
                        </div>
                        <div className='row'>
                            {IndustryData.map((service, index) => (
                                <div className='col-xl-4 col-lg-4 col-sm-6 mt-5' key={index}>
                                    <div className='services-wrapper text-center'>
                                        <div className='icon-wrapper mb-3'>
                                            <img className='img-fluid' src={service.icon} alt="Icon" style={{ width: '50px', height: '50px', filter: "invert(100%)" }} />
                                        </div>
                                        <div className='service-title mb-2 pb-1'>{service.title}</div>
                                        <div className='service-description'>{service.description}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Why Choose Us Section */}
                <div className='why-choose-section section-padding'>
                    <div className='container'>
                        <div className="text-center mx-auto mb-2 mb-md-4 pb-1" style={{ maxWidth: '670px' }}>
                            <div className="section-heading mb-2">Why Choose us</div>
                        </div>

                        <div className='row'>
                            {ChooseData.map((item, key) => (
                                <div className='col-xl-4 col-md-6 mt-3 pt-1' key={key}>
                                    <div className='data-wrapper d-flex g-3 h-100'>
                                        <div className='left-box'>
                                            <div className='num'>{item.id}</div>
                                        </div>
                                        <div className='right-box'>
                                            <div className='title mb-3'>{item.title}</div>
                                            <div className='description'>{item.description}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Our Packages Section */}
                {
                    props.subscriptionPlanlist.length > 0 ?
                        <div className='packages-section pricing-plan section-padding'>
                            <div className='container'>
                                <div className="text-center mx-auto mb-4" style={{ maxWidth: '654px' }}>
                                    <div className="section-heading mb-2">Mobile App Pricing</div>
                                    <div className="section-subheading">Unleash the potential of your idea with professional mobile app design and development from the experts.</div>
                                </div>

                                <div className='tab-section text-center mb-4 mb-md-5'>
                                    <div className="nav tab-wrapper d-inline-flex" id="nav-tab" role="tablist">
                                        <a className="tab-btn active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Quote</a>
                                    </div>
                                </div>

                                <div className="row g-3 g-lg-2">
                                    {filteredPlanDetails.map((value) => (
                                        <div className="col-lg-4 col-md-6" key={value._id}>
                                            <div className='package-item text-center h-100' >
                                                <div className='package-header'><label>{value.advertisement_type}</label></div>
                                                <div className='price-wrapper px-3 py-4'>
                                                    <div className='package-label'>{value.description_of_original_price}</div>
                                                    {/* <div className='package-price section-heading'>${value.price}</div> */}
                                                </div>
                                                <div className='package-info'>
                                                    <div className='list-cover text-start' dangerouslySetInnerHTML={{
                                                        __html:
                                                            (value.planDetail !== null) ? value.planDetail : "waiting"
                                                    }}></div>
                                                    <div className='btn-wrapper mt-4'>
                                                        <button className='btn btn-custom btn-custom-filled shadow-none' onClick={() => OnSubscriptionPlan({ planId: value._id, subscriptionType: value.subscription_type })}>CALL NOW</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div> : ''
                }
                {/* Services Section */}
                <ServiceSection />
                {/* Newsletter Section */}
                <div className='newsletter-section section-padding'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className="col-lg-8 text-center text-lg-start">
                                <div className="section-heading text-white">Servicehub Connect is the best way to Skyrocket your business</div>
                            </div>
                            <div className="col-lg-4 text-center text-lg-end mt-4 mt-lg-0">
                                <div className='btn-wrapper d-inline-flex justify-content-end flex-column gap-3'>
                                    <Link to={{ pathname: "/send-us-requirement" }} className='btn btn-custom btn-custom-outline border-white text-white'>Send Us Your Requirement</Link>
                                    <Link to={{ pathname: "/register" }} className='btn btn-custom btn-custom-filled bg-white border-white'>Register now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Download App Section */}
                <div className='download-app-section section-padding mt-xxl-5 pt-xl-3 pb-xl-0'>
                    <div className='container'>
                        <div className='row align-items-center align-items-xl-end flex-column-reverse flex-lg-row'>
                            <div className="col-lg-6">
                                <div className='info-wrapper my-xl-3 py-xl-5'>
                                    <div className='section-heading mb-4'>Download our Servicehub Connect App today</div>
                                    <div className='app-description mb-4 pb-2'>ServiceHubConnect is a reliable and secure platform for connecting customers and professional service providers.</div>
                                    <div className="d-flex gap-4">
                                        <a className="text-md-end" href="https://apps.apple.com/in/app/servicehubconnect/id1588399255" target="_blank">
                                            <img className="img-fluid" src="/static/media/app_store_ic.0f0fd86b.png" />
                                        </a>
                                        <a className="text-md-end" href="https://play.google.com/store/apps/details?id=com.Servicehubconnect" target="_blank">
                                            <img className="img-fluid" src="/static/media/play_store_ic.f4aa1def.png" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <div className='app-wrapper ps-xxl-3'>
                                    <img className='img-fluid' src={AppImg} alt="Download App" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>

            <Footer />
        </>
    )
}


const mapPropsToState = (state) => {
    return {
        subscriptionPlanlist: state.advertiseSubscription.subscriptionPlanlist,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        advertiseSubscriptionList: (data) => dispatch(actions.advertiseSubscriptionList(data)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(MobileAppPage))
