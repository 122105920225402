
import React, { useEffect, useState } from 'react'
// import Logo from "./images/logo.png";
function  Profile ()  {
     let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return (
      <div className='modalcont'>
        <div className='modalinner'>
          {/* <div className='logo' href='#'>
            <img   src={userInfo?userInfo.profile_pic:Logo} className='img-fluid' />
          </div> */}
          <h2>{userInfo?userInfo.name:"User Profile"}</h2>
          <div className='loginForm'>
            <div className='mb-3 mt-3'>
              <h4>Email : </h4>
              {userInfo?userInfo.email:''}
            </div>
            <div className='mb-3'>
              <h4>Phone Number : </h4>
              {userInfo?userInfo.country_code + userInfo.mobile_no:''}
            </div>
          </div>
        </div>
      </div>
    )
    
  }

  export default Profile