import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";
import {useHistory} from 'react-router-dom';



export const startAdvertiseSubscription = () => {
  return {
    type: actionTypes.START_ADVERTISEMENT_SUBSCRIPTION_PLAN
  };
};

export const advertiseSubscriptionFail = message => {
  return {
    type: actionTypes.ADVERTISEMENT_SUBSCRIPTION_PLAN_FAIL,
    message
  };
};

export const advertiseSubscriptionSuccess = (data) => {
    return {
      type: actionTypes.GET_ADVERTISEMENT_SUBSCRIPTION_SUCCESS,
      subscriptionPlanlist: data,
    };
  };

  


  export const advertiseSubscriptionList = (data) => {
    return dispatch => {
      dispatch(startAdvertiseSubscription());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("webCustomer/get-advertisement-subscription-listing" ,data, {
          headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
                dispatch(advertiseSubscriptionSuccess(response.data.data,response.data.message))
            } else {
              dispatch(advertiseSubscriptionFail(response.data.message));
            }
          } else {
            dispatch(advertiseSubscriptionFail(response.message));
          }
        })
        .catch(err => {
          dispatch(advertiseSubscriptionFail(err.message));
        });
    };
  };

  export const getAllSubscriptionPlanList = () => {
    return dispatch => {
      dispatch(startAdvertiseSubscription());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .get("webCustomer/get-all-subscription-plan-listing" , {
          headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
                dispatch(advertiseSubscriptionSuccess(response.data.data,response.data.message))
            } else {
              dispatch(advertiseSubscriptionFail(response.data.message));
            }
          } else {
            dispatch(advertiseSubscriptionFail(response.message));
          }
        })
        .catch(err => {
          dispatch(advertiseSubscriptionFail(err.message));
        });
    };
  };

  export const advertiseSubscriptionDetails = (data) => {
    return {
      type: actionTypes.GET_ADVERTISEMENT_SUBSCRIPTION_DETAILS,
      subscriptionPlanDetails: data,
    };
  };


  export const getSubscriptionDetails = (data) => {
    return dispatch => {
      dispatch(startAdvertiseSubscription());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .get("webCustomer/get-advertisement-subscription-details/"+data, {
          headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
                dispatch(advertiseSubscriptionDetails(response.data.data))
            } else {
              dispatch(advertiseSubscriptionFail(response.data.message));
            }
          } else {
            dispatch(advertiseSubscriptionFail(response.message));
          }
        })
        .catch(err => {
          dispatch(advertiseSubscriptionFail(err.message));
        });
    };
  };

  export const buyAdvertiseSubscriptionSuccess = message => {
    return {
      type: actionTypes.BUY_ADVERTISEMENT_SUBSCRIPTION_SUCCESS,
      message
    };
  };

  export const buyAdvertiseSubscriptionPlan = (data) => {
    return dispatch => {
      dispatch(startAdvertiseSubscription());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("webCustomer/create-adv-subscriptiob-by-business-user/",data, {
          headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
                dispatch(buyAdvertiseSubscriptionSuccess(response.data.message));
                const load = () => { window.location.replace('/thank-you') }
                setTimeout(load, 2000);
            } else {
              dispatch(advertiseSubscriptionFail(response.data.message));
            }
          } else {
            dispatch(advertiseSubscriptionFail(response.message));
          }
        })
        .catch(err => {
          dispatch(advertiseSubscriptionFail(err.message));
        });
    };
  };

