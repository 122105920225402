import React, { useEffect } from 'react'
import Header from "../Header";
import Footer from "../Footer";
import ServicesBanner from "../../assets/images/new/AddedValue.jpg";
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import * as actions from '../../store/actions/index'
import { Link, Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import {LoginHelperModal} from '../LoginHelperModal';

// Services Images
import ServicesIcon1 from "../../assets/images/new/socialMedia/seo.png";
import ServicesIcon2 from "../../assets/images/new/socialMedia/social-media-ad.png";
import ServicesIcon3 from "../../assets/images/new/socialMedia/bullhorn.png";
import ServicesIcon4 from "../../assets/images/new/socialMedia/social-media.png";
import ServicesIcon5 from "../../assets/images/new/socialMedia/place-localizer.png";
import ServicesIcon6 from "../../assets/images/new/socialMedia/customer.png";
import ServicesIcon7 from "../../assets/images/new/socialMedia/share.png";
import ServicesIcon8 from "../../assets/images/new/socialMedia/pay-per-click.png";
import ServicesIcon9 from "../../assets/images/new/socialMedia/urban.png";

// Our Packages Slick Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Download App Image
import AppImg from "../../assets/images/new/app-banner.jpg";
import { ServiceSection } from '../Component/ServiceSection';

const Data = [
    {
        icon: ServicesIcon1,
        title: 'SEO Service',
    },
    {
        icon: ServicesIcon2,
        title: 'Social Media Advertising',
    },
    {
        icon: ServicesIcon3,
        title: 'Google Local Ads Management',
    },
    {
        icon: ServicesIcon4,
        title: 'Social Media Design',
    },
    {
        icon: ServicesIcon5,
        title: 'Local SEO Service',
    },
    {
        icon: ServicesIcon6,
        title: 'Social Media Management',
    },
    {
        icon: ServicesIcon7,
        title: 'Youtube Social Media Advertising',
    },
    {
        icon: ServicesIcon8,
        title: 'PPC Management Service',
    },
    {
        icon: ServicesIcon9,
        title: 'Enterprise SEO Service',
    },
]


const ChooseData = [
    {
        id: '01',
        title: 'Digital Ad Experienced Team',
        description: 'We have an experienced and specialized team of digital advertising professionals to help you maximize your visibility and get the best ROI for your Ads.'
    },
    {
        id: '02',
        title: 'No Hidden fees',
        description: 'We tell you the costs upfront with our detailed proposals; we want all of us to have the same expectations for your project.'
    },
    {
        id: '03',
        title: 'Data Driven Optimization',
        description: 'Automatically optimize your digital ads to reach more users and generate more leads.'
    },
    {
        id: '04',
        title: 'Unique products and solutions',
        description: 'We strive to share our acquisitions and show the high quality of our products.'
    },
    {
        id: '05',
        title: 'Automated Ad Optimization',
        description: 'Get more impressions for your ad by having our Al algorithms constantly evaluate your campaign for potential improvement.'
    },
    {
        id: '06',
        title: 'Cross Device Compatibility',
        description: 'Make sure your message gets to the right customers regardless of the device they are using through seamless integration on web, mobile and tablet.'
    }
]

// Slick Props
var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};



const AddedValueMarketing = (props) => {
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
        props.getAllSubscriptionPlanList()
    }, [])
    const userToken = localStorage.getItem('token')
    const OnSubscriptionPlan = (planDetails) => {
        if (planDetails.subscriptionType == 'quote') {
            props.history.push({
                pathname: '/support',
            })
        } else {
            const userToken = localStorage.getItem('token')
            if (!userToken) {
                toast.warning("Please login!")
            } else {
                props.history.push({
                    pathname: '/subscription-plan-details/' + `${planDetails.planId}`,
                    // planDetails: planDetails // your data array of objects
                })
            }
        }
    }
    let details = props.subscriptionPlanlist ? props.subscriptionPlanlist : []
    var isPresent;
    var filteredPlanDetails = [];
    var matchWords = ['(Marketing)'];
    for (var index = 0; index < details.length; index++) {
        isPresent = true;
        for (var outer = 0; outer < matchWords.length; outer++) {
            if (details[index].advertisement_type.indexOf(matchWords[outer]) === -1) {
                isPresent = false;
                break;
            }
        }
        if (isPresent) {
            filteredPlanDetails.push(details[index]);
        }
    }

    return (

        <>
            <Header />
            <div className='services-page'>
                {/* Top Banner Section */}
                <div className='banner-section' style={{ backgroundImage: `url(${ServicesBanner})` }}>
                    <div className='container'>
                        <div className='banner-info-cover'>
                            <div className='banner_subtitle'>Added Value (Add-On)</div>
                            <div className='banner_title mb-3 mb-md-4'>for Marketing </div>
                            <div className='banner_description mb-4 mb-lg-5'>Take your marketing efforts to the next level with added value services. Get the competitive edge with customizable add-on features for your campaigns.</div>
                            <div className='btn-wrapper d-flex flex-column flex-md-row flex-wrap gap-3'>
                                <Link to={{ pathname: "/send-us-requirement" }} className='btn btn-custom btn-custom-outline'>Send Us Your Requirement</Link>
                                <Link to={{ pathname: "/register" }} className='btn btn-custom btn-custom-filled position-relative'>Register now</Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Professional website Services Section */}
                <div className='ecom-services services-section section-padding'>
                    <div className='container'>
                        <div className="text-center mx-auto pb-3" style={{ maxWidth: '670px' }}>
                            <div className="section-heading mb-2">Added Value (add-on) for marketing services</div>
                            <div className="section-subheading">Make the most out of your marketing strategy with added value and add-on services from ServiceHubConncet.</div>
                        </div>
                        <div className='row'>
                            {Data.map((service, index) => (
                                <div className='col-lg-4 col-sm-6 mt-4' key={index}>
                                    <div className='services-wrapper text-center h-100'>
                                        <div className='icon-wrapper mb-3'>
                                            <img className='img-fluid img-white' src={service.icon} alt="Icon" />
                                        </div>
                                        <div className='service-title text-white'>{service.title}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Why Choose Us Section */}
                <div className='why-choose-section section-padding'>
                    <div className='container'>
                        <div className="text-center mx-auto mb-2 mb-md-4 pb-1" style={{ maxWidth: '670px' }}>
                            <div className="section-heading mb-2">Why Choose us</div>
                        </div>
                        <div className='row'>
                            {ChooseData.map((item, key) => (
                                <div className='col-xl-4 col-md-6 mt-3 pt-1' key={key}>
                                    <div className='data-wrapper d-flex g-3 h-100'>
                                        <div className='left-box'>
                                            <div className='num'>{item.id}</div>
                                        </div>
                                        <div className='right-box'>
                                            <div className='title mb-3'>{item.title}</div>
                                            <div className='description'>{item.description}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Our Packages Section */}
                {
                    props.subscriptionPlanlist.length > 0 ?
                        <div className='packages-section pricing-plan section-padding'>
                            <div className='container'>
                                <div className="text-center mx-auto mb-4" style={{ maxWidth: '654px' }}>
                                    <div className="section-heading mb-2">Added Value (Add-On) Pricing</div>
                                    <div className="section-subheading">Unlock hidden value in your business by using the added value and add-on features of our pricing plans.</div>
                                </div>
                                <div className="row g-3 g-lg-2">
                                    <Slider {...settings} className="custom-slick mx-3 mx-md-0">
                                        {filteredPlanDetails.map((key) => (
                                            <div className="col-lg-4 col-md-6">
                                                <div className='package-item text-center h-100' key={key}>
                                                    <div className='package-header'><label>{key.advertisement_type}</label></div>
                                                    <div className='price-wrapper px-3 py-4'>
                                                        <div className='package-label'>{key.subscription_type == 'monthly' || key.subscription_type == 'quote' ? key.description_of_original_price : `${key.description_of_original_price ? `${key.description_of_original_price} $${key.original_price}` : ''}`}</div>
                                                        <div className='package-price section-heading'>${key.price}<span className='package-label plan_type'>{key.subscription_type == 'monthly' ? '/Month' : `${key.subscription_type == 'yearly' ? '/Year' : ''}`}</span></div>
                                                    </div>
                                                    <div className='package-info'>
                                                        <div className='list-cover text-start' dangerouslySetInnerHTML={{
                                                            __html:
                                                                (key.planDetail !== null) ? key.planDetail : "waiting"
                                                        }}></div>
                                                        <div className='btn-wrapper mt-4'>
                                                            {
                                                                !userToken ?
                                                                    <LoginHelperModal /> :
                                                                    <button className='btn btn-custom btn-custom-filled shadow-none' onClick={() => OnSubscriptionPlan({ planId: key._id, subscriptionType: key.subscription_type })}>BUY NOW</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div> : ''
                }

                {/* Services Section */}
                <ServiceSection />
                {/* Newsletter Section */}
                <div className='newsletter-section section-padding'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className="col-lg-8 text-center text-lg-start">
                                <div className="section-heading text-white">Servicehub Connect is the best way to Skyrocket your business</div>
                            </div>
                            <div className="col-lg-4 text-center text-lg-end mt-4 mt-lg-0">
                                <div className='btn-wrapper d-inline-flex justify-content-end flex-column gap-3'>
                                    <Link to={{ pathname: "/send-us-requirement" }} className='btn btn-custom btn-custom-outline border-white text-white'>Send Us Your Requirement</Link>
                                    <Link to={{ pathname: "/register" }} className='btn btn-custom btn-custom-filled bg-white border-white'>Register now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Download App Section */}
                <div className='download-app-section section-padding mt-xxl-5 pt-xl-3 pb-xl-0'>
                    <div className='container'>
                        <div className='row align-items-center align-items-xl-end flex-column-reverse flex-lg-row'>
                            <div className="col-lg-6">
                                <div className='info-wrapper my-xl-3 py-xl-5'>
                                    <div className='section-heading mb-4'>Download our Servicehub Connect App today</div>
                                    <div className='app-description mb-4 pb-2'>ServiceHubConnect is a reliable and secure platform for connecting customers and professional service providers.</div>
                                    <div className="d-flex gap-4">
                                        <a className="text-md-end" href="https://apps.apple.com/in/app/servicehubconnect/id1588399255" target="_blank">
                                            <img className="img-fluid" src="/static/media/app_store_ic.0f0fd86b.png" />
                                        </a>
                                        <a className="text-md-end" href="https://play.google.com/store/apps/details?id=com.Servicehubconnect" target="_blank">
                                            <img className="img-fluid" src="/static/media/play_store_ic.f4aa1def.png" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <div className='app-wrapper ps-xxl-3'>
                                    <img className='img-fluid' src={AppImg} alt="Download App" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>

            <Footer />
        </>
    )
}


const mapPropsToState = (state) => {
    return {
        subscriptionPlanlist: state.advertiseSubscription.subscriptionPlanlist,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // advertiseSubscriptionList: (data) => dispatch(actions.advertiseSubscriptionList(data)),
        getAllSubscriptionPlanList: () => dispatch(actions.getAllSubscriptionPlanList()),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(AddedValueMarketing))
