import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import Login from "../Login";
import Signup from "../Signup";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import 'react-awesome-slider/dist/styles.css';

import * as actions from '../../store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import ReactPlayer from 'react-player'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import { timers } from "jquery";
import { Autoplay } from "swiper";
import '../../App.css'
class Homepage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // latitude:44.55,
      // longitude:88.75
      playing: true
    }
  }

  // this.state:{},
  async componentWillMount() {
    this.props.fetchCategory();

    await navigator.geolocation.getCurrentPosition(async (position) => {
      await localStorage.setItem("latitude", position.coords.latitude);
      await localStorage.setItem("longitude", position.coords.longitude);

    })
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });

  }
  async componentDidMount() {
    let lat = await localStorage.getItem("latitude")
    let long = await localStorage.getItem("longitude")
    let data = {
      page_type: 'first',
      latitude: parseFloat(lat),
      longitude: parseFloat(long)
    }

    await this.props.getAdvertisementPictureList1(data);
    await this.props.getAdvertisementVideoList1(data);
    await this.props.getBannerPictureList1(data);
    await this.props.getSquarePictureList1(data);
    await this.props.getBannerVideoList1(data);
    await this.props.getSquareVideoList1(data);

  }
  render() {
    const userToken = localStorage.getItem('token')
    let categoryList = [];
    let i = 1;
    {
      this.props.categorylisting.length > 0
        ? this.props.categorylisting.map(datas => {
          categoryList.push(
            (i === 1) ?
              <div className="col-6 col-lg-3 col-md-4">
                <div className="dashItem h-100" style={{ background: "#fef7da" }}>
                  {
                    // !userToken?
                    // <div><img src={datas.category_images} />
                    // <h3>{datas.name}</h3></div>:
                    <div>
                      <Link to={{
                        pathname: "/subcategory",
                        aboutProps: {
                          name: datas._id,
                          category: datas.name
                        }
                      }}
                      // {"/subcategory/"+datas._id}
                      >
                        <img src={datas.category_images} />
                        <h3>{datas.name}</h3>
                      </Link></div>
                  }
                </div>
              </div>
              : (i === 2) ?
                <div className="col-6 col-lg-3 col-md-4">
                  <div className="dashItem h-100" style={{ background: "#fee7db" }}>
                    {
                      // !userToken?
                      // <div><img src={datas.category_images} />
                      // <h3>{datas.name}</h3></div>:
                      <div>
                        <Link
                          to={{
                            pathname: "/subcategory",
                            aboutProps: {
                              name: datas._id,
                              category: datas.name
                            }
                          }}
                        // to={"/subcategory/"+datas._id}
                        >
                          <img src={datas.category_images} />
                          <h3>{datas.name}</h3>
                        </Link></div>
                    }
                  </div>
                </div>
                : (i === 3) ?
                  <div className="col-6 col-lg-3 col-md-4">
                    <div className="dashItem h-100" style={{ background: "#f0ddfe" }}>
                      {
                        // !userToken?
                        // <div><img src={datas.category_images} />
                        // <h3>{datas.name}</h3></div>:
                        <div>
                          <Link
                            to={{
                              pathname: "/subcategory",
                              aboutProps: {
                                name: datas._id,
                                category: datas.name
                              }
                            }}
                          // to={"/subcategory/"+datas._id}
                          >
                            <img src={datas.category_images} />
                            <h3>{datas.name}</h3>
                          </Link></div>
                      }
                    </div>
                  </div>
                  :
                  (i === 4) ?
                    <div className="col-6 col-lg-3 col-md-4">
                      <div className="dashItem h-100" style={{ background: "#ddf0ff" }}>
                        {
                          // !userToken?
                          // <div><img src={datas.category_images} />
                          // <h3>{datas.name}</h3></div>:
                          <div>
                            <Link
                              to={{
                                pathname: "/subcategory",
                                aboutProps: {
                                  name: datas._id,
                                  category: datas.name
                                }
                              }}
                            //  to={"/subcategory/"+datas._id}
                            >
                              <img src={datas.category_images} />
                              <h3>{datas.name}</h3>
                            </Link></div>
                        }
                      </div>
                    </div> :
                    <div className="col-6 col-lg-3 col-md-4">
                      <div className="dashItem h-100" style={{ background: "#f0ddfe" }}>
                        {
                          // !userToken?
                          // <div><img src={datas.category_images} />
                          // <h3>{datas.name}</h3></div>:
                          <div>
                            <Link
                              to={{
                                pathname: "/subcategory",
                                aboutProps: {
                                  name: datas._id,
                                  category: datas.name
                                }
                              }}
                            // to={"/subcategory/"+datas._id}
                            >
                              <img src={datas.category_images} />
                              <h3>{datas.name}</h3>
                            </Link></div>
                        }
                      </div>
                    </div>
          )
          if (i === 5) {
            i = 0;
          }
          ++i;
        })
        : categoryList.push(

          <div>

          </div>
        )
    }
    return (
      <>
        <Header />
        <section className="my-5">
          <div className="container">
            {/* for video banner */}
            {/* <ReactPlayer playing url ={videolist } /> */}

            {
              this.props.advertisementBannerVideoList1.length > 0 ?
                <div className="video-wrapper row g-md-0">
                  <div className="col-md-6">
                    <div className="slide-container">
                      <Slide arrows={false} duration={5000} >
                        {this.props.advertisementBannerVideoList1.map((slideImage, index) => (
                          <div className="each-slide" key={index}>
                            <a
                              target="_blank" href={'https://' + slideImage.website}
                            >
                              <div >
                                <ReactPlayer url={slideImage.video_url} playing={true} volume={0} controls={true}
                                  loop={true} width={'100%'} ></ReactPlayer>
                              </div>
                            </a>
                          </div>
                        ))}
                      </Slide>

                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="slide-container">
                      <Slide arrows={false} duration={5000} >

                        {this.props.advertisementBannerVideoList1.map((slideImage, index) => (
                          <div className="each-slide" key={index}>
                            <a
                              target="_blank" href={'https://' + slideImage.website}
                            // onclick={()=>this.link(e.website)} 
                            >
                              <div >
                                <ReactPlayer url={slideImage.video_url} playing={true} volume={0} controls={true}
                                  loop={true} width={'100%'}></ReactPlayer>
                              </div>
                            </a>
                          </div>
                        ))}
                      </Slide>

                    </div>
                  </div>
                </div> :
                ''
            }

            {/* for Picture banner */}
            {this.props.advetisementBannerPictureList1.length > 0 ?
              <div className="video-wrapper row g-md-0">
                <div className="col-md-6">
                  <div className="slide-container fgfd">
                    <Slide arrows={false} duration={3000}>

                      {this.props.advetisementBannerPictureList1.map((slideImage, index) => (
                        <div className="each-slide" key={index}>
                          <a
                            target="_blank" href={'https://' + slideImage.website}
                          >
                            <div >
                              <img src={slideImage.picture_url} style={{
                                // height: '60vh',
                                width: '100%',
                              }} />

                            </div>
                          </a>
                        </div>
                      ))}
                    </Slide>
                  </div>
                </div>

                <div className="col-md-6 mt-3 mt-md-0">
                  <div className="slide-container">
                    <Slide arrows={false} duration={3000}>
                      {this.props.advetisementBannerPictureList1.map((slideImage, index) => (
                        <div className="each-slide" key={index}>
                          <a
                            target="_blank" href={'https://' + slideImage.website}
                          // onclick={()=>this.link(e.website)} 
                          >
                            <div >
                              <img src={slideImage.picture_url} style={{
                                // height: '60vh',
                                width: '100%',
                              }} />

                            </div>
                          </a>
                        </div>
                      ))}
                    </Slide>
                  </div>
                </div>
              </div> :
              ''
            }

            {/* Unlimited Square Video*/}
            {this.props.advertisementSquareVideoList1.length > 0 ?
              <div className="video-wrapper row g-md-0">

                {this.props.advertisementSquareVideoList1.map(e => (
                  <div className="col-md-6">
                    <a
                      target="_blank" href={'https://' + e.website}
                    >
                      <div >
                        <ReactPlayer url={e.video_url} playing={true} volume={0} controls={true}
                          loop={true} width={'100%'} ></ReactPlayer>
                      </div>
                    </a>
                  </div>

                ))}

              </div>
              : ''}

            {/* Unlimited Square Picture*/}
            {this.props.advetisementSquarePictureList1.length > 0 ?
              <div className="video-wrapper row g-md-0">
                {this.props.advetisementSquarePictureList1.map(e => (
                  <div className="col-md-6">
                    <a
                      target="_blank" href={'https://' + e.website}
                    >
                      <div >
                        <img src={e.picture_url} style={{
                          // height: '50vh',
                          width: '100%',
                        }} />

                      </div>
                    </a>
                  </div>
                ))}
              </div>
              : ''}


            {/* <h1>Website shows features for now. To order download the app.</h1> */}
            <h2><u>Industries</u></h2>
            <div className="row dashItemCont mt-4 g-3 g-md-4">
              {categoryList}
            </div>

          </div>
        </section>
        <Footer />
        {/* <Login /> */}
        {/* <Signup /> */}
      </>
    );
  }
}

const mapPropsToState = (state) => {
  return {
    categorylisting: state.category.categorylisting,
    advertisementPicture: state.advertisementPicture.advetisementPictureList1,
    advertisementVideoList1: state.advertisementPicture.advertisementVideoList1,
    advertisementBannerVideoList1: state.advertisementPicture.advertisementBannerVideoList1,
    advertisementSquareVideoList1: state.advertisementPicture.advertisementSquareVideoList1,
    advetisementBannerPictureList1: state.advertisementPicture.advetisementBannerPictureList1,
    advetisementSquarePictureList1: state.advertisementPicture.advetisementSquarePictureList1
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCategory: () => dispatch(actions.fetchcategoryListAction()),
    getAdvertisementPictureList1: (data) => dispatch(actions.getAdvertisementPictureList1(data)),
    getAdvertisementVideoList1: (data) => dispatch(actions.getAdvertisementVideoList1(data)),
    getBannerPictureList1: (data) => dispatch(actions.getBannerPictureList1(data)),
    getSquarePictureList1: (data) => dispatch(actions.getSquarePictureList1(data)),
    getBannerVideoList1: (data) => dispatch(actions.getBannerVideoList1(data)),
    getSquareVideoList1: (data) => dispatch(actions.getSquareVideoList1(data))
  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(Homepage))
