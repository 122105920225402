import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "../store/actions/index";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import VerifyOtp from './VerifyOtp';
import Header from "./Header";
import Footer from "./Footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Signup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      formErrName: '',
      businessOwnerName: '',
      formErrBusinessOwnerName: '',
      email: '',
      formErremail: '',
      password: '',
      formErrpassword: '',
      confirmPassword: '',
      formErrconfirmPassword: '',
      countyCode: '',
      formErrcountyCode: '',
      phoneNumber: '',
      formErrphoneNumber: '',
      showPassword: false,
      showConfirmPassword: false
    }
  }

  componentWillMount = () => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }
  showPassword = (confirm) => {
    if (confirm) {
      if (this.state.showConfirmPassword) {
        this.setState({ showConfirmPassword: false })
      } else {
        this.setState({ showConfirmPassword: true })
      }
    } else {
      if (this.state.showPassword) {
        this.setState({ showPassword: false })
      } else {
        this.setState({ showPassword: true })
      }
    }

  }
  handleSubmit = async () => {
    await this.setState({
      formErrName: (this.state.name === '') ? "Please provide this field" : '',
      formErrBusinessOwnerName: (this.state.businessOwnerName === '') ? "Please provide this field" : '',
      formErrcountyCode: (this.state.countyCode === '') ? "Please provide this field" : '',
      formErrphoneNumber: (this.state.phoneNumber === '') ? "Please provide this field" : '',
      formErremail: (this.state.email === '') ? "Please provide this field" : '',
      formErrpassword: (this.state.password == '') ? "Please provide this field" : '',
      formErrconfirmPassword: (this.state.confirmPassword == '') ? "Please provide this field" : '',
    })
    if (((this.state.email !== '') && (this.state.password !== '') && (this.state.confirmPassword !== '') &&
      (this.state.name !== '') && (this.state.businessOwnerName !== '') && (this.state.countyCode !== '')
      && (this.state.phoneNumber !== ''))) {
      if (this.state.password !== this.state.confirmPassword) {
        // alert('password does not match with confirm password')
        toast.warning("Password does not match with confirm password")
      }

      await this.props.SignUp({
        name: this.state.name,
        business_owner_name: this.state.businessOwnerName,
        country_code: '+' + this.state.countyCode,
        mobile_no: this.state.phoneNumber,
        email: this.state.email,
        password: this.state.password,
        // local_city: this.state.localCity
      })
      // await window.location.reload(true);

    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { enqueueSnackbar, error, success, signup_success, signup_error } = this.props;
    success && enqueueSnackbar(success, { variant: "success" });
    error && enqueueSnackbar(error, { variant: "error" });
    signup_success && enqueueSnackbar(signup_success, { variant: "success" });
    signup_error && enqueueSnackbar(signup_error, { variant: "error" });
  }
  onChangeAddName = async (key, value) => {
    var self = this;
    await self.setState({
      [key]: value,
      ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
    })
  }

  render() {

    return (
      <>
        <Header />
        <div className="modalcont">
          <div className="modalinner my-5 py-4">
            <h2>SignUp</h2>
            Please Enter Your Business Details
            <div className="loginForm signup">
              <div className='mb-3 mt-3'>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text h-100" id="basic-addon1">
                      <PersonIcon />
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    id='name'
                    placeholder="Enter Business Name"
                    value={this.state.name}
                    onChange={(e) => this.onChangeAddName('name', e.target.value)}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div className='text-danger'>{this.state.formErrName}</div>
              </div>
              <div className='mb-3 mt-3'>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text h-100" id="basic-addon1">
                      <PersonIcon />
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    id='businessOwnerName'
                    placeholder="Enter Business Owner Name"
                    value={this.state.businessOwnerName}
                    onChange={(e) => this.onChangeAddName('businessOwnerName', e.target.value)}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div className='text-danger'>{this.state.formErrBusinessOwnerName}</div>
              </div>

              <div className='mb-3 mt-3'>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <PhoneInput
                      country={"us"}
                      className="marginBottom"
                      id='countyCode'
                      value={this.state.countyCode}
                      onChange={(countyCode) => this.setState({ countyCode })}
                    // onChange={(e) => this.onChangeAddName('countyCode', e.target.value)}
                    />

                  </div>
                  <div className='text-danger'>{this.state.formErrcountyCode}</div>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Enter Mobile Number"
                    id='phoneNumber'
                    value={this.state.phoneNumber}
                    onChange={(e) => this.onChangeAddName('phoneNumber', e.target.value)}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div className='text-danger'>{this.state.formErrphoneNumber}</div>
              </div>
              <div className='mb-3 mt-3'>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text h-100" id="basic-addon1">
                      <EmailIcon />
                    </span>
                  </div>
                  <input
                    type="email"
                    class="form-control form-control-lg"
                    placeholder="Enter Email"
                    id='email'
                    value={this.state.email}
                    onChange={(e) => this.onChangeAddName('email', e.target.value)}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div className='text-danger'>{this.state.formErremail}</div>
              </div>
              <div className='mb-3 mt-3'>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text h-100" id="basic-addon1">
                      <LockIcon />
                    </span>
                  </div>
                  <input
                    type={this.state.showPassword ? 'text' : "password"}
                    class="form-control form-control-lg"
                    placeholder="Enter Password"
                    id='password'
                    value={this.state.password}
                    onChange={(e) => this.onChangeAddName('password', e.target.value)}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text h-100" id="basic-addon1" onClick={() => { this.showPassword(false) }}>
                      {this.state.showPassword ? <VisibilityOff /> : <VisibilityIcon />}
                    </span>
                  </div>
                </div>
                <div className='text-danger'>{this.state.formErrpassword}</div>
              </div>

              <div className='mb-3 mt-3'>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text h-100" id="basic-addon1">
                      <LockIcon />
                    </span>
                  </div>
                  <input
                    type={this.state.showConfirmPassword ? 'text' : "password"}
                    class="form-control form-control-lg"
                    placeholder="Enter Confirm Password"
                    id='confirmPassword'
                    value={this.state.confirmPassword}
                    onChange={(e) => this.onChangeAddName('confirmPassword', e.target.value)}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text h-100" id="basic-addon1" onClick={() => { this.showPassword(true) }}>
                      {this.state.showConfirmPassword ? <VisibilityOff /> : <VisibilityIcon />}
                    </span>
                  </div>
                </div>
                <div className='text-danger'>{this.state.formErrconfirmPassword}</div>
              </div>

              <div className="mt-3">
                <button
                  className="btn btn-primary w-100 btn-lg"
                  type="button"
                  onClick={(e) => this.handleSubmit()}
                >
                  SignUp
                </button>
              </div>

              {/* <div className="d-grid mt-3">
                <a
                  className="nav-link"
                  // href="#"
                  data-toggle="modal"
                  data-target="#exampleModal_verify"
                > <button
                  className="btn btn-primary btn-lg "
                  type="button"
                >
                    Verify User
                  </button></a>

              </div> */}
            </div>
            {/* <div>{
              this.props.askingForVerify ?
                <div
                  className="modal fade"
                  id="exampleModal_verify"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body">
                        <VerifyOtp />
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div> : ''
            }
            </div> */}

          </div>
          <ToastContainer />
        </div>
        <Footer />
      </>

    )
  }
}
const mapPropsToState = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    signup_error: state.auth.signup_error,
    signup_success: state.auth.signup_success,
    isAuthenticated: state.auth.token !== null,
    askingForVerify: state.auth.askingForVerify
  };
};

const mapDispatchToProps = dispatch => {
  return {
    SignUp: (SignUpdata) => dispatch(actions.register(SignUpdata))

  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(Signup));
