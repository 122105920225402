import React from 'react';
import { Link } from "react-router-dom";

// Services Images
import LandingBanner from "../../assets/images/new/landing-banner.png";
import ServicesIcon1 from "../../assets/images/new/e-commerce.svg";
import ServicesIcon2 from "../../assets/images/new/business.svg";
import ServicesIcon3 from "../../assets/images/new/digital-ads.svg";
import ServicesIcon4 from "../../assets/images/new/business-apps.svg";
import ServicesIcon5 from "../../assets/images/new/business-shop.svg";
import ServicesIcon6 from "../../assets/images/new/business-branding.svg";
import ServicesIcon7 from "../../assets/images/new/video-reviews.svg";
import ServicesIcon8 from "../../assets/images/new/business-directory.svg";
import ServicesIcon9 from "../../assets/images/new/social-media-marketing.svg";

const ServiceData = [
    {
        icon: ServicesIcon1,
        title: 'E-commerce',
        description: 'Grow your business with E-commerce website',
        routes: '/e-commerece-services'
    },
    {
        icon: ServicesIcon2,
        title: 'Professional Business Website',
        description: 'Stand out with Professional business website',
        routes: '/professional-business-website'
    },
    {
        icon: ServicesIcon3,
        title: 'Digital Ads',
        description: 'Skyrocket your business with Digital Ads',
        routes: '/digital-ads'
    },
    {
        icon: ServicesIcon4,
        title: 'Mobile Apps',
        description: 'Unlock your App’s Potential now',
        routes: '/mobile-app'
    },
    {
        icon: ServicesIcon5,
        title: 'Small business consulting',
        description: 'Get expert advise for you small business',
        routes: '/small-branding-and-consulting'
    },
    {
        icon: ServicesIcon6,
        title: 'Small business branding',
        description: 'Stand out with small business Branding',
        routes: '/small-branding-and-consulting'
    },
    {
        icon: ServicesIcon7,
        title: 'Video Reviews',
        description: 'Build your business with Video Reviews',
        routes: '/added-value-marketing'
    },
    {
        icon: ServicesIcon8,
        title: 'Business Directory',
        description: 'Grow your business with our directory',
        routes: '/added-value-marketing'
    },
    {
        icon: ServicesIcon9,
        title: 'Social Media Marketing',
        description: 'Get more eyes on your product with digital marketing solutions',
        routes: '/social-media'
    }
]

export const ServiceSection = () => {
    return (

        <div className='services-section section-padding' style={{ backgroundImage: `url(${LandingBanner})` }}>
            <div className='container'>
                <div className="text-center mx-auto pb-1" style={{ maxWidth: '750px' }}>
                    <div className="section-heading mb-3">Our Services</div>
                    <div className="section-subheading text-white">Maximize your online presence and take your business to the next level with cutting-edge website design, development, and digital marketing services</div>
                </div>

                <div className='row'>
                    {ServiceData.map((service, index) => (
                        <div className='col-xl-4 col-lg-4 col-sm-6 mt-5' key={index}>
                            <Link to={{ pathname: service.routes }} >
                                <div className='services-wrapper text-center'>
                                    <div className='icon-wrapper mb-3'>
                                        <img className='img-fluid' src={service.icon} alt="Icon" style={{ width: '50px', height: '50px', filter: `${index == 8 ? "invert(100%)" : ''}` }} />
                                    </div>
                                    <div className='service-title mb-2 pb-1'>{service.title}</div>
                                    <div className='service-description'>{service.description}</div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}
