import React, { useEffect } from 'react'
import Header from "../Header";
import Footer from "../Footer";
import ServicesBanner from "../../assets/images/new/professionalwebsite-banner.png";
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import * as actions from '../../store/actions/index'
import { Link, Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import {LoginHelperModal} from '../LoginHelperModal';
// Services Images
import ServicesIcon1 from "../../assets/images/new/professionalWebsite/hosting.png";
import ServicesIcon2 from "../../assets/images/new/professionalWebsite/seo.png";
import ServicesIcon3 from "../../assets/images/new/professionalWebsite/anchor.png";
import ServicesIcon4 from "../../assets/images/new/professionalWebsite/responsive.png";
import ServicesIcon5 from "../../assets/images/new/professionalWebsite/settings.png";
import ServicesIcon6 from "../../assets/images/new/professionalWebsite/service.png";
import ServicesIcon7 from "../../assets/images/new/professionalWebsite/contact-form.png";
import ServicesIcon8 from "../../assets/images/new/professionalWebsite/analytics.png";
import ServicesIcon9 from "../../assets/images/new/professionalWebsite/mobile-friendly.png";

// Our Packages Slick Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Download App Image
import AppImg from "../../assets/images/new/app-banner.jpg";
import { ServiceSection } from '../Component/ServiceSection';

const Data = [
  {
    icon: ServicesIcon1,
    title: 'Optimized Hosting',
  },
  {
    icon: ServicesIcon2,
    title: 'Search Engine Optimization Tools',
  },
  {
    icon: ServicesIcon3,
    title: 'Easy to use website builder',
  },
  {
    icon: ServicesIcon4,
    title: 'Responsive Design',
  },
  {
    icon: ServicesIcon5,
    title: 'Hassle Free Setup',
  },
  {
    icon: ServicesIcon6,
    title: 'Comprehensive Customization',
  },
  {
    icon: ServicesIcon7,
    title: 'Contact Form',
  },
  {
    icon: ServicesIcon8,
    title: 'Website Analytics',
  },
  {
    icon: ServicesIcon9,
    title: 'Mobile users friendly',
  },
]

const ChooseData = [
  {
    id: '01',
    title: 'Friendly, Personable Service',
    description: '100% of our business is from word-of-mouth and repeat business. You’ll also get the experience of people that have been building websites for over 10 years.'
  },
  {
    id: '02',
    title: 'No Hidden fees',
    description: 'We tell you the costs upfront with our detailed proposals; we want all of us to have the same expectations for your project.'
  },
  {
    id: '03',
    title: 'Cross browser tested',
    description: 'We’ll make sure your website looks good in the latest versions of the major web browsers.'
  },
  {
    id: '04',
    title: 'Unique products and solutions',
    description: 'We strive to share our acquisitions and show the high quality of our products.'
  },
  {
    id: '05',
    title: 'Our websites are flexible',
    description: 'New functionality can be added seamlessly and quickly without reinventing the wheel.'
  },
  {
    id: '06',
    title: 'We put you in control',
    description: 'Every website comes with a password-protected control panel so you can keep your website updated on your schedule.'
  }
]

const ProfessionalWebsite = (props) => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
    props.advertiseSubscriptionList({ subscription_type: "onetime" })
  }, [])
  const userToken = localStorage.getItem('token')
  const OnSubscriptionPlan = (planDetails) => {
    if (planDetails.subscriptionType == 'quote') {
      props.history.push({
        pathname: '/support',
      })
    } else {
      const userToken = localStorage.getItem('token')
      if (!userToken) {
        toast.warning("Please login!")
      } else {
        props.history.push({
          pathname: '/subscription-plan-details/' + `${planDetails.planId}`,
          // planDetails: planDetails // your data array of objects
        })
      }
    }
  }

  let details = props.subscriptionPlanlist ? props.subscriptionPlanlist : []
    var isPresent;
    var filteredPlanDetails = [];
    var matchWords = ["Professional Business"];
    for (var index = 0; index < details.length; index++) {
        isPresent = true;
        for (var outer = 0; outer < matchWords.length; outer++) {
            if (details[index].advertisement_type.indexOf(matchWords[outer]) === -1) {
                isPresent = false;
                break;
            }
        }
        if (isPresent) {
            filteredPlanDetails.push(details[index]);
        }
    }

  return (
    <>
      <Header />

      <div className='services-page'>
        {/* Top Banner Section */}
        <div className='banner-section' style={{ backgroundImage: `url(${ServicesBanner})` }}>
          <div className='container'>
            <div className='banner-info-cover'>
              <div className='banner_subtitle'>Create a Professional </div>
              <div className='banner_title mb-3 mb-md-4'>Business Website</div>
              <div className='banner_description mb-4 mb-lg-5'>Take your business to the next level with a professionally designed website that showcases your services and puts you ahead of the competition.</div>
              <div className='btn-wrapper d-flex flex-column flex-md-row flex-wrap gap-3'>
                <Link to={{ pathname: "/send-us-requirement" }} className='btn btn-custom btn-custom-outline'>Send Us Your Requirement</Link>
                <Link to={{ pathname: "/register" }} className='btn btn-custom btn-custom-filled position-relative'>Register now</Link>
              </div>
            </div>
          </div>
        </div>

        {/* Professional website Services Section */}
        <div className='ecom-services services-section section-padding'>
          <div className='container'>
            <div className="text-center mx-auto pb-3" style={{ maxWidth: '670px' }}>
              <div className="section-heading mb-2">Professional Business Website Services</div>
              <div className="section-subheading">Create an online presence that stands out from the crowd with a professional business website from ServiceHubConncet</div>
            </div>
            <div className='row'>
              {Data.map((service, index) => (
                <div className='col-lg-4 col-sm-6 mt-4' key={index}>
                  <div className='services-wrapper text-center h-100'>
                    <div className='icon-wrapper mb-3'>
                      <img className='img-fluid img-white' src={service.icon} alt="Icon" />
                    </div>
                    <div className='service-title text-white'>{service.title}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Why Choose Us Section */}
        <div className='why-choose-section section-padding'>
          <div className='container'>
            <div className="text-center mx-auto mb-2 mb-md-4 pb-1" style={{ maxWidth: '670px' }}>
              <div className="section-heading mb-2">Why Choose us</div>
            </div>

            <div className='row'>
              {ChooseData.map((item, key) => (
                <div className='col-xl-4 col-md-6 mt-3 pt-1' key={key}>
                  <div className='data-wrapper d-flex g-3 h-100'>
                    <div className='left-box'>
                      <div className='num'>{item.id}</div>
                    </div>
                    <div className='right-box'>
                      <div className='title mb-3'>{item.title}</div>
                      <div className='description'>{item.description}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Our Packages Section */}
        {
          props.subscriptionPlanlist.length > 0 ?
            <div className='packages-section pricing-plan section-padding'>
              <div className='container'>
                <div className="text-center mx-auto mb-4" style={{ maxWidth: '654px' }}>
                  <div className="section-heading mb-2">Professional business website pricing</div>
                  <div className="section-subheading">Get a professional website quickly and easily with our unbeatable business website pricing</div>
                </div>

                <div className='tab-section text-center mb-4 mb-md-5'>
                  <div class="nav tab-wrapper d-inline-flex" id="nav-tab" role="tablist">
                    <a class="tab-btn active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Onetime</a>
                  </div>
                </div>

                <div className="row g-3 g-lg-2">
                  {filteredPlanDetails.map((key) => (
                    <div className="col-lg-4 col-md-6">
                      <div className='package-item text-center h-100' key={key}>
                        <div className='package-header'><label>{key.advertisement_type}</label></div>
                        <div className='price-wrapper px-3 py-4'>
                          <div className='package-label'>{key.description_of_original_price}</div>
                          <div className='package-price section-heading'>${key.price}</div>
                        </div>
                        <div className='package-info'>
                          <div className='list-cover text-start' dangerouslySetInnerHTML={{
                            __html:
                              (key.planDetail !== null) ? key.planDetail : "waiting"
                          }}></div>
                          <div className='btn-wrapper mt-4'>
                            {
                              !userToken ?
                                <LoginHelperModal /> :
                                <button className='btn btn-custom btn-custom-filled shadow-none' onClick={() => OnSubscriptionPlan({ planId: key._id, subscriptionType: key.subscription_type })}>BUY NOW</button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div> : ''
        }
        {/* Services Section */}
        <ServiceSection />

        {/* Newsletter Section */}
        <div className='newsletter-section section-padding'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className="col-lg-8 text-center text-lg-start">
                <div className="section-heading text-white">Servicehub Connect is the best way to Skyrocket your business</div>
              </div>
              <div className="col-lg-4 text-center text-lg-end mt-4 mt-lg-0">
                <div className='btn-wrapper d-inline-flex justify-content-end flex-column gap-3'>
                  <Link to={{ pathname: "/send-us-requirement" }} className='btn btn-custom btn-custom-outline border-white text-white'>Send Us Your Requirement</Link>
                  <Link to={{ pathname: "/register" }} className='btn btn-custom btn-custom-filled bg-white border-white'>Register now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Download App Section */}
        <div className='download-app-section section-padding mt-xxl-5 pt-xl-3 pb-xl-0'>
          <div className='container'>
            <div className='row align-items-center align-items-xl-end flex-column-reverse flex-lg-row'>
              <div className="col-lg-6">
                <div className='info-wrapper my-xl-3 py-xl-5'>
                  <div className='section-heading mb-4'>Download our Servicehub Connect App today</div>
                  <div className='app-description mb-4 pb-2'>ServiceHubConnect is a reliable and secure platform for connecting customers and professional service providers.</div>
                  <div className="d-flex gap-4">
                    <a className="text-md-end" href="https://apps.apple.com/in/app/servicehubconnect/id1588399255" target="_blank">
                      <img className="img-fluid" src="/static/media/app_store_ic.0f0fd86b.png" />
                    </a>
                    <a className="text-md-end" href="https://play.google.com/store/apps/details?id=com.Servicehubconnect" target="_blank">
                      <img className="img-fluid" src="/static/media/play_store_ic.f4aa1def.png" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mb-4 mb-lg-0">
                <div className='app-wrapper ps-xxl-3'>
                  <img className='img-fluid' src={AppImg} alt="Download App" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>

      <Footer />
    </>
  )
}


const mapPropsToState = (state) => {
  return {
    subscriptionPlanlist: state.advertiseSubscription.subscriptionPlanlist,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    advertiseSubscriptionList: (data) => dispatch(actions.advertiseSubscriptionList(data)),
  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(ProfessionalWebsite))
