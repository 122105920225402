import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "../store/actions/index";
import Header from './Header';
import Footer from './Footer';
// import Logo from "./images/logo.png";

class VerifyOtp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      otp: '',
      formErrotp: '',
      email: localStorage.getItem('registerUserEmail')
    }

  }
  handleSubmit = async () => {
    await this.setState({
      formErremail: (this.state.otp === '') ? "Please provide this field" : '',
    })
    if (!(this.state.otp === '')) {
      await this.props.verifyOtp({
        email: this.state.email,
        otp: this.state.otp,
      })
      // await window.location.reload(true);

    }
  }

  otpSubmit = async () => {
    await this.props.resendOtp({
      email: this.state.email,
      from: 'Signup'
    })
  }


  componentDidUpdate(prevProps, prevState) {
    const { enqueueSnackbar, error, success, signup_success, signup_error } = this.props;
    signup_success && enqueueSnackbar(signup_success, { variant: "success" });
    signup_error && enqueueSnackbar(signup_error, { variant: "error" });
  }
  onChangeAddName = async (key, value) => {
    var self = this;
    await self.setState({
      [key]: value,
      ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
    })
  }
  render() {
    return (
      <>
        <Header />
        <div className='modalcont'>
          <div className='modalinner my-5 py-4'>
            <h2>Verify User</h2>

            <div className='loginForm'>
              <div className='mb-3 mt-3'>
                <div className='text-center mb-4'>
                  We noticed that you are signing in from a device we don’t recognize. For security purpose please enter the verification code sent to your email/mobile device.
                  </div>
                <input type='otp' className='form-control form-control-lg' id='email'
                  placeholder='Enter otp'
                  value={this.state.otp}
                  onChange={(e) => this.onChangeAddName('otp', e.target.value)}
                />
                <span style={{ color: 'red' }}>{this.state.formErrotp}</span>

              </div>
              <div className='d-grid'>
                <button className='btn btn-primary btn-lg' type='button'
                  onClick={(e) => this.handleSubmit()}
                >Submit</button>
              </div>

              <div className='d-flex justify-content-between mt-3 mb-4'>
                <button className='btn btn-primary w-100 btn-lg' type='button'
                  onClick={(e) => this.otpSubmit()}
                >Resend Otp</button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}
const mapPropsToState = (state) => {
  return {
    loading: state.auth.loading,
    signup_error: state.auth.signup_error,
    signup_success: state.auth.signup_success,
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    verifyOtp: (data) => dispatch(actions.verifyOtp(data)),
    resendOtp: (data) => dispatch(actions.resendOtp(data))
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(VerifyOtp));
