import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import menu from "../assets/images/menu_ic.png";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import HomeIcon from "@material-ui/icons/Home";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import SettingsIcon from "@material-ui/icons/Settings";
import GradeIcon from "@material-ui/icons/Grade";
import EventNoteIcon from "@material-ui/icons/EventNote";
import GroupIcon from "@material-ui/icons/Group";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import Login from "./Login";
import Profile from './Profile'
import Signup from "./Signup";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "../store/actions/index";
import "../../src/style/index.css"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contents1: '',
    }
  }
  async componentWillMount() {
    await this.props.fetchpagesList({
      page: "about_us"
    });
    this.props.getConactUsAction();
  }

  handleSubmit = async () => {
    await this.props.fetchpagesList({
      page: "about_us"
    });
    await this.setState({
      contents1: this.props.about.about_us
    })
  }
  render() {
    const userToken = localStorage.getItem('token')
    let logoutData = ['token', 'userInfo', 'userId']
    return (
      <>
        <header className='sticky-top'>
          <div className="container">
            {/* <div className="navbar-dark  position-fixed h-100 w-sidebar navbar-collapse collapse lftside">
            <ul className="nav flex-column flex-nowrap  ulstyle">

              <li className="nav-item">
                <Link to="/">
                  <a className="nav-link active" href="#">
                    <HomeIcon style={{ marginRight: "10px" }} />
                    Dashboard
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/trackprofessional">
                  <a className="nav-link">
                    <AddLocationIcon style={{ marginRight: "10px" }} />
                    Track Professional
                  </a>
                </Link>
              </li>
              <li className="nav-item ">
                <Link to="/myservice">
                  <a className="nav-link" href="#">
                    <SettingsIcon style={{ marginRight: "10px" }} />
                    My Service
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/myinvoice">
                  <a className="nav-link" href="#">
                    <LibraryBooksIcon style={{ marginRight: "10px" }} />
                    My Invoice
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/carpoollist">
                  <a className="nav-link" href="#">
                    <GradeIcon style={{ marginRight: "10px" }} />
                    My Rating & Comment
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/rescheduleservice">
                  <a className="nav-link" href="#">
                    <EventNoteIcon style={{ marginRight: "10px" }} />
                    Rescheduled Service
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/">
                  <a className="nav-link" href="#">
                    <GroupIcon style={{ marginRight: "10px" }} />
                    Refer Friends
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/">
                  <a className="nav-link" href="#">
                    <NotificationsIcon style={{ marginRight: "10px" }} />
                    Notification
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/support">
                  <a className="nav-link" href="#">
                    <SupervisedUserCircleIcon style={{ marginRight: "10px" }} />
                    Supports
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/settings">
                  <a className="nav-link" href="#">
                    <SettingsApplicationsIcon style={{ marginRight: "10px" }} />
                    Setting
                  </a>
                </Link>
              </li>
            </ul>
          </div> */}

            <nav className="navbar navbar-expand-lg d-flex justify-content-between px-0">
              <a className="navbar-brand" href="#">
                <img
                  src={require("../assets/images/logo.png")}
                  style={{ width: "120px" }}
                />
              </a>
              <button className="navbar-toggler border-light" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" className="bi bi-list" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                </svg>
              </button>
              {/* <button type="button" data-toggle="collapse"
                href="#" data-target=".collapse" role="button" className="btn button-collapse mt-3" style={{ marginLeft: "60px" }}>
                <img src={menu} style={{ height: "40px" }} />
              </button> */}
              <div className="collapse navbar-collapse" id="navbarContent">
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item header-nav">
                    <a className="nav-link m-0 mx-xl-2" href="/" >
                      Home
                    </a>
                  </li>
                  <li className="nav-item header-nav dropdown">
                    <a className="nav-link m-0 mx-xl-2 nav-link dropdown-toggle" data-toggle="dropdown" style={{cursor:"pointer"}}>
                      Service
                    </a>
                    <ul className="dropdown-menu text-body py-2 shadow" aria-labelledby="navbarScrollingDropdown">
                      <li><Link className="dropdown-item border-bottom py-2 m-0" to="/e-commerece-services">Business Services & E-Commerce Website</Link></li>
                      <li><Link className="dropdown-item border-bottom py-2 m-0" to='/professional-business-website'>Professional Business Website</Link></li>
                      <li><Link className="dropdown-item border-bottom py-2 m-0" to='/digital-ads'>Digital Ads</Link></li>
                      <li><Link className="dropdown-item border-bottom py-2 m-0" to='/mobile-app'>Mobile App Design & Development</Link></li>
                      <li><Link className="dropdown-item border-bottom py-2 m-0" to='/social-media'>Social Media Marketing</Link></li>
                      <li><Link className="dropdown-item border-bottom py-2 m-0" to='/added-value-marketing'>Added Value Marketing</Link></li>
                      <li><Link className="dropdown-item py-2 m-0" to='/small-branding-and-consulting'>Small Business Branding & Consulting</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item header-nav">
                    <a className="nav-link m-0 mx-xl-2" href="/blog-list" >
                      Blog
                    </a>
                  </li>
                  <li className="nav-item header-nav">
                    <a
                      className="nav-link m-0 mx-xl-2"
                      href="/advertise-subscription-plan"
                    >
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item header-nav">
                    <a

                      className="nav-link m-0 mx-xl-2"
                      href="#"
                      data-toggle="modal"
                      data-target="#exampleModal21"
                      onClick={(e) => this.handleSubmit()}
                      aria-current="page"
                    >
                      About Us
                    </a>
                  </li>
                  <li className="nav-item header-nav">
                    <a
                      className="nav-link m-0 mx-xl-2"
                      href="/support"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li className="nav-item header-nav">
                    <a className="nav-link m-0 mx-xl-2" href="/category">
                      {/* <Link to="/carpool"> */}
                      Industries
                    </a>
                  </li>
                  {/* {
                    !userToken ?
                      <li className="nav-item header-nav">
                      
                        <div
                          className="modal fade"
                          id="exampleModal"
                          tabIndex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-body">
                                <Signup />
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li> : <li></li>} */}
                  {
                    !userToken ?

                      <li className="nav-item header-nav">
                        <a
                          className="nav-link m-0 mx-xl-2"
                          href="#"
                          data-toggle="modal"
                          data-target="#exampleModal1"
                        >
                          Login
                        </a>
                      </li>
                      : <li className="nav-item header-nav">
                        <a
                          className="nav-link m-0 mx-xl-2"
                          // href="#"
                          data-toggle="modal"
                          data-target="#exampleModal2"
                        > Profile</a>
                      </li>
                  }
                </ul>
              </div>
            </nav>
          </div>
        </header>

        {/* for login modal */}
        <div
          className="modal fade"
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <Login />
              </div>
              <div className="modal-footer">
                Don't have Account?
                <button type="button" className="btn btn-primary ms-3 my-0 py-1" data-dismiss="modal"
                  onClick={() => { window.location.href = '/register' }} >
                  SignUp
                </button>
                <button type="button" className="btn btn-secondary my-0 py-1" data-dismiss="modal">Close</button>

              </div>
            </div>
          </div>
        </div>

        {/* for about us modal */}
        <div
          className="modal fade"
          id="exampleModal21"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header border-0">
                        <div className="row align-items-center">
                          <div className="col">
                            <div className="content"
                              dangerouslySetInnerHTML=
                              {{
                                __html: this.state.contents1
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* for profile modal */}
        <div
          className="modal fade"
          id="exampleModal2"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <Profile />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    logoutData.forEach(item => localStorage.removeItem(item));
                    window.location = '/';
                  }}
                >
                  Logout
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapPropsToState = (state) => {
  return {
    success: state.pages.success,
    error: state.pages.error,
    pages: state.pages.pages,
    term: state.pages.term,
    about: state.pages.about,
    contact: state.pages.contact
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchpagesList: (data) => dispatch(actions.fetchpagesListAction(data)),
    addPageAction: (data1, data2) => dispatch(actions.addPage(data1, data2)),
    getConactUsAction: () => dispatch(actions.getConactUs()),

  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(Header));
