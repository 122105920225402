import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { Route } from 'react-router-dom';
import * as actions from '../../store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
class RideshareByName extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      category_id: '',
      category_name: ''
    }
  }

  componentWillMount() {
    // this.props.fetchSubCategory(this.state.category_id)
    var name = this.props.match.params.short_name
    // console.log(name,'=====')

    this.props.fetchCategoryByName(name)

    // if(this.props.categorylistbyname.length > 0) {
    //   this.setState({ 
    //     category_id:this.props.categorylistbyname[0]._id,
    //   });

    // }
  }

  async componentDidMount() {
    console.log(this.props.categorylistbyname, '====what is here')
    if (this.props != undefined && this.props.location != undefined && this.props.location.aboutProps != undefined && this.props.location.aboutProps.name != undefined) {
      let category_id = this.props.location.aboutProps.name;
      // let category_name = this.props.location.aboutProps.category;

      this.props.fetchSubCategory(this.state.category_id)
      // this.setState({ 
      //     category_id:category_id,
      //     // category_name:category_name
      // });
    }
  }

  render() {

    let category_name = ""
    let categoryList = [];
    let categoryList1 = [];

    // if(this.props.categorylistbyname.length > 0)
    // {
    //   category_name = this.props.match.params.short_name

    // }
    // else{
    //   category_name = <Redirect from= "./${this.props.match.params.short_name}" to= "/"/>
    // }

    let i = 1
    for (let index = 0; index < this.props.categorylistbyname.length; index++) {
      const element = this.props.categorylistbyname[index];
      categoryList1.push(
        <div className="col-6 col-md-4 col-xl-3">
          <div className="ridebox h-100 px-3">
            {/* <Link to="/carpool"> */}
            <Link to={{
              pathname: "/TrackBusinessUsers",
              aboutProps: {
                name: this.props.categorylistbyname[index]._id,
              }
            }}
            // {"/subcategory/"+datas._id}
            >
              {/* </Link> */}
              <img className="rounded-circle mb-15" width="100" height="100" src={this.props.categorylistbyname[index].sub_category_images} />
              <h3>{this.props.categorylistbyname[index].name}</h3>
            </Link>
          </div>
        </div>
      )
      if (i == 4 || this.props.categorylistbyname.length == index + 1) {
        categoryList.push(
          <div className="rideboxCont row g-0">
            {categoryList1}
          </div>)
        categoryList1 = [];
        i = 1;
      }
    }


    return (
      <>
        <Header />

        <section className="subcatery-page my-5">
          <div className="container">
            <div className="d-flex mt-4">
              <h3 className="uprheadng text-nowrap pe-1">Dashboard / </h3>
              <h3 className="txtBlk"> {category_name}</h3>
            </div>

            <div className="shadowCont mt-4 mb-5">
              {categoryList}
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

const mapPropsToState = (state) => {

  return {
    subcategorylisting: state.subCategory.subcategorylisting,
    categorylisting: state.category.categorylisting,
    categorylistbyname: state.category.categorylistbyname
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSubCategory: (data) => dispatch(actions.fetchsubCategoryListAction(data)),
    fetchCategoryByName: (data) => dispatch(actions.fetchcategoryByNameListAction(data)),
  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(RideshareByName))
