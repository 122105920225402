import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { MDBDataTable } from "mdbreact";
import rating from "../../assets/images/rating_on_ic.png";
import ReactPlayer from 'react-player'
import '../../App.css'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
// export default function TrackBusinessUsers() {
class TrackBusinessUsers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    // this.link=this.link.bind(this);
  }


  async componentDidMount() {
    let data = {};
    let self = this;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (self.props !== undefined && self.props.location !== undefined && self.props.location.aboutProps !== undefined && self.props.location.aboutProps.name !== undefined) {
      var name = self.props.location.aboutProps.name
      navigator.geolocation.getCurrentPosition(function (position) {
        data = {
          subCategoryId: name,//,//
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          // longitude:-88.27857348227539,
          // latitude:40.08201745156272,
          time_zone: timezone
        };
        self.props.fetchbussinessList(data)
      });

    }
    let lat = await localStorage.getItem("latitude")
    let long = await localStorage.getItem("longitude")
    let param = {
      page_type: 'third',
      latitude: parseFloat(lat),
      longitude: parseFloat(long),
      subCategory_id: self.props.location.aboutProps.name
    }
    await this.props.getBannerPictureList3(param);
    await this.props.getSquarePictureList3(param);
    await this.props.getBannerVideoList3(param);
    await this.props.getSquareVideoList3(param);


  }
  render() {

    let businessCategoryTable = <div />;
    let categoryList = [];
    let stars = <div></div>
    {
      this.props.bussinesslisting.length > 0
        ? this.props.bussinesslisting.map(e => {
          if (e.website == "") {

          }
          else {
            if (Math.floor(e.businessRating) == 0) {
              stars = <div> No Stars</div>
            }
            else if (Math.floor(e.businessRating) == 1) {
              stars = <div>
                <img src={rating} />{e.businessRating} Star Rating
              </div>
            }

            else if (Math.floor(e.businessRating) == 2) {
              stars = <div>
                <img src={rating} />
                <img src={rating} />{e.businessRating} Star Rating
              </div>
            }

            else if (Math.floor(e.businessRating) == 3) {
              stars = <div>
                <img src={rating} />
                <img src={rating} />
                <img src={rating} />{e.businessRating} Star Rating
              </div>
            }

            else if (Math.floor(e.businessRating) == 4) {
              stars = <div>
                <img src={rating} />
                <img src={rating} />
                <img src={rating} />
                <img src={rating} />{e.businessRating} Star Rating
              </div>
            }

            else if (Math.floor(e.businessRating) == 5) {
              stars = <div>
                <img src={rating} />
                <img src={rating} />
                <img src={rating} />
                <img src={rating} />
                <img src={rating} />{e.businessRating} Star Rating
              </div>
            }
            var start_time = "";
            var end_time = "";
            if (e.bussiness_info == undefined && e.bussiness_info.happy_hours == undefined && e.bussiness_info.happy_hours.start_time == undefined) {

            }
            else if (e.bussiness_info.happy_hours.start_time == "" && e.bussiness_info.happy_hours.end_time == "") {

            }
            else {
              var start_hour = parseInt(e.bussiness_info.happy_hours.start_time.split(":")[0]) > 12 ? parseInt(e.bussiness_info.happy_hours.start_time.split(":")[0]) - 12 : parseInt(e.bussiness_info.happy_hours.start_time.split(":")[0])
              var start_min = e.bussiness_info.happy_hours.start_time.split(":")[1]
              var start_am_pm = parseInt(e.bussiness_info.happy_hours.start_time.split(":")[0]) > 12 ? "PM" : "AM"
              start_hour = start_hour > 9 ? start_hour : "0" + start_hour
              start_time = start_hour + ":" + start_min + " " + start_am_pm;


              var end_hour = parseInt(e.bussiness_info.happy_hours.end_time.split(":")[0]) > 12 ? parseInt(e.bussiness_info.happy_hours.end_time.split(":")[0]) - 12 : parseInt(e.bussiness_info.happy_hours.end_time.split(":")[0])
              end_hour = end_hour > 9 ? end_hour : "0" + end_hour

              var end_min = e.bussiness_info.happy_hours.end_time.split(":")[1]
              var end_am_pm = parseInt(e.bussiness_info.happy_hours.end_time.split(":")[0]) > 12 ? "PM" : "AM"
              end_time = end_hour + ":" + end_min + " " + end_am_pm;

            }
            var addwebsite_url = (e.rideShare_delivery == null) ? "" : "/" + e.rideShare_delivery + "/" + e.location_required
            var website = e.website + addwebsite_url + "/" + e.subCategoryId;
            categoryList.push(
              {
                business_logo:
                  <a target="_blank" href={(website.indexOf("https://") == 0) ? website : 'https://' + website}
                  // onclick={()=>this.link(e.website)} 
                  >
                    <img src={e.business_logo} className="rounded-circle mb-15" style={{ height: "329px", width: "329px", objectFit: 'cover' }} />
                  </a>,
                data: <a target="_blank" href={(website.indexOf("https://") == 0) ? website : 'https://' + website}>
                  <div className="carpoolContent">
                    <div>
                      <p>Happy Hours</p>
                      <span>{start_time} - {end_time}</span>
                      <div className="ratingBox">{e.bussiness_info.happy_hours.discount}%</div>
                    </div>

                    <h3>{e.bussiness_name}</h3>
                    {/* <p>{e.business_owner_name}</p> */}
                    <p>{e.description}</p>
                    <p>{e.local_city}</p>
                    {/* <p>{e.bussiness_email}</p> */}
                    <p>Contact Number {e.country_code} {e.mobile_no}</p>
                    <p>

                      <div>{stars}</div>
                    </p>
                    <p className="d-flex align-items-center justify-content-between" style={{gap: '15px'}}>
                      <span>
                        {
                          (e.business_weekdays != undefined ?
                            ((new Date()).getDay() == 1) ? (e.business_weekdays.is_monday_Open ? "Opened" : "Closed") :
                              ((new Date()).getDay() == 2) ? (e.business_weekdays.is_tuesday_Open ? "Opened" : "Closed") :
                                ((new Date()).getDay() == 3) ? (e.business_weekdays.is_wednesday_Open ? "Opened" : "Closed") :
                                  ((new Date()).getDay() == 4) ? (e.business_weekdays.is_thursday_Open ? "Opened" : "Closed") :
                                    ((new Date()).getDay() == 5) ? (e.business_weekdays.is_friday_Open ? "Opened" : "Closed") :
                                      ((new Date()).getDay() == 6) ? (e.business_weekdays.is_saturday_Open ? "Opened" : "Closed") :
                                        (e.business_weekdays.is_sunday_Open ? "Opened" : "Closed")
                            : "Closed")

                        }
                      </span>
                      <div className="button_box p-0"><button type="button" class="btn btn-danger">Order or Book Now</button></div>
                    </p>

                  </div>
                </a>
              }
            )
          }

        })

        : categoryList.push(
          ['No record']
        )
    }
    const data = {
      columns: [
        {
          label: "Business Logo",
          field: "business_logo",
          sort: "asc",
        },
        {
          label: "Data",
          field: "data",
          sort: "asc",
        },
      ],
      rows: categoryList,
    };

    businessCategoryTable = (
      <MDBDataTable striped bordered hover entries={10} data={data} />
    );
    return (
      <>
        <Header />

        <section className="TrackBusinessUsers my-5">
          <div className="container">
            {/* Banner Video*/}
            {
              this.props.advertisementBannerVideoList3.length > 0 ?
                <div className="video-wrapper row g-md-0">
                  <div className="col-md-6">
                    <div className="slide-container">
                      <Slide arrows={false} duration={5000} >

                        {this.props.advertisementBannerVideoList3.map((slideImage, index) => (

                          <div className="each-slide" key={index}>
                            <a
                              target="_blank" href={'https://' + slideImage.website}
                            >
                              <div >
                                <ReactPlayer url={slideImage.video_url} playing={true} volume={0} controls={true}
                                  loop={true} width={'100%'} ></ReactPlayer>
                              </div>
                            </a>
                          </div>
                        ))}
                      </Slide>

                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="slide-container">
                      <Slide arrows={false} duration={5000} >

                        {this.props.advertisementBannerVideoList3.map((slideImage, index) => (
                          <div className="each-slide" key={index}>
                            <a
                              target="_blank" href={'https://' + slideImage.website}
                            // onclick={()=>this.link(e.website)} 
                            >
                              <div >
                                <ReactPlayer url={slideImage.video_url} playing={true} volume={0} controls={true}
                                  loop={true} width={'100%'}></ReactPlayer>
                              </div>
                            </a>
                          </div>
                        ))}
                      </Slide>

                    </div>
                  </div>
                </div> :
                ''
            }

            {/* for Picture banner */}
            {this.props.advetisementBannerPictureList3.length > 0 ?
              <div className="video-wrapper row g-md-0">
                <div className="col-md-6">
                  <div className="slide-container">
                    <Slide arrows={false} duration={3000}>
                      {this.props.advetisementBannerPictureList3.map((slideImage, index) => (
                        <div className="each-slide" key={index}>
                          <a
                            target="_blank" href={'https://' + slideImage.website}
                          >
                            <div >
                              <img src={slideImage.picture_url} style={{
                                height: '60vh',
                                width: '100%',
                              }} />
                            </div>
                          </a>
                        </div>
                      ))}
                    </Slide>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="slide-container">
                    <Slide arrows={false} duration={3000}>
                      {this.props.advetisementBannerPictureList3.map((slideImage, index) => (
                        <div className="each-slide" key={index}>
                          <a target="_blank" href={'https://' + slideImage.website}>
                            <div >
                              <img src={slideImage.picture_url} style={{
                                height: '60vh',
                                width: '100%',
                              }} />

                            </div>
                          </a>
                        </div>
                      ))}
                    </Slide>
                  </div>
                </div>
              </div> :
              ''
            }
            {/* Unlimited Square Video*/}
            {this.props.advertisementSquareVideoList3.length > 0 ?
              <div className="video-wrapper row g-md-0">

                {this.props.advertisementSquareVideoList3.map(e => (
                  <div className="col-md-4">
                    <a
                      target="_blank" href={'https://' + e.website}
                    >
                      <div >
                        <ReactPlayer url={e.video_url} playing={true} volume={0} controls={true}
                          loop={true} width={'100%'} ></ReactPlayer>
                      </div>
                    </a>
                  </div>

                ))}

              </div>
              : ''
            }

            {/* Unlimited Square Picture*/}
            {this.props.advetisementSquarePictureList3.length > 0 ?
              <div className="video-wrapper row g-md-0">
                {this.props.advetisementSquarePictureList3.map(e => (
                  <div className="col-md-4">
                    <a target="_blank" href={'https://' + e.website}>
                      <div >
                        <img src={e.picture_url} style={{
                          height: '50vh',
                          width: '100%',
                        }} />
                      </div>
                    </a>
                  </div>
                ))}
              </div>
              : ''
            }

            {/*business listing */}
            <div className="d-flex mb-3">
              {/* <h3 className="uprheadng">Dashboard / Ride Share / Location / </h3>
              <h3 className="txtBlk"> Carpool</h3> */}
              <h3 className="txtBlk">Business Listing</h3>
            </div>
            <div className='table-responsive'>
              {businessCategoryTable}
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

const mapPropsToState = (state) => {
  return {
    bussinesslisting: state.bussiness.bussinesslisting,
    advertisementBannerVideoList3: state.advertisementPicture.advertisementBannerVideoList3,
    advertisementSquareVideoList3: state.advertisementPicture.advertisementSquareVideoList3,
    advetisementBannerPictureList3: state.advertisementPicture.advetisementBannerPictureList3,
    advetisementSquarePictureList3: state.advertisementPicture.advetisementSquarePictureList3
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchbussinessList: (data) => dispatch(actions.fetchbussinessListAction(data)),
    getBannerPictureList3: (data) => dispatch(actions.getBannerPictureList3(data)),
    getSquarePictureList3: (data) => dispatch(actions.getSquarePictureList3(data)),
    getBannerVideoList3: (data) => dispatch(actions.getBannerVideoList3(data)),
    getSquareVideoList3: (data) => dispatch(actions.getSquareVideoList3(data))
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(TrackBusinessUsers));