import React, { useState, useEffect } from 'react'
import Header from "../Header";
import Footer from "../Footer";
import Banner from "../../assets/images/new/Banner.jpg";
import * as actions from '../../store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { Link, Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import VideoThumbnail from 'react-video-thumbnail';
import Login from '../Login';

// Our Packages Slick Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


// Download App Image
import AppImg from "../../assets/images/new/app-banner.jpg";
import { ServiceSection } from '../Component/ServiceSection';


const NewLandingPage = (props) => {
  const [imageBase64, setImageBase64] = useState('');
  const userToken = localStorage.getItem('token')
  // Slick Props
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    props.fetchCategory()
    props.getAllSubscriptionPlanList()
    props.getBlogList()
  }, [])

  useEffect(() => {
    // Start: Same Height JS For Package info
    var i = 0;
    var items = document.querySelectorAll(".package-info");
    var itemsHeight = [];
    for (i = 0; i < items.length; i++) { itemsHeight.push(items[i].offsetHeight); }
    var maxHeight = Math.max(...itemsHeight);
    for (i = 0; i < items.length; i++) { items[i].style.height = maxHeight + "px"; }
    // End: Same Height JS For Package info

    // Start: Same Height JS For Package Label
    var i = 0;
    var label = document.querySelectorAll(".package-label");
    var labelHeight = [];
    for (i = 0; i < label.length; i++) { labelHeight.push(label[i].offsetHeight); }
    var maxHeight = Math.max(...labelHeight);
    for (i = 0; i < label.length; i++) { label[i].style.height = maxHeight + "px"; }
    // End: Same Height JS For Package Label
  });

  const OnSubscriptionPlan = (planDetails) => {
    if (planDetails.subscriptionType == 'quote') {
      props.history.push({
        pathname: '/support',
      })
    } else {
      const userToken = localStorage.getItem('token')
      if (!userToken) {
        toast.warning("Please login!")
      } else {
        props.history.push({
          pathname: '/subscription-plan-details/' + `${planDetails.planId}`,
          // planDetails: planDetails // your data array of objects
        })
      }
    }
  }

  const handleFreeTrail = () => {
    const userToken = localStorage.getItem('token')
    let { is_monthlyfreetrial_advertise_subscription } = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : false
    if (!userToken) {
      toast.warning("Please login!")
    } else {
      if (is_monthlyfreetrial_advertise_subscription) {
        toast.warning("You avail this plan")
      } else {
        props.history.push({
          pathname: '/advertise-subscription-plan',
        })
      }

    }
  }

  const LearnMore = (item) => {
    // console.log(item,'kya shi rahega',item.advertisement_type.indexOf("E-Commerce"))
    if (item.advertisement_type.indexOf("E-Commerce") >= 0 && item.subscription_type === 'onetime') {//Do stuff
      props.history.push({
        pathname: '/e-commerece-services'
      })
    } else if (item.advertisement_type.indexOf("Professional Business") >= 0 && item.subscription_type === 'onetime') {
      props.history.push({
        pathname: '/professional-business-website'
      })
      
    } else if (item.advertisement_type.indexOf('(Ad)') >= 0 && item.subscription_type === 'monthly') {
      props.history.push({
        pathname: '/social-media'
      })
    }
    else if (item.advertisement_type.indexOf("Ad") >= 0 && item.advertisement_type.indexOf("Plan") >= 0 && item.subscription_type === 'monthly') {
      props.history.push({
        pathname: '/digital-ads'
      })
    } else if (item.advertisement_type.indexOf('(Marketing)') >= 0) {
      props.history.push({
        pathname: '/added-value-marketing'
      })
    } else if (item.advertisement_type.indexOf("Mobile App's") >= 0 && item.subscription_type === 'quote') {
      props.history.push({
        pathname: '/mobile-app'
      })
    } else if (item.advertisement_type.indexOf('Small Business') >= 0 && item.subscription_type === 'quote') {
      props.history.push({
        pathname: '/small-branding-and-consulting'
      })
    }
  }

  return (
    <>
      <Header />

      <div className='landingpage'>
        {/* Top Banner Section */}
        <div className='banner-section' style={{ backgroundImage: `url(${Banner})` }}>
          <div className='container'>
            <div className='banner-info-cover'>
              <div className='banner_subtitle'>Get the Most out of</div>
              <div className='banner_title mb-3 mb-md-4'>Your Business Online</div>
              <div className='banner_description mb-4 mb-lg-5'>With Professional Websites and Digital Marketing. Take Advantages the FREE TRIAL Now! </div>
              <div className='btn-wrapper d-flex flex-column flex-md-row flex-wrap gap-3'>
                <Link to={{ pathname: "/send-us-requirement" }} className='btn btn-custom btn-custom-outline'>Send Us Your Requirement</Link>
                {
                  !userToken ?
                    <button className='btn btn-custom btn-custom-filled position-relative' data-toggle="modal" data-target="#exampleModal1">Get Free trial</button>
                    :
                    <button className='btn btn-custom btn-custom-filled position-relative' onClick={() => handleFreeTrail()}>Get Free trial</button>
                }
              </div>
            </div>
          </div>
        </div>

        {/* Popular Category Section */}
        <div className='category-section section-padding'>
          <div className='container'>
            <div className="text-center mb-5">
              <div className="section-heading mb-md-1">Popular Industries</div>
            </div>

            <div className='row'>
              {props.categorylisting.slice(0, 8).map((item, index) => (
                <div className='col-md-6 col-lg-4 col-xl-3 mb-3 pb-1' key={index}>
                  <div className='category-info-wrapper d-flex justify-content-between flex-column h-100'>
                    <div className='coverr'>
                      <div className='category-header d-flex align-items-center gap-2 py-2'>
                        <img src={item.category_images} alt='Icon' />
                        <div className='category-title d-flex flex-wrap align-items-center'>{item.name}</div>
                      </div>

                      <div className='category-body py-1'>
                        {
                          item.subcategoryInfo ? item.subcategoryInfo.slice(0, 2).map((val, i) => (
                            <div className='category-item my-4' key={i} >{val.name}</div>
                          )) : ''
                        }
                      </div>
                    </div>
                    <div className='category-footer border-2 border-top border-white text-center'>
                      <Link to={{ pathname: "/subcategory", aboutProps: { name: item._id, category: item.name } }} className='btn btn-link py-0'>View All</Link>
                    </div>
                  </div>
                </div>
              ))}

              <div className='btn-wrapper text-center mt-4 mt-lg-5'>
                <Link to={{ pathname: "/category" }} className='btn btn-custom btn-custom-filled'>See All Industries</Link>
              </div>
            </div>
          </div>
        </div>

        {/* Services Section */}
        <ServiceSection />

        {/* Our Packages Section */}
        <div className='packages-section section-padding'>
          <div className='container'>
            <div className="text-center mx-auto mb-5" style={{ maxWidth: '750px' }}>
              <div className="section-heading mb-2">Our Packages</div>
              <div className="section-subheading">Unlock the potential of your online presence with comprehensive website design, development, and digital marketing packages.</div>
            </div>

            <Slider {...settings} className="custom-slick mx-3 mx-md-0">
              {props.subscriptionPlanlist ? props.subscriptionPlanlist.map((item, key) => (
                <div className='package-item text-center' key={item._id}>
                  <div className='package-header'><label>{item.advertisement_type}</label></div>
                  <div className='package-info d-flex flex-column'>
                    <div className='package-label'>{item.subscription_type == 'monthly' || item.subscription_type == 'quote' ? item.description_of_original_price : `${item.description_of_original_price ? `${item.description_of_original_price} $${item.original_price}` : ''}`}</div>
                    {item.subscription_type !== 'quote' ?
                      <div className='package-price section-heading mb-4 pb-2'>${item.price}<span className='package-label plan_type'>{item.subscription_type == 'monthly' ? '/Month' : `${item.subscription_type == 'yearly' ? '/Year' : ''}`}</span></div>
                      : ''
                    }

                    <div className='learn-more mt-auto mb-2 pb-1'>
                      <p className='btn-link text-decoration-none' onClick={() => LearnMore(item)}>Learn More</p>
                    </div>
                    <div className='btn-wrapper'>
                      {
                        (!userToken && item.subscription_type !== 'quote') ?
                        <button className='btn btn-custom btn-custom-filled shadow-none' data-toggle="modal" data-target="#exampleModal1">BUY NOW</button>
                          :
                          <button className='btn btn-custom btn-custom-filled shadow-none' onClick={() => OnSubscriptionPlan({ planId: item._id, subscriptionType: item.subscription_type })}>{item.subscription_type == 'quote' ? 'Call Now' : 'BUY NOW'}</button>
                      }
                    </div>
                  </div>
                </div>
              )) : ""}
            </Slider>
            <div className='btn-wrapper text-center mt-5 pt-1'>
              <Link to={{ pathname: "/advertise-subscription-plan" }} className='btn btn-custom btn-custom-outline' >See All Packages</Link>
            </div>
          </div>
        </div>

        {/* Video Section */}
        <div className='video-section section-padding'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 pe-xl-3'>
                <div className='video-wrapper h-100'>
                  <video controls poster={(require('../../assets/images/new/video-poster.png'))} preload="metadata">
                    <source src="https://servicehub-blog-media.s3.us-west-2.amazonaws.com/1675001735922-syls%20short%20video-hd.mp4" type="video/mp4" />
                    <source src="https://servicehub-blog-media.s3.us-west-2.amazonaws.com/1675001735922-syls%20short%20video-hd.mp4" type="video/ogg" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div className='col-lg-6 ps-xl-3 mt-4 mt-lg-0'>
                <div className='video-content-wrapper d-flex flex-column justify-content-center h-100'>
                  <div className="section-heading mb-2">Stand out with professional Business website</div>
                  <div className='section-subheading mb-3 pb-1'>Get your business the professional website it deserves. Our website design and development services can give you the competitive edge you need in today’s digital world.</div>
                  <div className='list-title mb-2'>Search on the go</div>
                  <div className='mb-4'>
                    <ul className='list-wrapper m-0'>
                      <li>Quick and easy access to information</li>
                      <li>More intuitive navigation for finding relevant information</li>
                      <li>Easier for customers to connect with businesses</li>
                    </ul>
                  </div>
                  <div className='btn-wrapper d-flex flex-column flex-md-row flex-wrap gap-3'>
                    {
                      !userToken ?
                      <button className='btn btn-custom btn-custom-filled' data-toggle="modal" data-target="#exampleModal1">Get Free trial</button>
                        :
                        <button className='btn btn-custom btn-custom-filled' onClick={() => handleFreeTrail()}>Get Free trial</button>
                    }
                    <Link to={{ pathname: "/register" }} className='btn btn-custom btn-custom-outline'>Register now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Our Blog Section */}
        <div className='blogs-section section-padding'>
          <div className='container'>
            <div className="text-center mx-auto pb-2" style={{ maxWidth: '750px' }}>
              <div className="section-heading mb-2">Our Blog</div>
              <div className="section-subheading">Dive deeper into topics that matter with ServiceHubConncet blog, the ultimate online resource for engaging and informative content.</div>
            </div>

            <div className='row'>
              {
                props.blogList ? props.blogList.slice(0, 3).map((blog, index) => (
                  <div className='col-lg-4 col-md-6 mt-4' key={blog._id}>
                    <Link to={"/blog-details/" + blog._id} className='blog-item h-100'>
                      <div className='img-wrapper'>
                        {blog.blog_video ?
                          <iframe className="blog-image" src={blog.blog_video} allow="accelerometer; encrypted-media; picture-in-picture" allowFullScreen></iframe>
                          // <div>
                          //   <VideoThumbnail
                          //     className="blog-image"
                          //     videoUrl={blog.blog_video}
                          //     thumbnailHandler={(thumbnail) => setImageBase64(thumbnail)}
                          //     // renderThumbnail={false}
                          //     snapshotAtTime={5}
                          //     cors={true}
                          //     width={400}
                          //     height={270}
                          //   />
                          //   {/* {imageBase64} */}
                          // </div>
                          :
                          <img className="blog-image" src={blog.blog_picture} alt="" />
                        }
                      </div>
                      <div className='blog-info-wrapper p-3'>
                        <div className='blog-label'>{blog.blog_title}</div>
                        {/* <div className='blog-title'>{blog.blog_description}</div> */}
                        <div className='blog-date'>{new Date(blog.createdAt).toDateString()}</div>
                      </div>
                      {/* </a> */}
                    </Link>
                  </div>
                ))
                  : ''
              }
            </div>

            <div className='btn-wrapper text-center mt-5 pt-1'>
              <Link to={"/blog-list/"} className='btn btn-custom btn-custom-filled'>See all Blogs</Link>
            </div>
          </div>
        </div>

        {/* Newsletter Section */}
        <div className='newsletter-section section-padding'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className="col-lg-8 text-center text-lg-start">
                <div className="section-heading text-white">Servicehub Connect is the best way to Skyrocket your business</div>
              </div>

              <div className="col-lg-4 text-center text-lg-end mt-4 mt-lg-0">
                <div className='btn-wrapper d-inline-flex justify-content-end flex-column gap-3'>
                  <Link to={{ pathname: "/send-us-requirement" }} className='btn btn-custom btn-custom-outline border-white text-white'>Send Us Your Requirement</Link>
                  {
                    !userToken ?
                    <button className='btn btn-custom btn-custom-filled bg-white border-white' data-toggle="modal" data-target="#exampleModal1">Get Free trial</button>
                      :
                      <button className='btn btn-custom btn-custom-filled bg-white border-white' onClick={() => handleFreeTrail()}>Get Free trial</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Download App Section */}
        <div className='download-app-section section-padding mt-xxl-5 pt-xl-3 pb-xl-0'>
          <div className='container'>
            <div className='row align-items-center align-items-xl-end flex-column-reverse flex-lg-row'>
              <div className="col-lg-6">
                <div className='info-wrapper my-xl-3 py-xl-5'>
                  <div className='section-heading mb-4'>Download our Servicehub Connect App today</div>
                  <div className='app-description mb-4 pb-2'>ServiceHubConnect is a reliable and secure platform for connecting customers and professional service providers.</div>
                  <div className="d-flex gap-4">
                    <a className="text-md-end" href="https://apps.apple.com/in/app/servicehubconnect/id1588399255" target="_blank">
                      <img className="img-fluid" src="/static/media/app_store_ic.0f0fd86b.png" />
                    </a>
                    <a className="text-md-end" href="https://play.google.com/store/apps/details?id=com.Servicehubconnect" target="_blank">
                      <img className="img-fluid" src="/static/media/play_store_ic.f4aa1def.png" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mb-4 mb-lg-0">
                <div className='app-wrapper ps-xxl-3'>
                  <img className='img-fluid' src={AppImg} alt="Download App" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>

      <Footer />
    </>
  )
}

const mapPropsToState = (state) => {
  return {
    categorylisting: state.category.categorylisting,
    subscriptionPlanlist: state.advertiseSubscription.subscriptionPlanlist,
    blogList: state.blog.blogList,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCategory: () => dispatch(actions.fetchcategoryListAction()),
    getAllSubscriptionPlanList: () => dispatch(actions.getAllSubscriptionPlanList()),
    getBlogList: () => dispatch(actions.getBlogList()),
  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(NewLandingPage))
