import './assets/style/index.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from 'react';

import { createBrowserHistory } from 'history';

import Homepage from './container/Homepage/Homepage';
import Rideshare from './container/Rideshare/Rideshare';
import RideshareByName from './container/Rideshare/RideshareByName';
import TrackBusinessUsers from './container/Homepage/TrackBusinessUsers';
import TrackBusinessUsersWithShortKeys from './container/Homepage/TrackBusinessUsersWithShortKeys';

import Carpool from './container/Rideshare/Carpool';
import CarpoolList from './container/Rideshare/CarpoolList';
import CarpoolDetail from './container/Rideshare/CarpoolDetail';
import Settings from './container/Settings/Settings';
import ServiceDetails from './container/Rideshare/ServiceDetails';
import TrackProfessional from './container/TrackProfessional/TrackProfessional';
import RescheduleService from './container/TrackProfessional/RescheduledService';
import MyService from './container/MyService/MyService';
import MyInvoice from './container/MyService/MyInvoice';
import Support from './container/Rideshare/Support';
import BusinessDetail from './container/Rideshare/BusinessDetail';
import SubscriptionPlan from './container/SubscriptionPlan'
import SubscriptionPlanDetails from './container/SubscriptionPlanDetails'
import Signup from './container/Signup'
import ForgetPassword from './container/ForgetPassword'
import ResetPassword from './container/ResetPassword'
import { SnackbarProvider } from "notistack";
import BlogList from './container/Blog/BlogList';
import BlogDetails from './container/Blog/BlogDetails';
import SendUsRequirement from './container/LandingPage/SendUsRequirement';
import NewLandingPage from './container/LandingPage/NewLandingPage';
import Services from './container/MyService/Services';
import ProfessionalWebsite from './container/MyService/ProfessionalWebsite';
import Thankyou from './container/Component/Thankyou';
import DigitalAds from './container/MyService/DigitalAds';
import MobileAppPage from './container/MyService/MobileAppPage';
import SocialMediaPage from './container/MyService/SocialMediaPage';
import AddedValueMarketing from './container/MyService/AddedValueMarketing';
import SmallBrandingConsulting from './container/MyService/SmallBrandingConsulting';
import VerifyUerOtp from './container/VerifyOtp';

const history = createBrowserHistory()

const App = () => {
  return (
    <Router history={history}>
      <SnackbarProvider>
        <div>
          <Switch>
          <Route exact path='/thank-you' component={Thankyou} />
            <Route exact path='/reset-password' component={ResetPassword} />
            <Route exact path='/forgot-password' component={ForgetPassword} />
            <Route exact path='/verify-user' component={VerifyUerOtp} />
            <Route exact path='/register' component={Signup} />
            <Route exact path='/' component={NewLandingPage} />
            <Route exact path='/category' component={Homepage} />
            <Route exact path='/subcategory' component={Rideshare} />
            <Route path='/subcategory' render={(props) => <Rideshare {...props} />} />
            <Route path='/advertise-subscription-plan' component={SubscriptionPlan} />
            <Route path='/subscription-plan-details/:Id' component={SubscriptionPlanDetails} />
            <Route path='/blog-list' component={BlogList} />
            <Route path='/blog-details/:Id' component={BlogDetails} />
            <Route exact path='/TrackBusinessUsers' component={TrackBusinessUsers} />
            <Route path='/TrackBusinessUsers' render={(props) => <TrackBusinessUsers {...props} />} />
            <Route exact path='/carpool' component={Carpool} />
            <Route exact path='/carpoollist' component={CarpoolList} />
            <Route exact path='/carpooldetail' component={CarpoolDetail} />
            <Route exact path='/servicedetail' component={ServiceDetails} />
            <Route exact path='/trackprofessional' component={TrackProfessional} />
            <Route exact path='/rescheduleservice' component={RescheduleService} />
            <Route exact path='/myservice' component={MyService} />
            <Route exact path='/myinvoice' component={MyInvoice} />
            <Route exact path='/support' component={Support} />
            <Route exact path='/businessdetail' component={BusinessDetail} />
            <Route exact path='/send-us-requirement' component={SendUsRequirement} />
            {/* <Route exact path='/landingpage' component={NewLandingPage} /> */}
            <Route exact path='/e-commerece-services' component={Services} />
            <Route exact path='/professional-business-website' component={ProfessionalWebsite} />
            <Route exact path='/digital-ads' component={DigitalAds} />
            <Route exact path='/mobile-app' component={MobileAppPage} />
            <Route exact path='/social-media' component={SocialMediaPage} />
            <Route exact path='/added-value-marketing' component={AddedValueMarketing} />
            <Route exact path='/small-branding-and-consulting' component={SmallBrandingConsulting} />
            <Route exact path='/settings' component={Settings} />
            <Route path='/:short_name' render={(props) => <RideshareByName {...props} />} />

            {/* <Route path='/:id' render={(props) => <TrackBusinessUsersWithShortKeys {...props} />} /> */}
          </Switch>

        </div>
      </SnackbarProvider>
    </Router>
  )
};

export default App
