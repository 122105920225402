import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "../store/actions/index";
import { Button } from 'reactstrap';

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      formErremail: '',
      password: '',
      formErrpassword: '',

    }
  }
  handleSubmit = async () => {
    // console.log((this.state.processingFees.split("+")).length)
    // return
    await this.setState({
      formErremail: (this.state.email === '') ? "Please provide this field" : '',
      formErrpassword: (this.state.password == '') ? "Please provide this field" : '',
    })
    if (!((this.state.email === '') && (this.state.password === ''))) {

      await this.props.onAuth({
        email: this.state.email,
        password: this.state.password,
      })
      // await window.location.reload(true);

    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { enqueueSnackbar, error, success } = this.props;
    success && enqueueSnackbar(success, { variant: "success" });
    error && enqueueSnackbar(error, { variant: "error" });
  }
  onChangeAddName = async (key, value) => {
    var self = this;
    await self.setState({
      [key]: value,
      ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
    })
  }
  render() {
    const uniqueIdforEmail = Math.random().toString(36).substring(2,7);
    const uniqueIdforPassword = Math.random().toString(36).substring(2,7);
    return (
      <>
        <div className='loginForm px-md-4'>
          <h4 className='fw-bold my-3 text-center'>Login</h4>
          <div className='mb-2'>
            <label htmlFor='email' className='form-label small'>Email</label>
            <input type='email' className='form-control py-2' id={uniqueIdforEmail}
              placeholder='Enter your email' value={this.state.email}
              onChange={(e) => this.onChangeAddName('email', e.target.value)}
            />
            <span style={{ color: 'red' }}>{this.state.formErremail}</span>
          </div>
          <div className='mb-3'>
            <label htmlFor='password' className='form-label small'>Password</label>
            <input type='password' className='form-control py-2' id={uniqueIdforPassword} placeholder='Enter your password'
              value={this.state.password}
              onChange={(e) => this.onChangeAddName('password', e.target.value)}
            />
            <span style={{ color: 'red' }}>{this.state.formErrpassword}</span>
          </div>
          <div className='d-grid'>
            <button className='btn btn-primary py-2' type='button'
              onClick={(e) => this.handleSubmit()}
            >Login</button>
          </div>
          <div className='mt-2 mb-3'>
            <a className='cyanLinks m-0' style={{ cursor: 'pointer' }} onClick={() => { window.location.href = '/forgot-password' }} >Forgot Password?</a>
          </div>
          <div className='ret-verif-steps'>
            <h6>Retrieve verification code</h6>
            <p>Step 1. Click on forgot password</p>
            <p>Step 2. Enter your email and click on verify otp and verification code will be sent to your email.</p>
            <p>Step 3. Create a new password.</p>
          </div>
        </div>
      </>
    )
  }
}
const mapPropsToState = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (data) => dispatch(actions.auth(data))

  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(Login));
