import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "../../store/actions/index";
// import Logo from "../images/logo.png";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Elements,
  ElementsConsumer,
  CardElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
let stripePromise = loadStripe("pk_live_HCDXsOAEmsRJMJkM1ZMnTi6H");
// toast.configure()
class PaymentForm extends React.Component {
  handleSubmit = async () => {

    const { elements, stripe } = this.props;
    const cardElement = elements.getElement(CardElement);
    let { error, token } = await stripe.createToken(cardElement)
    if (error) {
      toast.error(error.message, { autoClose: 1000 })
    } else {
      let data = {
        planId:this.props.planId,
        tokenId:token.id,
        freeTrial:this.props.freeTrial
    }
      this.props.buyAdvertiseSubscriptionPlan(data)
    }

  };

  render() {
    return (
      <>
        <div className="mt-4">
          <div className='mb-1' style={{ textAlign: 'left', fontWeight: 600 }}>Pay&Save Your Card Details</div>
          <div className="border py-3 px-2">
            <CardElement options={{ hidePostalCode: true }} />
          </div>
        </div>
        <button className='btn btn-success btn-lg w-100 mt-4' onClick={this.handleSubmit}>Pay</button>
      </>
    );
  }
}
class PayAndSaveCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      card_number: '',
      expir: ''
    }
  } // live key
  //  = loadStripe("pk_live_HCDXsOAEmsRJMJkM1ZMnTi6H");


  render() {
    return (
      <div className='modalcont'>
        <div className='modalinner'>
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {(ctx) => <PaymentForm {...ctx} planId={this.props.planId} freeTrial= {this.props.freeTrial} buyAdvertiseSubscriptionPlan={this.props.buyAdvertiseSubscriptionPlan} />}
            </ElementsConsumer>
          </Elements>
        </div>
      </div>
    )
  }
}


const mapPropsToState = (state) => {
  return {
    
  };
};

const mapDispatchToProps = dispatch => {
  return {
    buyAdvertiseSubscriptionPlan:(data) => dispatch(actions.buyAdvertiseSubscriptionPlan(data))
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(PayAndSaveCard));
