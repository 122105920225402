import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  blogList: [],
  blogDetails: {},
  blogBannerPictureList: [],
  blogSquarePictureList: [],
  blogBannerVideoList: [],
  blogSquareVideoList: [],
  blogDetailsBannerPictureList: [],
  blogDetailsSquarePictureList: [],
  blogDetailsBannerVideoList: [],
  blogDetailsSquareVideoList: [],
  pricingBannerPictureList: [],
  pricingSquarePictureList: [],
  pricingBannerVideoList: [],
  pricingSquareVideoList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_BLOG:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.GET_BLOG_FAIL:
      return {
        ...state,
        loading: null,
        error: action.message,
        success: null,
      }
    case actionTypes.GET_BLOG_SUCCESS:
      return {
        ...state,
        loading: null,
        blogList: action.blogList,
        error: null,
        success: action.message,

      }
    case actionTypes.GET_BLOG_DETAILS_SUCCESS:
      return {
        ...state,
        loading: null,
        blogDetails: action.blogDetails,
        error: null,
        success: action.message,
      }

    case actionTypes.GET_BLOGLIST_BANNER_PICTURE_LIST:
      return {
        ...state,
        loading: null,
        blogBannerPictureList: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.GET_BLOGLIST_SQUARE_PICTURE_LIST:
      return {
        ...state,
        loading: null,
        blogSquarePictureList: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.GET_BLOGLIST_BANNER_VIDEO_LIST:
      return {
        ...state,
        loading: null,
        blogBannerVideoList: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.GET_BLOGLIST_SQUARE_VIDEO_LIST:
      return {
        ...state,
        loading: null,
        blogSquareVideoList: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.GET_BLOGDETAILS_BANNER_VIDEO_LIST:
      return {
        ...state,
        loading: null,
        blogDetailsBannerVideoList: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.GET_BLOGDETAILS_BANNER_PICTURE_LIST:
      return {
        ...state,
        loading: null,
        blogDetailsBannerPictureList: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.GET_BLOGDETAILS_SQUARE_PICTURE_LIST:
      return {
        ...state,
        loading: null,
        blogDetailsSquarePictureList: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.GET_BLOGDETAILS_SQUARE_VIDEO_LIST:
      return {
        ...state,
        loading: null,
        blogDetailsSquareVideoList: action.listing,
        error: null,
        success: action.message,
      }
    case actionTypes.GET_PRICINGLIST_BANNER_VIDEO_LIST:
      return {
        ...state,
        loading: null,
        pricingBannerVideoList: action.listing,
        error: null,
        success: action.message,
      }
    case actionTypes.GET_PRICINGLIST_BANNER_PICTURE_LIST:
      return {
        ...state,
        loading: null,
        pricingBannerPictureList: action.listing,
        error: null,
        success: action.message,
      }
      case actionTypes.GET_PRICINGLIST_SQUARE_VIDEO_LIST:
      return {
        ...state,
        loading: null,
        pricingSquareVideoList: action.listing,
        error: null,
        success: action.message,
      }
      case actionTypes.GET_PRICINGLIST_SQUARE_PICTURE_LIST:
        return {
          ...state,
          loading: null,
          pricingSquarePictureList: action.listing,
          error: null,
          success: action.message,
        }
    default:
      return state;
  }
};

export default reducer;
