import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startAdvertisementAction = () => {
  return {
    type: actionTypes.START_ADVERTISEMENT_ACTION
  };
};

export const advertisementPictureFail = message => {
  return {
    type: actionTypes.ADVERTISEMENT_PICTURE_FAIL,
    message
  };
};

export const getAdvertisementPictureListSuccess = (pictureList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_PICTURE_LIST_SUCCESS,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getAdvertisementPictureList1 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-picture-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getAdvertisementPictureListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(advertisementPictureFail(response.data.message));
          }
        } else {
          dispatch(advertisementPictureFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementPictureFail(err.message));
      });
  };
};

//Banner Pictures actions for page1 2 and 3
export const getBannerPictureListSuccess1 = (pictureList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_BANNER_PICTURE_LIST_SUCCESS1,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getBannerPictureList1 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-banner-picture-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBannerPictureListSuccess1(response.data.data,response.data.message));
          } else {
            dispatch(advertisementPictureFail(response.data.message));
          }
        } else {
          dispatch(advertisementPictureFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementPictureFail(err.message));
      });
  };
};


export const getBannerPictureListSuccess2 = (pictureList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_BANNER_PICTURE_LIST_SUCCESS2,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getBannerPictureList2 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-banner-picture-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBannerPictureListSuccess2(response.data.data,response.data.message));
          } else {
            dispatch(advertisementPictureFail(response.data.message));
          }
        } else {
          dispatch(advertisementPictureFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementPictureFail(err.message));
      });
  };
};

export const getBannerPictureListSuccess3 = (pictureList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_BANNER_PICTURE_LIST_SUCCESS3,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getBannerPictureList3 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-banner-picture-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBannerPictureListSuccess3(response.data.data,response.data.message));
          } else {
            dispatch(advertisementPictureFail(response.data.message));
          }
        } else {
          dispatch(advertisementPictureFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementPictureFail(err.message));
      });
  };
};

export const getSquarePictureListSuccess1 = (pictureList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_SQUARE_PICTURE_LIST_SUCCESS1,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getSquarePictureList1 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-square-picture-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getSquarePictureListSuccess1(response.data.data,response.data.message));
          } else {
            dispatch(advertisementPictureFail(response.data.message));
          }
        } else {
          dispatch(advertisementPictureFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementPictureFail(err.message));
      });
  };
};


export const getSquarePictureListSuccess2 = (pictureList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_SQUARE_PICTURE_LIST_SUCCESS2,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getSquarePictureList2 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-square-picture-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getSquarePictureListSuccess2(response.data.data,response.data.message));
          } else {
            dispatch(advertisementPictureFail(response.data.message));
          }
        } else {
          dispatch(advertisementPictureFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementPictureFail(err.message));
      });
  };
};

export const getSquarePictureListSuccess3 = (pictureList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_SQUARE_PICTURE_LIST_SUCCESS3,
    listing: pictureList,
    message: message ? message : ''
  };
};

export const getSquarePictureList3 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-square-picture-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getSquarePictureListSuccess3(response.data.data,response.data.message));
          } else {
            dispatch(advertisementPictureFail(response.data.message));
          }
        } else {
          dispatch(advertisementPictureFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementPictureFail(err.message));
      });
  };
};


//video actions

export const advertisementVideoFail = message => {
  return {
    type: actionTypes.ADVERTISEMENT_VIDEO_FAIL,
    message
  };
};

export const getAdvertisementVideoListSuccess = (videoList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_VIDEO_LIST_SUCCESS,
    listing: videoList,
    message: message ? message : ''
  };
};

export const getAdvertisementVideoList1 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-video-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getAdvertisementVideoListSuccess(response.data.data,response.data.message));
          } else {
            dispatch(advertisementVideoFail(response.data.message));
          }
        } else {
          dispatch(advertisementVideoFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementVideoFail(err.message));
      });
  };
};

//banner video for Page1 ,2 and 3
export const getBannerVideoListSuccess1 = (videoList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_BANNER_VIDEO_LIST_SUCCESS1,
    listing: videoList,
    message: message ? message : ''
  };
};

export const getBannerVideoList1 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-banner-video-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBannerVideoListSuccess1(response.data.data,response.data.message));
          } else {
            dispatch(advertisementVideoFail(response.data.message));
          }
        } else {
          dispatch(advertisementVideoFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementVideoFail(err.message));
      });
  };
};

export const getBannerVideoListSuccess2 = (videoList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_BANNER_VIDEO_LIST_SUCCESS2,
    listing: videoList,
    message: message ? message : ''
  };
};

export const getBannerVideoList2 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-banner-video-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBannerVideoListSuccess2(response.data.data,response.data.message));
          } else {
            dispatch(advertisementVideoFail(response.data.message));
          }
        } else {
          dispatch(advertisementVideoFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementVideoFail(err.message));
      });
  };
};

export const getBannerVideoListSuccess3 = (videoList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_BANNER_VIDEO_LIST_SUCCESS3,
    listing: videoList,
    message: message ? message : ''
  };
};

export const getBannerVideoList3 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-banner-video-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBannerVideoListSuccess3(response.data.data,response.data.message));
          } else {
            dispatch(advertisementVideoFail(response.data.message));
          }
        } else {
          dispatch(advertisementVideoFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementVideoFail(err.message));
      });
  };
};

//Square Video for Page 1,2 and 3

export const getSquareVideoListSuccess1 = (videoList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_SQUARE_VIDEO_LIST_SUCCESS1,
    listing: videoList,
    message: message ? message : ''
  };
};

export const getSquareVideoList1 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-square-video-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getSquareVideoListSuccess1(response.data.data,response.data.message));
          } else {
            dispatch(advertisementVideoFail(response.data.message));
          }
        } else {
          dispatch(advertisementVideoFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementVideoFail(err.message));
      });
  };
};

export const getSquareVideoListSuccess2 = (videoList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_SQUARE_VIDEO_LIST_SUCCESS2,
    listing: videoList,
    message: message ? message : ''
  };
};

export const getSquareVideoList2 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-square-video-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getSquareVideoListSuccess2(response.data.data,response.data.message));
          } else {
            dispatch(advertisementVideoFail(response.data.message));
          }
        } else {
          dispatch(advertisementVideoFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementVideoFail(err.message));
      });
  };
};

export const getSquareVideoListSuccess3 = (videoList,message = '') => {
  return {
    type: actionTypes.ADVERTISEMENT_SQUARE_VIDEO_LIST_SUCCESS3,
    listing: videoList,
    message: message ? message : ''
  };
};

export const getSquareVideoList3 = (data) => {
  return dispatch => {
    dispatch(startAdvertisementAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/webCustomer/get-Advertisement-square-video-list", data,{
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getSquareVideoListSuccess3(response.data.data,response.data.message));
          } else {
            dispatch(advertisementVideoFail(response.data.message));
          }
        } else {
          dispatch(advertisementVideoFail(response.message));
        }
      })
      .catch(err => {
        dispatch(advertisementVideoFail(err.message));
      });
  };
};