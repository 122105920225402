import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import 'react-awesome-slider/dist/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import * as actions from '../store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import Spinner from "../Spinner/Spinner";
import { MDBDataTable } from "mdbreact";
import 'react-awesome-slider/dist/styles.css';
import { LoginHelperModal } from "./LoginHelperModal";
import ReactPlayer from 'react-player'
import {
    Button
} from 'reactstrap'
import '../App.css'
class SubscriptionPlan extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            monthlyBottonColor: "#2dce89",
            yearlyBottonColor: "#0a68ae",
            oneTimeBottonColor: "#0a68ae",
            playing: true
        }
    }

    componentWillMount() {
        const queryParams = new URLSearchParams(window.location.search)
        // window.scrollTo(0,0);
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
        const type = queryParams.get("type")
        if (type === 'monthly') {
            this.getMontlyPlan()
        } else if (type === 'onetime') {
            this.getOneTimePlan()
        } else {
            this.props.advertiseSubscriptionList({ subscription_type: "monthly" })
        }
    }

    async componentDidMount() {
        let lat = await localStorage.getItem("latitude")
        let long = await localStorage.getItem("longitude")
        let dataSquare = {
            latitude: parseFloat(lat),
            longitude: parseFloat(long),
            size_type: "square",
        }
        let dataBanner = {
            latitude: parseFloat(lat),
            longitude: parseFloat(long),
            size_type: "banner",
        }
        this.props.getPricingBannerVideoList(dataBanner)
        this.props.getPricingBannerPictureList(dataBanner)
        this.props.getPricingSquareVideoList(dataSquare)
        this.props.getPricingSquarePictureList(dataSquare)

    }


    getMontlyPlan() {
        this.props.advertiseSubscriptionList({ subscription_type: "monthly" })
        this.setState(
            {
                monthlyBottonColor: "#2dce89",
                yearlyBottonColor: "#0a68ae",
                oneTimeBottonColor: "#0a68ae",
            }
        )
    }
    getYearlyPlan() {
        this.props.advertiseSubscriptionList({ subscription_type: "yearly" })
        this.setState(
            {
                monthlyBottonColor: "#0a68ae",
                yearlyBottonColor: "#2dce89",
                oneTimeBottonColor: "#0a68ae",
            }
        )
    }
    getOneTimePlan() {
        this.props.advertiseSubscriptionList({ subscription_type: "onetime" })
        this.setState(
            {
                monthlyBottonColor: "#0a68ae",
                yearlyBottonColor: "#0a68ae",
                oneTimeBottonColor: "#2dce89",
            }
        )

    }
    OnSubscriptionPlan(planDetails) {
        const userToken = localStorage.getItem('token')
        if (!userToken) {
            toast.warning("Please login!")
        } else {
            this.props.history.push({
                pathname: '/subscription-plan-details/' + `${planDetails.planId}`,
                planDetails: planDetails // your data array of objects
            })
        }
    }
    render() {
        let pricingModal;
        const userToken = localStorage.getItem('token');
        let { is_monthlyfreetrial_advertise_subscription } = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : false;
        var sno = 1
        let advSubscriptionList = [];
        let subscriptionPlanTable = <Spinner />;
        {
            this.props.subscriptionPlanlist.length > 0
                ? this.props.subscriptionPlanlist.map(datas => {
                    advSubscriptionList.push(
                        {

                            s_no: (sno++),
                            advType: datas.advertisement_type,
                            subsType: datas.subscription_type,
                            price: "$ " + datas.price,
                            planDays: datas.plan_day,

                            action: <div>
                                {
                                    !userToken ?
                                        <LoginHelperModal props={{ value: 'pricing' }} /> :
                                        <Button className='btn btn-icon btn-danger btn-sm'
                                            onClick={(id) => this.OnSubscriptionPlan({
                                                advertisementType: datas.advertisement_type,
                                                subscriptionType: datas.subscription_type,
                                                price: datas.price,
                                                planDays: datas.plan_day,
                                                planId: datas._id
                                            })} >
                                            Buy Plan
                                        </Button>
                                }
                            </div>

                        }
                    )
                })
                : advSubscriptionList.push(
                    ['No record']
                )
        }
        const data = {
            columns: [
                {
                    label: "Sr.No",
                    field: "s_no",
                    sort: "asc",
                },
                {
                    label: "Advertisement Type",
                    field: "advType",
                    sort: "asc",
                },
                {
                    label: "Subscription Type",
                    field: "subsType",
                    sort: "asc",
                },
                {
                    label: "Price (in $)",
                    field: "price",
                    sort: "asc",
                },
                {
                    label: "Plan Days",
                    field: "planDays",
                    sort: "asc",
                },
                {
                    label: "Action",
                    field: "action",
                    sort: "asc",
                },
            ],
            rows: advSubscriptionList,
        };

        subscriptionPlanTable = (
            <MDBDataTable striped bordered hover entries={10} data={data} />
        );
        let monthColor = this.state.monthlyBottonColor;
        let yearColor = this.state.yearlyBottonColor;
        let onetimeColor = this.state.oneTimeBottonColor;

        return (
            <>
                <Header />
                <section className="my-5">
                    <div className="container subscription-plan" id='top'>
                        {/* advertisement video and picture  */}
                        {
                            this.props.pricingBannerVideoList.length > 0 ?
                                <div className="video-wrapper row g-md-0">
                                    <div className="col-md-4">
                                        <div className="slide-container">
                                            <Slide arrows={false} duration={10000} >
                                                {this.props.pricingBannerVideoList.map((slideImage, index) => (
                                                    <div className="each-slide" key={index}>
                                                        <a target="_blank" href={'https://' + slideImage.website}>
                                                            <div >
                                                                <ReactPlayer url={slideImage.video_url} playing={true} volume={0.5} muted={true} controls={true}
                                                                    loop={true} width={'100%'} height={'250px'}></ReactPlayer>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="slide-container">
                                            <Slide arrows={false} duration={10000} >
                                                {this.props.pricingBannerVideoList.map((slideImage, index) => (
                                                    <div className="each-slide" key={index}>
                                                        <a target="_blank" href={'https://' + slideImage.website}>
                                                            <div>
                                                                <ReactPlayer url={slideImage.video_url} playing={true} volume={0.5} muted={true} controls={true}
                                                                    loop={true} width={'100%'} height={'250px'}></ReactPlayer>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </Slide>

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="slide-container">
                                            <Slide arrows={false} duration={10000} >
                                                {this.props.pricingBannerVideoList.map((slideImage, index) => (
                                                    <div className="each-slide" key={index}>
                                                        <a target="_blank" href={'https://' + slideImage.website}>
                                                            <div >
                                                                <ReactPlayer url={slideImage.video_url} playing={true} volume={0.5} muted={true} controls={true}
                                                                    loop={true} width={'100%'} height={'250px'}></ReactPlayer>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </Slide>
                                        </div>
                                    </div>
                                </div> :
                                ''
                        }
                        {/* banner pictures */}
                        {this.props.pricingBannerPictureList.length > 0 ?
                            <div className="video-wrapper row g-md-0">
                                <div className="col-md-4">
                                    <div className="slide-container">
                                        <Slide arrows={false} duration={3000}>
                                            {this.props.pricingBannerPictureList.map((slideImage, index) => (
                                                <div className="each-slide" key={index}>
                                                    <a target="_blank" href={'https://' + slideImage.website} >
                                                        <div>
                                                            <img src={slideImage.picture_url} style={{ height: '40vh', width: '100%' }} />
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </Slide>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="slide-container">
                                        <Slide arrows={false} duration={3000}>
                                            {this.props.pricingBannerPictureList.map((slideImage, index) => (
                                                <div className="each-slide" key={index}>
                                                    <a target="_blank" href={'https://' + slideImage.website} >
                                                        <div>
                                                            <img src={slideImage.picture_url} style={{ height: '40vh', width: '100%' }} />
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </Slide>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="slide-container">
                                        <Slide arrows={false} duration={3000}>
                                            {this.props.pricingBannerPictureList.map((slideImage, index) => (
                                                <div className="each-slide" key={index}>
                                                    <a target="_blank" href={'https://' + slideImage.website} >
                                                        <div>
                                                            <img src={slideImage.picture_url} style={{ height: '40vh', width: '100%' }} />
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </Slide>
                                    </div>
                                </div>
                            </div> :
                            ''
                        }
                        <div className='header-body'>
                            <h6 className='h2 text-center mb-0'>Subscription Plan List</h6>
                            <div className='d-flex align-items-center justify-content-center py-4'>
                                <Button className='btn btn-primary border-0 rounded-0' onClick={() => this.getMontlyPlan()} style={{ background: monthColor, color: "white" }}>Montly Plan</Button>
                                <Button className='btn btn-primary border-0 rounded-0 mx-2' onClick={() => this.getYearlyPlan()} style={{ background: yearColor, color: "white" }}>Yearly Plan</Button>
                                <Button className='btn btn-primary border-0 rounded-0' onClick={() => this.getOneTimePlan()} style={{ background: onetimeColor, color: "white" }}>OneTime Plan</Button>
                            </div>
                        </div>
                        {(is_monthlyfreetrial_advertise_subscription == false && userToken) ? <div className='freetrialPlan'> Get any monthly free trial plan in just $0.99 for 14 Days.</div> : ''}
                        <div className='mt-4 mb-5'>
                            <div className='row'>
                                <div className='col'>
                                    <div className='card'>
                                        <div className='card-header border-0'>
                                            <div className='table-responsive'>
                                                {subscriptionPlanTable}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                        </div>
                        {/* Square video list */}
                        {
                            this.props.pricingSquareVideoList.length > 0 ?
                                <div className="row g-0 mt-5">
                                    {this.props.pricingSquareVideoList.map((item) => (
                                        <div className="col-md-4" key={item._id}>
                                            <div>
                                                <a target="_blank" href={'https://' + item.website}>
                                                    <ReactPlayer url={item.video_url} playing={true} volume={0.5} muted={true} controls={true}
                                                        loop={true} width={'100%'} height={'150%'}></ReactPlayer>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                : ''
                        }

                        {/* square picture list */}
                        {
                            this.props.pricingSquarePictureList.length > 0 ?
                                <div className="row g-0 mt-1 mb-2">
                                    {this.props.pricingSquarePictureList.map((item) => (
                                        <div className="col-md-4" key={item._id}>
                                            <div>
                                                <a target="_blank" href={'https://' + item.website}>
                                                    <div>
                                                        <img src={item.picture_url} style={{ height: '35vh', width: '100%' }} />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                : ''
                        }

                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

const mapPropsToState = (state) => {
    return {
        subscriptionPlanlist: state.advertiseSubscription.subscriptionPlanlist,
        pricingBannerVideoList: state.blog.pricingBannerVideoList,
        pricingBannerPictureList: state.blog.pricingBannerPictureList,
        pricingSquareVideoList: state.blog.pricingSquareVideoList,
        pricingSquarePictureList: state.blog.pricingSquarePictureList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        advertiseSubscriptionList: (data) => dispatch(actions.advertiseSubscriptionList(data)),
        getPricingBannerVideoList: (data) => dispatch(actions.getPricingBannerVideoList(data)),
        getPricingBannerPictureList: (data) => dispatch(actions.getPricingBannerPictureList(data)),
        getPricingSquareVideoList: (data) => dispatch(actions.getPricingSquareVideoList(data)),
        getPricingSquarePictureList: (data) => dispatch(actions.getPricingSquarePictureList(data))
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(SubscriptionPlan))
