import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  advetisementPictureList1: [],
  advertisementVideoList1: [],
  advetisementBannerPictureList1: [],
  advertisementBannerVideoList1: [],
  advetisementSquarePictureList1: [],
  advertisementSquareVideoList1: [],
  advetisementBannerPictureList2: [],
  advertisementBannerVideoList2: [],
  advetisementSquarePictureList2: [],
  advertisementSquareVideoList2: [],
  advetisementBannerPictureList3: [],
  advertisementBannerVideoList3: [],
  advetisementSquarePictureList3: [],
  advertisementSquareVideoList3: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_ADVERTISEMENT_ACTION:
      return {
        ...state,
        loading: true,
        error: null
      };

    case actionTypes.ADVERTISEMENT_PICTURE_FAIL:
      return {
        ...state,
        loading: null,
        error: action.message,
        success: null,
      }
    case actionTypes.ADVERTISEMENT_PICTURE_LIST_SUCCESS:

      return {
        ...state,
        loading: null,
        advetisementPictureList1: action.listing,
        error: null,
        success: action.message,
      }
    case actionTypes.ADVERTISEMENT_BANNER_PICTURE_LIST_SUCCESS1:

      return {
        ...state,
        loading: null,
        advetisementBannerPictureList1: action.listing,
        error: null,
        success: action.message,
      }
    case actionTypes.ADVERTISEMENT_BANNER_PICTURE_LIST_SUCCESS2:

      return {
        ...state,
        loading: null,
        advetisementBannerPictureList2: action.listing,
        error: null,
        success: action.message,
      }
    case actionTypes.ADVERTISEMENT_BANNER_PICTURE_LIST_SUCCESS3:

      return {
        ...state,
        loading: null,
        advetisementBannerPictureList3: action.listing,
        error: null,
        success: action.message,
      }
    case actionTypes.ADVERTISEMENT_SQUARE_PICTURE_LIST_SUCCESS1:

      return {
        ...state,
        loading: null,
        advetisementSquarePictureList1: action.listing,
        error: null,
        success: action.message,
      }
    case actionTypes.ADVERTISEMENT_SQUARE_PICTURE_LIST_SUCCESS2:

      return {
        ...state,
        loading: null,
        advetisementSquarePictureList2: action.listing,
        error: null,
        success: action.message,
      }
    case actionTypes.ADVERTISEMENT_SQUARE_PICTURE_LIST_SUCCESS3:

      return {
        ...state,
        loading: null,
        advetisementSquarePictureList3: action.listing,
        error: null,
        success: action.message,
      }
//video reduucers

    case actionTypes.ADVERTISEMENT_VIDEO_FAIL:
      return {
        ...state,
        loading: null,
        error: action.message,
        success: null,
      }
    case actionTypes.ADVERTISEMENT_VIDEO_LIST_SUCCESS:

      return {
        ...state,
        loading: null,
        advertisementVideoList1: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.ADVERTISEMENT_BANNER_VIDEO_LIST_SUCCESS1:

      return {
        ...state,
        loading: null,
        advertisementBannerVideoList1: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.ADVERTISEMENT_BANNER_VIDEO_LIST_SUCCESS2:

      return {
        ...state,
        loading: null,
        advertisementBannerVideoList2: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.ADVERTISEMENT_BANNER_VIDEO_LIST_SUCCESS3:

      return {
        ...state,
        loading: null,
        advertisementBannerVideoList3: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.ADVERTISEMENT_SQUARE_VIDEO_LIST_SUCCESS1:

      return {
        ...state,
        loading: null,
        advertisementSquareVideoList1: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.ADVERTISEMENT_SQUARE_VIDEO_LIST_SUCCESS2:

      return {
        ...state,
        loading: null,
        advertisementSquareVideoList2: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.ADVERTISEMENT_SQUARE_VIDEO_LIST_SUCCESS3:

      return {
        ...state,
        loading: null,
        advertisementSquareVideoList3: action.listing,
        error: null,
        success: action.message,
      }

    default:
      return state;
  }
};

export default reducer;