import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "../store/actions/index";
import EmailIcon from "@material-ui/icons/Email";
import "react-phone-input-2/lib/style.css";
import VerifyForgetOtp from './VerifyForgetOtp';
import Header from "./Header";
import Footer from "./Footer";

class ForgetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            formErremail: '',
        }
    }
    handleSubmit = async () => {
        await this.setState({
            formErremail: (this.state.email === '') ? "Please provide this field" : ''
        })
        if (!(this.state.email === '')) {
            await this.props.forgotPassword({
                email: this.state.email,
            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { enqueueSnackbar, error, success, signup_success, signup_error } = this.props;
        success && enqueueSnackbar(success, { variant: "success" });
        error && enqueueSnackbar(error, { variant: "error" });
        signup_success && enqueueSnackbar(signup_success, { variant: "success" });
        signup_error && enqueueSnackbar(signup_error, { variant: "error" });
    }
    onChangeAddName = async (key, value) => {
        var self = this;
        await self.setState({
            [key]: value,
            ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
        })
    }

    render() {

        return (
            <>
                <Header />
                <div className="modalcont">
                    <div className="modalinner my-5 py-4">
                        <h2>Forgot Password ?</h2>
                        <p>Please Enter Your Email,We will send otp to verify your account</p>
                        <div className="loginForm signup" style={{height:"250px"}}>
                            <div class="input-group mb-3 mt-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text h-100" id="basic-addon1">
                                        <EmailIcon />
                                    </span>
                                </div>
                                <input
                                    type="email"
                                    class="form-control form-control-lg"
                                    placeholder="Enter Email"
                                    id='email'
                                    value={this.state.email}
                                    onChange={(e) => this.onChangeAddName('email', e.target.value)}
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                />
                                <span style={{ color: 'red' }}>{this.state.formErremail}</span>
                            </div>
                            {this.state.email ? <span style={{ color: 'green' }}>We will send otp on this email to verify</span> : ''}
                            <div className="d-grid mt-4">
                                <button
                                    className="btn btn-primary btn-lg"
                                    type="button"
                                    onClick={(e) => this.handleSubmit()}
                                >
                                    Submit
                                </button>
                            </div>

                            <div className="d-grid mt-4">
                                <a
                                    className="nav-link"
                                    // href="#"
                                    data-toggle="modal"
                                    data-target="#exampleModal_verify_otp"
                                > <button
                                    className="btn btn-primary btn-m"
                                    type="button"
                                >
                                        Verify Otp
                                    </button></a>

                            </div>
                        </div>
                        <div
                            className="modal fade"
                            id="exampleModal_verify_otp"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <VerifyForgetOtp />
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>

        )
    }
}
const mapPropsToState = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        signup_error: state.auth.signup_error,
        signup_success: state.auth.signup_success,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        forgotPassword: (data) => dispatch(actions.forgotPassword(data))
    };
};

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(ForgetPassword));
