import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
// import Login from "../Login";
// import Signup from "../Signup";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
// import AwesomeSlider from 'react-awesome-slider';
// import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import ReactPlayer from 'react-player'
import { ToastContainer, toast } from 'react-toastify';
import * as actions from '../../store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { MDBDataTable } from "mdbreact";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
} from 'reactstrap'



class BlogList extends React.Component {
    constructor(props) {
        super(props)

    }
    componentWillMount = () =>{
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
    }
    async componentDidMount() {
        let lat = await localStorage.getItem("latitude")
        let long = await localStorage.getItem("longitude")
        let dataBannerPic = {
            latitude: parseFloat(lat),
            longitude: parseFloat(long),
            size_type: "banner",
        }
        let dataSquarePic = {
            latitude: parseFloat(lat),
            longitude: parseFloat(long),
            size_type: "square",
        }
        let dataBannerVideo = {
            latitude: parseFloat(lat),
            longitude: parseFloat(long),
            size_type: "banner",
        }
        this.props.getBlogList()
        this.props.getBlogSquarePictureList(dataSquarePic)
        this.props.getBlogBannerPictureList(dataBannerPic)
        this.props.getBlogBannerVideoList(dataBannerVideo)
        this.props.getBlogSquareVideoList(dataSquarePic)
    }

    render() {
        return (
            <>
                <Header />
                <div className="my-5">
                    <div className='container'>
                        {/* advertisement video and picture  */}
                        {
                            this.props.blogBannerVideoList.length > 0 ?
                                <div className="video-wrapper row g-md-0">
                                    <div className="col-md-4">
                                        <div className="slide-container">
                                            <Slide arrows={false} duration={10000} >
                                                {this.props.blogBannerVideoList.map((slideImage, index) => (
                                                    <div className="each-slide" key={index}>
                                                        <a target="_blank" href={'https://' + slideImage.website}>
                                                            <div >
                                                                <ReactPlayer url={slideImage.video_url} playing={true} volume={0.5} muted={true} controls={true}
                                                                    loop={true} width={'100%'} height={'250px'}></ReactPlayer>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="slide-container">
                                            <Slide arrows={false} duration={10000} >
                                                {this.props.blogBannerVideoList.map((slideImage, index) => (
                                                    <div className="each-slide" key={index}>
                                                        <a target="_blank" href={'https://' + slideImage.website}>
                                                            <div>
                                                                <ReactPlayer url={slideImage.video_url} playing={true} volume={0.5} muted={true} controls={true}
                                                                    loop={true} width={'100%'} height={'250px'}></ReactPlayer>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </Slide>

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="slide-container">
                                            <Slide arrows={false} duration={10000} >
                                                {this.props.blogBannerVideoList.map((slideImage, index) => (
                                                    <div className="each-slide" key={index}>
                                                        <a target="_blank" href={'https://' + slideImage.website}>
                                                            <div >
                                                                <ReactPlayer url={slideImage.video_url} playing={true} volume={0.5} muted={true} controls={true}
                                                                    loop={true} width={'100%'} height={'250px'}></ReactPlayer>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </Slide>
                                        </div>
                                    </div>
                                </div> :
                                ''
                        }
                        {/* banner pictures */}
                        {this.props.blogBannerPictureList.length > 0 ?
                            <div className="video-wrapper row g-md-0">
                                <div className="col-md-4">
                                    <div className="slide-container">
                                        <Slide arrows={false} duration={3000}>
                                            {this.props.blogBannerPictureList.map((slideImage, index) => (
                                                <div className="each-slide" key={index}>
                                                    <a target="_blank" href={'https://' + slideImage.website} >
                                                        <div>
                                                            <img src={slideImage.picture_url} style={{ height: '40vh', width: '100%' }} />
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </Slide>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="slide-container">
                                        <Slide arrows={false} duration={3000}>
                                            {this.props.blogBannerPictureList.map((slideImage, index) => (
                                                <div className="each-slide" key={index}>
                                                    <a target="_blank" href={'https://' + slideImage.website} >
                                                        <div>
                                                            <img src={slideImage.picture_url} style={{ height: '40vh', width: '100%' }} />
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </Slide>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="slide-container">
                                        <Slide arrows={false} duration={3000}>
                                            {this.props.blogBannerPictureList.map((slideImage, index) => (
                                                <div className="each-slide" key={index}>
                                                    <a target="_blank" href={'https://' + slideImage.website} >
                                                        <div>
                                                            <img src={slideImage.picture_url} style={{ height: '40vh', width: '100%' }} />
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </Slide>
                                    </div>
                                </div>
                            </div> :
                            ''
                        }
                        {/* Square video list */}
                        {
                            this.props.blogSquareVideoList.length > 0 ?
                                <div className="row g-0 mt-5">
                                    {this.props.blogSquareVideoList.map((item) => (
                                        <div className="col-md-4" key={item._id}>
                                            <div>
                                                <a target="_blank" href={'https://' + item.website}>
                                                    <ReactPlayer url={item.video_url} playing={true} volume={0.5} muted={true} controls={true}
                                                        loop={true} width={'100%'} height={'150%'}></ReactPlayer>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                : ''
                        }
                        {/* square picture list */}
                        {
                            this.props.blogSquarePictureList.length > 0 ?
                                <div className="row g-0 mt-1">
                                    {this.props.blogSquarePictureList.map((item) => (
                                        <div className="col-md-4" key={item._id}>
                                            <div>
                                                <a target="_blank" href={'https://' + item.website}>
                                                    <div>
                                                        <img src={item.picture_url} style={{ height: '30vh', width: '100%' }} />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                : ''
                        }
                        {/*BLog listing  */}
                        <div className='row'>
                            {this.props.blogList.map((item) => (
                                <div className='col-md-6 col-lg-4 my-3' key={item._id}>
                                    <Link to={"/blog-details/" + item._id} className="blog-post">
                                        <div className="img-wrapper">
                                            {item.blog_video ?
                                                <iframe className="blog-image" src={item.blog_video} allow="accelerometer; encrypted-media; picture-in-picture" allowFullScreen></iframe>
                                                :
                                                <img className="blog-image" src={item.blog_picture} alt="" />
                                            }
                                        </div>
                                        <div className="blog-label">Blog Post</div>
                                        <div className="blog-title">{item.blog_title}</div>
                                        <div className="blog-date">{new Date(item.createdAt).toDateString()}</div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

const mapPropsToState = (state) => {
    return {
        success: state.blog.success,
        error: state.blog.error,
        blogList: state.blog.blogList,
        blogBannerPictureList: state.blog.blogBannerPictureList,
        blogSquarePictureList: state.blog.blogSquarePictureList,
        blogBannerVideoList: state.blog.blogBannerVideoList,
        blogSquareVideoList: state.blog.blogSquareVideoList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBlogList: () => dispatch(actions.getBlogList()),
        getBlogSquarePictureList: (data) => dispatch(actions.getBlogSquarePictureList(data)),
        getBlogBannerPictureList: (data) => dispatch(actions.getBlogBannerPictureList(data)),
        getBlogBannerVideoList: (data) => dispatch(actions.getBlogBannerVideoList(data)),
        getBlogSquareVideoList: (data) => dispatch(actions.getBlogSquareVideoList(data)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(BlogList))
