import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { Route } from 'react-router-dom';
import * as actions from '../../store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import ReactPlayer from 'react-player'
import '../../App.css'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
class Rideshare extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      category_id: '',
      category_name: ''
    }
  }

  componentWillMount() {
    this.props.fetchSubCategory(this.state.category_id)
    this.props.fetchCategory()

    if (this.props.categorylisting.length > 0) {
      this.setState({
        category_id: this.props.categorylisting[0]._id,
      });
    }
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }

  async componentDidMount() {
    if (this.props != undefined && this.props.location != undefined && this.props.location.aboutProps != undefined && this.props.location.aboutProps.name != undefined) {
      let category_id = this.props.location.aboutProps.name;
      let category_name = this.props.location.aboutProps.category;
      this.props.fetchSubCategory(category_id)
      this.setState({
        category_id: category_id,
        category_name: category_name
      });
    }

    let lat = await localStorage.getItem("latitude")
    let long = await localStorage.getItem("longitude")
    let data = {
      page_type: 'second',
      latitude: parseFloat(lat),
      longitude: parseFloat(long),
      category_id: this.props.location.aboutProps.name
    }
    await this.props.getBannerPictureList2(data);
    await this.props.getSquarePictureList2(data);
    await this.props.getBannerVideoList2(data);
    await this.props.getSquareVideoList2(data);
  }

  render() {

    let category_name = ""
    let categoryList = [];
    let categoryList1 = [];
    for (let index = 0; index < this.props.categorylisting.length; index++) {
      if (this.props != undefined && this.props.location != undefined && this.props.location.aboutProps != undefined && this.props.location.aboutProps.name != undefined && this.props.categorylisting[index]._id == this.state.category_id) {
        category_name = this.props.categorylisting[index].name
        break
      }
      else {
        category_name = <Redirect from="./subcategory" to="/" />
      }
    }
    let i = 1
    for (let index = 0; index < this.props.subcategorylisting.length; index++) {
      const element = this.props.subcategorylisting[index];
      categoryList1.push(
        <div className="col-6 col-md-4 col-xl-3">
          <div className="ridebox h-100 px-3">
            {/* <Link to="/carpool"> */}
            <Link
              to={{
                pathname: '/send-us-requirement',
                // aboutProps: {
                //   name: this.props.subcategorylisting[index]._id,
                // },
              }}
            // {"/subcategory/"+datas._id}
            >
              {/* </Link> */}
              <img
                className="rounded-circle mb-15"
                width="100"
                height="100"
                src={this.props.subcategorylisting[index].sub_category_images}
              />
              <h3>{this.props.subcategorylisting[index].name}</h3>
            </Link>
          </div>
        </div>
      );
      if (i == 4 || this.props.subcategorylisting.length == index + 1) {
        categoryList.push(
          <div className="rideboxCont row g-0">
            {categoryList1}
          </div>)
        categoryList1 = [];
        i = 1;
      }
    }


    return (
      <>
        <Header />

        <section className="subcatery-page my-5">
          <div className="container">
            {/* Banner Video*/}
            {
              this.props.advertisementBannerVideoList2.length > 0 ?
                <div className="video-wrapper row g-md-0">
                  <div className="col-md-6">
                    <div className="slide-container">
                      <Slide arrows={false} duration={5000} >

                        {this.props.advertisementBannerVideoList2.map((slideImage, index) => (

                          <div className="each-slide" key={index}>
                            <a
                              target="_blank" href={'https://' + slideImage.website}
                            >
                              <div >
                                <ReactPlayer url={slideImage.video_url} playing={true} volume={0} controls={true}
                                  loop={true} width={'100%'} ></ReactPlayer>
                              </div>
                            </a>
                          </div>
                        ))}
                      </Slide>

                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="slide-container">
                      <Slide arrows={false} duration={5000} >

                        {this.props.advertisementBannerVideoList2.map((slideImage, index) => (
                          <div className="each-slide" key={index}>
                            <a
                              target="_blank" href={'https://' + slideImage.website}
                            // onclick={()=>this.link(e.website)} 
                            >
                              <div >
                                <ReactPlayer url={slideImage.video_url} playing={true} volume={0} controls={true}
                                  loop={true} width={'100%'}></ReactPlayer>
                              </div>
                            </a>
                          </div>
                        ))}
                      </Slide>

                    </div>
                  </div>
                </div> :
                ''
            }

            {/* for Picture banner */}
            {this.props.advetisementBannerPictureList2.length > 0 ?
              <div className="video-wrapper row g-md-0">
                <div className="col-md-6">
                  <div className="slide-container">
                    <Slide arrows={false} duration={3000}>
                      {this.props.advetisementBannerPictureList2.map((slideImage, index) => (
                        <div className="each-slide" key={index}>
                          <a
                            target="_blank" href={'https://' + slideImage.website}
                          >
                            <div >
                              <img src={slideImage.picture_url} style={{
                                height: '60vh',
                                width: '100%',
                              }} />
                            </div>
                          </a>
                        </div>
                      ))}
                    </Slide>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="slide-container">
                    <Slide arrows={false} duration={3000}>
                      {this.props.advetisementBannerPictureList2.map((slideImage, index) => (
                        <div className="each-slide" key={index}>
                          <a target="_blank" href={'https://' + slideImage.website}>
                            <div >
                              <img src={slideImage.picture_url} style={{
                                height: '60vh',
                                width: '100%',
                              }} />

                            </div>
                          </a>
                        </div>
                      ))}
                    </Slide>
                  </div>
                </div>
              </div> :
              ''
            }
            {/* Unlimited Square Video*/}
            {this.props.advertisementSquareVideoList2.length > 0 ?
              <div className="video-wrapper row g-md-0">

                {this.props.advertisementSquareVideoList2.map(e => (
                  <div className="col-md-4">
                    <a
                      target="_blank" href={'https://' + e.website}
                    >
                      <div >
                        <ReactPlayer url={e.video_url} playing={true} volume={0} controls={true}
                          loop={true} width={'100%'} ></ReactPlayer>
                      </div>
                    </a>
                  </div>

                ))}

              </div>
              : ''
            }

            {/* Unlimited Square Picture*/}
            {this.props.advetisementSquarePictureList2.length > 0 ?
              <div className="video-wrapper row g-md-0">
                {this.props.advetisementSquarePictureList2.map(e => (
                  <div className="col-md-4">
                    <a target="_blank" href={'https://' + e.website}>
                      <div >
                        <img src={e.picture_url} style={{
                          height: '50vh',
                          width: '100%',
                        }} />
                      </div>
                    </a>
                  </div>
                ))}
              </div>
              : ''
            }
            {/* Sub category Listing*/}
            <div className="d-flex mt-4">
              <h3 className="uprheadng text-nowrap pe-1">Dashboard / </h3>
              <h3 className="txtBlk">{category_name}</h3>
            </div>

            <div className="shadowCont mt-4 mb-5">
              {categoryList}
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

const mapPropsToState = (state) => {
  return {
    subcategorylisting: state.subCategory.subcategorylisting,
    categorylisting: state.category.categorylisting,
    advertisementBannerVideoList2: state.advertisementPicture.advertisementBannerVideoList2,
    advertisementSquareVideoList2: state.advertisementPicture.advertisementSquareVideoList2,
    advetisementBannerPictureList2: state.advertisementPicture.advetisementBannerPictureList2,
    advetisementSquarePictureList2: state.advertisementPicture.advetisementSquarePictureList2
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSubCategory: (data) => dispatch(actions.fetchsubCategoryListAction(data)),
    fetchCategory: () => dispatch(actions.fetchcategoryListAction()),
    getBannerPictureList2: (data) => dispatch(actions.getBannerPictureList2(data)),
    getSquarePictureList2: (data) => dispatch(actions.getSquarePictureList2(data)),
    getBannerVideoList2: (data) => dispatch(actions.getBannerVideoList2(data)),
    getSquareVideoList2: (data) => dispatch(actions.getSquareVideoList2(data))
  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(Rideshare))
