
import React from 'react'
import Login from './Login'

export const LoginHelperModal = (pricingModal) => {
    return (
        <div>
            <a
                className="nav-link m-0 mx-xl-2"
                href="#"
                data-toggle="modal"
                data-target="#exampleModal1"
            >
                {
                    pricingModal.props ?
                        <button className='btn btn-icon btn-danger btn-sm'>BUY NOW</button>
                        : <button className='btn btn-custom btn-custom-filled shadow-none'>BUY NOW</button>
                }

            </a>
            <div
                className="modal fade"
                id="exampleModal1"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <Login />
                        </div>
                        <div className="modal-footer">
                            Don't have Account?
                            <button type="button" className="btn btn-primary ms-3 my-0 py-1" data-dismiss="modal"
                                onClick={() => { window.location.href = '/register' }} >
                                SignUp
                            </button>
                            <button type="button" className="btn btn-secondary my-0 py-1" data-dismiss="modal">Close</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const LoginHelperForFreeTrail = (props) => {
    console.log(props.props.freeTraialNo, 'helloooo')
    return (
        <div>
            <a
                className="nav-link m-0 mx-xl-2"
                href="#"
                data-toggle="modal"
                data-target="#exampleModal1"
            >
                <button className='btn btn-custom btn-custom-filled position-relative'>Get Free trial</button>
                {
                    props.props.freeTraialNo == 1 ?
                        <button className='btn btn-custom btn-custom-filled position-relative'>Get Free trial</button> :
                        props.props.freeTraialNo == 2 ? <button className='btn btn-custom btn-custom-filled'>Get Free trial</button>
                            : <button className='btn btn-custom btn-custom-filled bg-white border-white'>Get Free trial</button>
                }
            </a>
            <div
                className="modal fade"
                id="exampleModal1"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <Login />
                        </div>
                        <div className="modal-footer">
                            Don't have Account?
                            <button type="button" className="btn btn-primary ms-3 my-0 py-1" data-dismiss="modal"
                                onClick={() => { window.location.href = '/register' }} >
                                SignUp
                            </button>
                            <button type="button" className="btn btn-secondary my-0 py-1" data-dismiss="modal">Close</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default { LoginHelperModal, LoginHelperForFreeTrail }