import React from 'react';
import Header from "../Header";
import Footer from "../Footer";
import 'react-slideshow-image/dist/styles.css'
import * as actions from '../../store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { cyan } from '@material-ui/core/colors';

class SendUsRequirement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            requiredService: [],
            formErrRequiredService: '',
            firstName: '',
            formErrfirstName: '',
            lastName: '',
            formErrlastName: '',
            mobileNo: '',
            formErrmobileNo: '',
            email: '',
            formErremail: '',
            userMessage:'',
            formErruserMessage:'',
        }
    }

    async componentWillMount() {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        await this.setState({
            formErrfirstName: (this.state.firstName === '') ? "Please provide this field" : '',
            formErrlastName: (this.state.lastName === '') ? "Please provide this field" : '',
            formErrmobileNo: (this.state.mobileNo === '') ? "Please provide this field" : '',
            formErremail: (this.state.email === '') ? "Please provide this field" : '',
            formErrRequiredService: (this.state.requiredService.length == 0) ? "Please provide this field" : '',
            formErruserMessage: (this.state.userMessage ==='') ? "Please provide this field" : '',
        })
        if (((this.state.email !== '') && (this.state.firstName !== '') && (this.state.lastName !== '') &&
            (this.state.mobileNo !== '') && (this.state.userMessage !== '') && (this.state.requiredService.length !== 0))) {
            await this.props.sendUsRequirementEmail({
                requiredService: this.state.requiredService,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                mobileNo: this.state.mobileNo,
                email: this.state.email,
                user_message:this.state.userMessage,
            },this.props.history)
        }
    }

    onChangeAddName = async (key, value) => {
        var self = this;
        await self.setState({
            [key]: value,
            ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
        })
    }

    handleCheckboxChange = event => {
        let newArray = [...this.state.requiredService, event.target.value];
        if (this.state.requiredService.includes(event.target.value)) {
            newArray = newArray.filter(service => service !== event.target.value);
        }
        this.setState({
            requiredService: newArray,
            formErrRequiredService: ""
        });
    };

    componentDidUpdate(prevProps, prevState) {
        const { enqueueSnackbar, signup_success, signup_error } = this.props;
        signup_success && enqueueSnackbar(signup_success, { variant: "success" });
        signup_error && enqueueSnackbar(signup_error, { variant: "error" });
    }


    render() {
        return (
            <>
                <Header />
                <section className='send_us_requirement my-5'>
                    <div className='container'>
                        <div className='form-wrapper mx-auto' style={{ maxWidth: '900px' }}>
                            {/* Form Section */}
                            <h3 className='text-uppercase mb-3'>Send us your requirement</h3>
                            <form className=''>
                                <div className='row g-3'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="firstName">First Name</label>
                                            <input type="name" class="form-control" id="firstName" placeholder='Enter your name'
                                                value={this.state.firstName}
                                                onChange={(e) => this.onChangeAddName('firstName', e.target.value)}
                                            />
                                            <span style={{ color: 'red' }}>{this.state.formErrfirstName}</span>
                                            <div id="validationServer03Feedback" class="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div class="form-group">
                                            <label className="form-label" htmlFor="lastName">Last Name</label>
                                            <input type="name" class="form-control" id="lastName" placeholder='Enter last name'
                                                value={this.state.lastName}
                                                onChange={(e) => this.onChangeAddName('lastName', e.target.value)}
                                            />
                                            <span style={{ color: 'red' }}>{this.state.formErrlastName}</span>
                                            <div id="validationServer03Feedback" class="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="userEmail">Email</label>
                                            <input type="email" className="form-control" id="userEmail" placeholder='Enter your email'
                                                value={this.state.email}
                                                onChange={(e) => this.onChangeAddName('email', e.target.value)}
                                            />
                                            <span style={{ color: 'red' }}>{this.state.formErremail}</span>
                                            <div id="validationServer03Feedback" class="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="userContact">Phone Number</label>
                                            <input type="text" className="form-control" id="userContact" placeholder='Enter your number'
                                                value={this.state.mobileNo}
                                                onChange={(e) => this.onChangeAddName('mobileNo', e.target.value)}
                                            />
                                            <span style={{ color: 'red' }}>{this.state.formErrmobileNo}</span>
                                            <div id="validationServer03Feedback" class="invalid-feedback"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='checkbox-cover mt-4'>
                                    <label className="form-label fw-bold mb-3" htmlFor="careExperience">What Type Service Do You Want</label>
                                    <div className='when-select-ecommerce border px-3 py-4'>
                                        <div class="form-check mb-1">
                                            <input class="form-check-input" type="checkbox" value="Digital Marketing" id="check1" onChange={this.handleCheckboxChange} />
                                            <label class="form-check-label" for="check1">
                                                Digital Marketing
                                            </label>
                                        </div>
                                        <div class="form-check mb-1">
                                            <input class="form-check-input" type="checkbox" value="Business Directory" id="check2" onChange={this.handleCheckboxChange} />
                                            <label class="form-check-label" for="check2">
                                                Business Directory Ad (Classified Ad)
                                            </label>
                                        </div>
                                        <div class="form-check mb-1">
                                            <input class="form-check-input" type="checkbox" value="Video Review And Comment" id="check3" onChange={this.handleCheckboxChange} />
                                            <label class="form-check-label" for="check3">
                                                Video Review & Comment
                                            </label>
                                        </div>
                                        <div class="form-check mb-1">
                                            <input class="form-check-input" type="checkbox" value="Small Business Consulting" id="check4" onChange={this.handleCheckboxChange} />
                                            <label class="form-check-label" for="check4">
                                                Small Business Consulting
                                            </label>
                                        </div>
                                        <div class="form-check mb-1">
                                            <input class="form-check-input" type="checkbox" value="Small Business Branding" id="check5" onChange={this.handleCheckboxChange} />
                                            <label class="form-check-label" for="check5">
                                                Small Business Branding
                                            </label>
                                        </div>
                                        <div class="form-check mb-1">
                                            <input class="form-check-input" type="checkbox" value="Mobile App Design And Development" id="check6" onChange={this.handleCheckboxChange} />
                                            <label class="form-check-label" for="check6">
                                                Mobile App Design & Development
                                            </label>
                                        </div>
                                        <div class="form-check mb-1">
                                            <input class="form-check-input" type="checkbox" value="Professional Business Website Design And Development" id="check7" onChange={this.handleCheckboxChange} />
                                            <label class="form-check-label" for="check7">
                                                Professional Business Website Design & Development
                                            </label>
                                        </div>
                                        <div class="form-check mb-1">
                                            <input class="form-check-input" type="checkbox" value="E-commerce Website Design And Development" id="check8" onChange={this.handleCheckboxChange} />
                                            <label class="form-check-label" for="check8">
                                                E-commerce Website Design & Development
                                            </label>
                                        </div>
                                        <span style={{ color: 'red' }}>{this.state.formErrRequiredService}</span>
                                    </div>
                                </div>

                                <div className='checkbox-cover mt-4'>
                                    <label className="form-label fw-bold mb-3" htmlFor="careExperience">Send us a message </label>
                                    <textarea type="textarea" rows={4} class="form-control" id="userMessage" placeholder='Write a message'
                                        value={this.state.userMessage}
                                        onChange={(e) => this.onChangeAddName('userMessage', e.target.value)}
                                    />
                                    <span style={{ color: 'red' }}>{this.state.formErruserMessage}</span>
                                </div>
                                <div className='d-flex align-items-center justify-content-between gap-3 mt-4'>
                                <button type="submit" className="btn btn-primary px-4 py-2" onClick={(e) => this.handleSubmit(e)}>Submit</button>
                                <div>Contact Us on <span style={{color:'var(--cyan)' ,fontWeight:600}}>+2174190067</span></div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

const mapPropsToState = (state) => {
    return {
        loading: state.auth.loading,
        signup_error: state.auth.signup_error,
        signup_success: state.auth.signup_success,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendUsRequirementEmail: (data,history) => dispatch(actions.sendUsRequirementEmail(data,history))
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(SendUsRequirement))