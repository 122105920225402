import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const getCardFail = (message) => {
    return {
      type: actionTypes.GET_CARD_FAIL,
      message: message
    };
  };

export const getCard = (cardDetails,message = '') => {
    return {
      type: actionTypes.GET_CARD_DETAILS,
      cardDetails: cardDetails,
      message: message ? message : ''
    };
  };

  export const updateCard = (cardDetails,message = '') => {
    return {
      type: actionTypes.UPDATE_CARD_DETAILS,
      cardDetails: cardDetails,
      message: message ? message : ''
    };
  };
   
  export const getCardDetails = (data) =>{
    return dispatch => {
        const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .get("/webCustomer/get-card-details",  {
            headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
              dispatch(getCard(response.data.data,''));
            } else if (response.data.status === 0){
                dispatch(getCardFail(response.data.message));
            }
            dispatch(getCardFail(response.data.message))
          }
        })
        .catch(err => {
            dispatch(getCardFail(err.message))
          console.log(err)
          // dispatch(timeSlotFail(err.message))
        });
    };
  }

  export const updateCardDetails = (data) =>{
    return dispatch => {
        const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/webCustomer/update-card-details", data, {
            headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
              dispatch(updateCard(response.data.data,response.data.message));
              window.location.reload(true)
            } else if (response.data.status === 0){
                dispatch(getCardFail(response.data.message));
            }
            dispatch(getCardFail(response.data.message))
          }
        })
        .catch(err => {
            dispatch(getCardFail(err.message))
          console.log(err)
          // dispatch(timeSlotFail(err.message))
        });
    };
  }