import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import * as actions from '../../store/actions/index'
import { Link, Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { LoginHelperModal } from '../LoginHelperModal';

import Header from "../Header";
import Footer from "../Footer";
import ServicesBanner from "../../assets/images/new/digitalAds/digitalAds1.jpg";

// Services Images
import ServicesIcon1 from "../../assets/images/new/digitalAds/target.png";
import ServicesIcon2 from "../../assets/images/new/digitalAds/geo.png";
import ServicesIcon3 from "../../assets/images/new/digitalAds/radar-localization.png";
import ServicesIcon4 from "../../assets/images/new/digitalAds/location.png";
import ServicesIcon5 from "../../assets/images/new/digitalAds/target-audience.png";
import ServicesIcon6 from "../../assets/images/new/digitalAds/promo.png";
import ServicesIcon7 from "../../assets/images/new/digitalAds/customer-loyalty.png";
import ServicesIcon8 from "../../assets/images/new/digitalAds/track.png";
import ServicesIcon9 from "../../assets/images/new/digitalAds/premium-quality.png";

// Our Packages Slick Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Download App Image
import AppImg from "../../assets/images/new/app-banner.jpg";
import { ServiceSection } from '../Component/ServiceSection';

const Data = [
  {
    icon: ServicesIcon1,
    title: 'Advanced Ad Targeting',
  },
  {
    icon: ServicesIcon2,
    title: 'Geo-Targeting',
  },
  {
    icon: ServicesIcon3,
    title: 'Local Targeting',
  },
  {
    icon: ServicesIcon4,
    title: 'Choose your location',
  },
  {
    icon: ServicesIcon5,
    title: 'Audience and Segmentation',
  },
  {
    icon: ServicesIcon6,
    title: 'Brand Awareness',
  },
  {
    icon: ServicesIcon7,
    title: 'Customer loyalty & retention',
  },
  {
    icon: ServicesIcon8,
    title: 'Track Performance',
  },
  {
    icon: ServicesIcon9,
    title: 'High quality Content',
  },
]


const ChooseData = [
  {
    id: '01',
    title: 'Digital Ad Experienced Team',
    description: 'We have an experienced and specialized team of digital advertising professionals to help you maximize your visibility and get the best ROI for your Ads.'
  },
  {
    id: '02',
    title: 'No Hidden fees',
    description: 'We tell you the costs upfront with our detailed proposals; we want all of us to have the same expectations for your project.'
  },
  {
    id: '03',
    title: 'Data Driven Optimization',
    description: 'Automatically optimize your digital ads to reach more users and generate more leads.'
  },
  {
    id: '04',
    title: 'Unique products and solutions',
    description: 'We strive to share our acquisitions and show the high quality of our products.'
  },
  {
    id: '05',
    title: 'Automated Ad Optimization',
    description: 'Get more impressions for your ad by having our Al algorithms constantly evaluate your campaign for potential improvement.'
  },
  {
    id: '06',
    title: 'Cross Device Compatibility',
    description: 'Make sure your message gets to the right customers regardless of the device they are using through seamless integration on web, mobile and tablet.'
  }
]

// Slick Props
var settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};



const DigitalAds = (props) => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
    props.advertiseSubscriptionList({ subscription_type: "monthly" })
    props.fetchCategory()
  }, [])
  const userToken = localStorage.getItem('token')
  const OnSubscriptionPlan = (planDetails) => {
    if (planDetails.subscriptionType == 'quote') {
      props.history.push({
        pathname: '/support',
      })
    } else {
      const userToken = localStorage.getItem('token')
      if (!userToken) {
        toast.warning("Please login!")
      } else {
        props.history.push({
          pathname: '/subscription-plan-details/' + `${planDetails.planId}`,
          // planDetails: planDetails // your data array of objects
        })
      }
    }
  }
  let details = props.subscriptionPlanlist ? props.subscriptionPlanlist : []
  var isPresent;
  var filteredPlanDetails = [];
  var matchWords = ['Ad', 'Plan'];
  for (var index = 0; index < details.length; index++) {
    isPresent = true;
    for (var outer = 0; outer < matchWords.length; outer++) {
      if (details[index].advertisement_type.indexOf(matchWords[outer]) === -1) {
        isPresent = false;
        break;
      }
    }
    if (isPresent) {
      filteredPlanDetails.push(details[index]);
    }
  }

  return (

    <>
      <Header />
      <div className='services-page digital-ads-page'>
        {/* Top Banner Section Old */}
        {/* <div className='banner-section' style={{ backgroundImage: `url(${ServicesBanner})` }}>
          <div className='container'>
            <div className='banner-info-cover'>
              <div className='banner_subtitle'>Digital Marketing</div>
              <div className='banner_title mb-3 mb-md-4'>Digital Ads</div>
              <div className='banner_description mb-4 mb-lg-5'>Reach more people and drive growth with the power of digital marketing. Maximize your business potential with the latest in digital ad technology.</div>
              <div className='btn-wrapper d-flex flex-column flex-md-row flex-wrap gap-3'>
                <Link to={{ pathname: "/send-us-requirement" }} className='btn btn-custom btn-custom-outline'>Send Us Your Requirement</Link>
                <Link to={{ pathname: "/register" }} className='btn btn-custom btn-custom-filled position-relative'>Register now</Link>
              </div>
            </div>
          </div>
        </div> */}

        {/* Top Banner Section */}
        <div className='digital-banner border-bottom position-relative'>
          <img className='w-100 img-fluid d-none d-md-block' src={require('../../assets/images/new/digitalAds/digital-banner.jpg')} alt="Banner" />
          <img className='w-100 img-fluid d-md-none' src={require('../../assets/images/new/digitalAds/digital-img.jpg')} alt="Banner" />
          <div className='banner-info-cover text-center my-5 my-md-0 px-3'>
            <div className='section-heading mb-3 mb-md-4'>Digital Marketing Agency</div>
            <div className='banner_description mb-4 mb-xl-5'>Stay ahead of your competition with our digital marketing expertise.</div>
            <Link to={{ pathname: "/send-us-requirement" }} className='btn btn-custom btn-custom-filled'>Send Us Your Requirement</Link>
            <div className='d-none d-lg-flex align-items-center justify-content-center gap-3 mt-5'>
              <div className='disc bg-danger rounded-circle' style={{ height: '40px', width: '40px' }}></div>
              <div className='disc bg-warning rounded-circle' style={{ height: '40px', width: '40px' }}></div>
              <div className='disc bg-primary rounded-circle' style={{ height: '40px', width: '40px' }}></div>
            </div>
          </div>
        </div>

        {/* Dominate Market Section */}
        <div className='dominate-market-sec border-bottom'>
          <div className='row g-0'>
            <div className='col-xl-8'>
              <div className='left-box px-3 px-md-5 py-5 m-xxl-4'>
                <div className='content-wrapper'>
                  <h2 className='fs-3 fw-semibold'>Dominate Your Market with Our Digital Marketing Strategies.</h2>
                  <p className='fs-5'>Our team has years of experience in B2B and B2C marketing, helping businesses like yours surpass their competitors and reach new heights. Let us help you take your business to the next level with our proven strategies.<br /><br /> We offer tailored digital marketing solutions that are designed to drive traffic, increase conversions, and grow your bottom line. Let us help you dominate your market today.​</p>
                </div>
                <div className='row gx-4 gx-xxl-5 mt-4 pt-3'>
                  <div className='col-xl-4 col-md-6'>
                    <div className='info-box h-100 px-3 py-4'>
                      <h6 className='fs-6 lh-base mb-3'>Get ahead of the game with our paid promotion strategies.</h6>
                      <p className='mb-0'>Don't let your competitors steal the spotlight. Our team of experts specializes in creating and executing effective paid promotion strategies that will put you ahead of the game. With our proven track record, you can trust us to elevate your business above the rest. Less ads spend and more revenue is the main focused.</p>
                    </div>
                  </div>
                  <div className='col-xl-4 col-md-6 mt-4 mt-md-0'>
                    <div className='info-box h-100 px-3 py-4'>
                      <h6 className='fs-6 lh-base mb-3'>Make your business shine with unbeatable SEO strategies.</h6>
                      <p className='mb-0'>In today's crowded digital space, it's not enough to just have a website. You need to rank high on search engines and stand out from the competition. Our expert team uses the latest SEO techniques to improve your online visibility and drive qualified traffic to your website. With our tailored organic strategies, you can make your business shine and stay ahead of the curve.</p>
                    </div>
                  </div>
                  <div className='col-xl-4 col-md-12 mt-4 mt-xl-0'>
                    <div className='info-box h-100 px-3 py-4'>
                      <h6 className='fs-6 lh-base mb-3'>Stay ahead of the competition with expertly crafted social media campaigns.</h6>
                      <p className='mb-0'>Don't just keep up with your competitors, stay ahead of them with our expertly crafted marketing campaigns. We'll work with you to create, launch, and iterate campaigns that are tailored to your unique business needs. With our experience in both B2B and B2C industries, you can trust us to help you achieve your marketing goals and outshine the competition.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-4'>
              <div className='services-wrapper h-100 px-3 px-md-5 px-xl-4 px-xxl-5 py-5'>
                <ul className='list-group justify-content-evenly h-100 py-4' style={{ listStylePosition: 'inside' }}>
                  <li className='mb-2'><Link to={'#'} className="fs-4 text-dark">Google Ads</Link></li>
                  <li className='mb-2'><Link to={'#'} className="fs-4 text-dark">Social Media Promotion</Link></li>
                  <li className='mb-2'><Link to={'#'} className="fs-4 text-dark">SEO (Search Engine Optimization)</Link></li>
                  <li className='mb-2'><Link to={'#'} className="fs-4 text-dark">Email Marketing</Link></li>
                  <li className='mb-2'><Link to={'#'} className="fs-4 text-dark">B2B Marketing</Link></li>
                  <li className='mb-2'><Link to={'#'} className="fs-4 text-dark">B2C Marketing</Link></li>
                  <li className='mb-2'><Link to={'#'} className="fs-4 text-dark">Pay Per Click</Link></li>
                  <li className='mb-2'><Link to={'#'} className="fs-4 text-dark">Lead Generation Campaigns</Link></li>
                  <li className='mb-2'><Link to={'#'} className="fs-4 text-dark">Boost the Website Traffic</Link></li>
                  <li className='mb-2'><Link to={'#'} className="fs-4 text-dark">Inbound Sales Campaign</Link></li>
                  <li className='mb-0'><Link to={'#'} className="fs-4 text-dark">Marketing Strategies</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Popular Category Section */}
        <div className='category-section section-padding'>
          <div className='container'>
            <div className="text-center mb-5">
              <div className="section-heading mb-md-1">Popular Industries</div>
            </div>

            <div className='row'>
              {props.categorylisting.slice(0, 8).map((item, index) => (
                <div className='col-md-6 col-lg-4 col-xl-3 mb-3 pb-1' key={index}>
                  <div className='category-info-wrapper d-flex justify-content-between flex-column h-100'>
                    <div className='coverr'>
                      <div className='category-header d-flex align-items-center gap-2 py-2'>
                        <img src={item.category_images} alt='Icon' />
                        <div className='category-title d-flex flex-wrap align-items-center'>{item.name}</div>
                      </div>

                      <div className='category-body py-1'>
                        {
                          item.subcategoryInfo ? item.subcategoryInfo.slice(0, 2).map((val, i) => (
                            <div className='category-item my-4' key={i} >{val.name}</div>
                          )) : ''
                        }
                      </div>
                    </div>
                    <div className='category-footer border-2 border-top border-white text-center'>
                      <Link to={{ pathname: "/subcategory", aboutProps: { name: item._id, category: item.name } }} className='btn btn-link py-0'>View All</Link>
                    </div>
                  </div>
                </div>
              ))}

              <div className='btn-wrapper text-center mt-4 mt-lg-5'>
                <Link to={{ pathname: "/category" }} className='btn btn-custom btn-custom-filled'>See All Industries</Link>
              </div>
            </div>
          </div>
        </div>

        {/* Professional website Services Section */}
        <div className='ecom-services services-section section-padding'>
          <div className='container'>
            <div className="text-center mx-auto pb-3" style={{ maxWidth: '670px' }}>
              <div className="section-heading mb-2">Digital Ad service</div>
              <div className="section-subheading">Increase your reach and get the best results from your digital ad campaigns with our custom digital ad service.</div>
            </div>
            <div className='row'>
              {Data.map((service, index) => (
                <div className='col-lg-4 col-sm-6 mt-4' key={index}>
                  <div className='services-wrapper text-center h-100'>
                    <div className='icon-wrapper mb-3'>
                      <img className='img-fluid img-white' src={service.icon} alt="Icon" />
                    </div>
                    <div className='service-title text-white'>{service.title}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Why Choose Us Section */}
        <div className='why-choose-section section-padding'>
          <div className='container'>
            <div className="text-center mx-auto mb-2 mb-md-4 pb-1" style={{ maxWidth: '670px' }}>
              <div className="section-heading mb-2">Why Choose us</div>
            </div>

            <div className='row'>
              {ChooseData.map((item, key) => (
                <div className='col-xl-4 col-md-6 mt-3 pt-1' key={key}>
                  <div className='data-wrapper d-flex g-3 h-100'>
                    <div className='left-box'>
                      <div className='num'>{item.id}</div>
                    </div>
                    <div className='right-box'>
                      <div className='title mb-3'>{item.title}</div>
                      <div className='description'>{item.description}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Our Packages Section */}
        {
          props.subscriptionPlanlist.length > 0 ?
            <div className='packages-section pricing-plan section-padding'>
              <div className='container'>
                <div className="text-center mx-auto mb-4" style={{ maxWidth: '654px' }}>
                  <div className="section-heading mb-2">Digital Ad Pricing</div>
                  <div className="section-subheading">Take your digital advertising to the next level. Get the results you deserve at a price you can afford.</div>
                </div>

                <div className='tab-section text-center mb-4 mb-md-5'>
                  <div className="nav tab-wrapper d-inline-flex" id="nav-tab" role="tablist">
                    <a className="tab-btn active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Monthly</a>
                  </div>
                </div>

                <div className="slider-wrapper">
                  <Slider {...settings} className="custom-slick mx-3 mx-md-0">
                    {filteredPlanDetails.map((key) => (
                      <div className='package-item text-center h-100' key={key}>
                        <div className='package-header'><label>{key.advertisement_type}</label></div>
                        <div className='price-wrapper px-3 py-4'>
                          <div className='package-label'>{key.description_of_original_price}</div>
                          <div className='package-price section-heading'>${key.price}</div>
                        </div>
                        <div className='package-info'>
                          <div className='list-cover text-start' dangerouslySetInnerHTML={{
                            __html:
                              (key.planDetail !== null) ? key.planDetail : "waiting"
                          }}></div>
                          <div className='btn-wrapper mt-4'>
                            {
                              !userToken ?
                                <LoginHelperModal /> :
                                <button className='btn btn-custom btn-custom-filled shadow-none' onClick={() => OnSubscriptionPlan({ planId: key._id, subscriptionType: key.subscription_type })}>BUY NOW</button>
                            }
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div> : ''
        }

        {/* Services Section */}
        <ServiceSection />

        {/* Newsletter Section */}
        <div className='newsletter-section section-padding'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className="col-lg-8 text-center text-lg-start">
                <div className="section-heading text-white">Servicehub Connect is the best way to Skyrocket your business</div>
              </div>
              <div className="col-lg-4 text-center text-lg-end mt-4 mt-lg-0">
                <div className='btn-wrapper d-inline-flex justify-content-end flex-column gap-3'>
                  <Link to={{ pathname: "/send-us-requirement" }} className='btn btn-custom btn-custom-outline border-white text-white'>Send Us Your Requirement</Link>
                  <Link to={{ pathname: "/register" }} className='btn btn-custom btn-custom-filled bg-white border-white'>Register now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Download App Section */}
        <div className='download-app-section section-padding mt-xxl-5 pt-xl-3 pb-xl-0'>
          <div className='container'>
            <div className='row align-items-center align-items-xl-end flex-column-reverse flex-lg-row'>
              <div className="col-lg-6">
                <div className='info-wrapper my-xl-3 py-xl-5'>
                  <div className='section-heading mb-4'>Download our Servicehub Connect App today</div>
                  <div className='app-description mb-4 pb-2'>ServiceHubConnect is a reliable and secure platform for connecting customers and professional service providers.</div>
                  <div className="d-flex gap-4">
                    <a className="text-md-end" href="https://apps.apple.com/in/app/servicehubconnect/id1588399255" target="_blank">
                      <img className="img-fluid" src="/static/media/app_store_ic.0f0fd86b.png" />
                    </a>
                    <a className="text-md-end" href="https://play.google.com/store/apps/details?id=com.Servicehubconnect" target="_blank">
                      <img className="img-fluid" src="/static/media/play_store_ic.f4aa1def.png" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mb-4 mb-lg-0">
                <div className='app-wrapper ps-xxl-3'>
                  <img className='img-fluid' src={AppImg} alt="Download App" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>

      <Footer />
    </>
  )
}


const mapPropsToState = (state) => {
  return {
    subscriptionPlanlist: state.advertiseSubscription.subscriptionPlanlist,
    categorylisting: state.category.categorylisting,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    advertiseSubscriptionList: (data) => dispatch(actions.advertiseSubscriptionList(data)),
    fetchCategory: () => dispatch(actions.fetchcategoryListAction()),
  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(DigitalAds))
