import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "../../store/actions/index";
// import Logo from "../images/logo.png";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Elements,
    ElementsConsumer,
    CardElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
let stripePromise = loadStripe("pk_live_HCDXsOAEmsRJMJkM1ZMnTi6H");

// toast.configure()
class PaymentForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }

        this.handleSubmit = async () => {
            const { elements, stripe } = this.props;
            const cardElement = elements.getElement(CardElement);
            let { error, token } = await stripe.createToken(cardElement)
            if (error) {
                toast.error(error.message, { autoClose: 1000 })
            } else {
                // console.log(token.id,'=======')
                this.props.updateCardDetails({ tokenId: token.id })

            }
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { enqueueSnackbar, error, success } = this.props;
        success && enqueueSnackbar(success, { variant: "success" });
        error && enqueueSnackbar(error, { variant: "error" });
      }

    render() {
        return (
            <>
                <div className="mt-4">
                    <div className='mb-1' style={{textAlign:'left',fontWeight:600}}> Edit Your Card Details </div>
                    <div className="border py-3 px-2">
                        <CardElement options={{ hidePostalCode: true }} />
                    </div>
                </div>
                <button className='btn btn-success btn-lg w-100 mt-4' onClick={this.handleSubmit}>Update</button>
            </>
        );
    }
}

class EditCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            card_number: '',
            expir: ''
        }

    } // live key
    //  = loadStripe("pk_live_HCDXsOAEmsRJMJkM1ZMnTi6H");


    render() {
        // console.log(this.props,'what is thereeeee','pppppppppppppppppppppp')
        return (
            <div className='modalcont'>
                <div className='modalinner'>
                    <Elements stripe={stripePromise} >
                        <ElementsConsumer >
                            {(ctx) => <PaymentForm {...ctx} updateCardDetails={this.props.updateCardDetails} />}
                        </ElementsConsumer>
                    </Elements>
                </div>
            </div>
        )
    }
}

const mapPropsToState = (state) => {
    return {
        // loading: state.cardDetails.loading,
        error: state.getCardDetails.error,
        success: state.getCardDetails.success,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateCardDetails: (data) => dispatch(actions.updateCardDetails(data))

    };
};

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(EditCard));
